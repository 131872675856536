import { create as n } from "zustand";
import { getTravelInsurancePaxParam as o, getTravelInsuranceStartDateParam as l, getTravelInsuranceEndDateParam as d } from "../utils/getParams.js";
const s = {
  destination: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  pax: !1
}, c = {
  elderly: 0,
  adult: 1,
  children: 0
}, x = n((r) => ({
  pax: o(),
  calendarDates: {
    startDate: l(),
    endDate: d()
  },
  destination: void 0,
  errors: s,
  errorsPax: {},
  openPax: !1,
  setDestination: (a) => r((e) => {
    var t;
    return {
      destination: a,
      inputFocus: (t = e.calendarDates) != null && t.startDate ? "pax" : "dates",
      errors: {
        ...e.errors,
        destination: s.destination
      }
    };
  }),
  setCalendarDates: (a) => r((e) => ({
    calendarDates: a,
    inputFocus: "pax",
    errors: {
      ...e.errors,
      calendarDates: s.calendarDates
    }
  })),
  setPax: (a) => r((e) => ({
    pax: a,
    errorsPax: {},
    errors: {
      ...e.errors,
      pax: !1
    }
  })),
  setErrors: (a) => r(() => ({
    errors: a
  })),
  setErrorsPax: (a) => r(() => ({
    errorsPax: a
  })),
  setOpenPax: (a) => r(() => ({
    openPax: a
  })),
  clearPax: () => r((a) => ({
    pax: c,
    errors: {
      ...a.errors,
      pax: !1
    },
    errorsPax: {}
  })),
  clearDates: () => r((a) => ({
    calendarDates: {
      endDate: void 0,
      startDate: void 0
    },
    errors: {
      ...a.errors,
      calendarDates: s.calendarDates
    }
  }))
}));
export {
  c as defaultTravelInsurancePax,
  x as useTravelInsuranceStore
};
