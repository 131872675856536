import { create as o } from "zustand";
import { getTourDateInitialValue as a, getTourAllDestinyInitialValue as i, getTourDestinationInitialValue as n } from "../utils/getToursInitialValue.js";
const s = {
  destinations: !1,
  date: !1
}, u = o((e) => ({
  date: a(),
  errors: s,
  allDestiny: i(),
  destinations: n(),
  setDestinations: (t) => e((r) => ({
    destinations: t,
    errors: {
      ...r.errors,
      destination: s.destinations
    }
  })),
  setDate: (t) => e((r) => ({
    date: t,
    errors: {
      ...r.errors,
      date: s.date
    }
  })),
  setAllDestiny: (t) => e({
    allDestiny: t
  }),
  setErrors: (t) => e(() => ({
    errors: t
  }))
}));
export {
  u as useToursStore
};
