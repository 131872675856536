function n(l) {
  const t = [];
  for (let e = 0; e <= 16; e++) {
    const s = String(e + 1);
    e === 0 ? t.push({
      label: "0 a 23 meses",
      value: `${l}`
    }) : t.push({
      label: `${s} anos`,
      value: s
    });
  }
  return t;
}
export {
  n as default
};
