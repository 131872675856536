import { create } from "zustand";

export enum OptionPackagePackageFullStoreBarValue {
    PACKAGE = "/pacotes-turisticos",
    PACKAGE_FULL = "/pacotes-estatico",
}

export type PackagePackageFullStoreStoreState = {
    choice: OptionPackagePackageFullStoreBarValue | string;
};

type Actions = {
    setChoice: (choice: string) => void;
};

export const usePackagePackageFullStoreStore = create<PackagePackageFullStoreStoreState & Actions>(set => ({
    choice: OptionPackagePackageFullStoreBarValue.PACKAGE,

    setChoice: (choice: string) => set(() => ({ choice })),
}));
