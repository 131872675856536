import { getBaseHostname as C, formatDateToParam as u, encodeSearchURL as S } from "@cvccorp-engines/components";
import { calcChildAges as x } from "@cvccorp-engines/pax";
function A(a) {
  var r, o, s, d, i, c, h, p, l, m, P;
  const e = new URL(`${C()}/hotel/search`);
  e.searchParams.append("CheckIn", u((r = a.calendarDates) == null ? void 0 : r.startDate)), e.searchParams.append("CheckOut", u((o = a.calendarDates) == null ? void 0 : o.endDate)), e.searchParams.append("Location", ((s = a.destination) == null ? void 0 : s.description) || ""), e.searchParams.append("ZoneId", String((d = a.destination) == null ? void 0 : d.masterCode)), e.searchParams.append("Rooms", String(((i = a.pax) == null ? void 0 : i.length) ?? ""));
  const f = ((c = a.pax) == null ? void 0 : c.map((n) => {
    var t;
    return (t = n.adult) == null ? void 0 : t.toString();
  }).filter(Boolean)) || [];
  e.searchParams.append("Adults", f.join(","));
  const g = ((h = a.pax) == null ? void 0 : h.map((n) => {
    var t;
    return (t = n.children) == null ? void 0 : t.toString();
  }).filter(Boolean)) || [];
  return e.searchParams.append("Children", g.join(",")), e.searchParams.append("ChildAges", x(a.pax)), e.searchParams.append("City", ((p = a.destination) == null ? void 0 : p.city) || ""), e.searchParams.append("State", ((l = a.destination) == null ? void 0 : l.state) || ""), e.searchParams.append("Country", ((m = a.destination) == null ? void 0 : m.country) || ""), e.searchParams.append("Name", ((P = a.destination) == null ? void 0 : P.city) || ""), S(e);
}
export {
  A as default
};
