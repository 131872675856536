import { d as o } from "../dayjs-CKf7tiKR.js";
import { CARS_LOCATIONS_DAY_OF_WEEK as d } from "../constants/carsWeekdayParser.js";
function i(a, e) {
  if (!e) return !1;
  const r = o(a).day(), t = d[r];
  return !e.some(({
    daysOfWeek: s
  }) => s === t) || !1;
}
export {
  i as default
};
