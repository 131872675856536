"use client";
import n, { useMemo as f } from "react";
import w from "@cvccorp-components/chui-react-components/components/InputDatePicker";
import { d as E } from "../../dayjs-CKf7tiKR.js";
import M from "../InputLoadingOverlay/InputLoadingOverlay.js";
import { Container as A } from "./styled.js";
function L(c) {
  const {
    max: o,
    min: a,
    label: d,
    value: h,
    error: i,
    options: u,
    content: y,
    inputRef: D,
    isLoading: g = !1,
    calendarDateVisible: t,
    range: C = !1,
    twoMonths: m = !0,
    showRangeCount: x = !0,
    onClear: P,
    onChange: r,
    onChangeCalendar: k,
    disableDates: s = () => !1
  } = c, {
    minDate: l,
    maxDate: p
  } = f(() => {
    const e = a ?? /* @__PURE__ */ new Date();
    return {
      minDate: e,
      maxDate: o ?? E(e).add(1, "year").toDate()
    };
  }, [a, o]), v = (e) => {
    e && (r == null || r(e.toString()));
  }, I = f(() => ({
    twoMonths: m,
    options: u,
    maxDate: p,
    minDate: l,
    size: "sm",
    month: t == null ? void 0 : t.month,
    year: t == null ? void 0 : t.year,
    disable: s
  }), [s, t, p, l, u, m]);
  return /* @__PURE__ */ n.createElement(A, null, /* @__PURE__ */ n.createElement(w, { range: C, label: d, onClear: P, datePickerProps: I, inputRef: D, status: i ? "danger" : void 0, value: h, fullWidth: !0, supportText: i, showRangeCount: x, onChangeCalendar: k, onChange: (e) => {
    Array.isArray(e) || v(e);
  }, content: y }), g ? /* @__PURE__ */ n.createElement(M, null) : void 0);
}
L.displayName = "InputDatePicker";
export {
  L as default
};
