import i from "@emotion/styled";
const p = i.div`
    padding: 0px;
    display: flex;
    width: 100%;

    & > fieldset {
        height: fit-content;

        & > div {
            height: 56px;

            & > input {
                padding: 20px 8px 15px;

                padding-block-start: 30px;
            }
        }
    }
    position: relative;
`;
export {
  p as Container
};
