import { _ as w, a as d, e as g, l as $ } from "./Icon-o1sXhey4.js";
import v from "react";
var N = ["size", "className"], f = function(l) {
  var i, a, t = l.size, o = t === void 0 ? "medium" : t, c = l.className, s = c === void 0 ? "" : c, e = w(l, N), r = e, m = ["chui-icon", s].join(" "), n = r.color || "#000014", u = d({}, e, {
    color: void 0
  }), h = g[o] || $[o];
  return /* @__PURE__ */ v.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: n,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : h,
    height: (a = e.height) != null ? a : h,
    className: m
  }, u), /* @__PURE__ */ v.createElement("path", {
    fill: void 0,
    d: "M5.809 14.256h4.228l-.894 1.646c-.686 1.275.235 2.807 1.67 2.807.469 0 .94-.176 1.296-.511l4.212-3.958h3.413a2.247 2.247 0 0 0 2.243-2.244 2.247 2.247 0 0 0-2.243-2.243h-3.411l-4.197-3.941a1.87 1.87 0 0 0-1.298-.512c-1.433 0-2.355 1.532-1.669 2.806v.001l.894 1.646h-2.35a.76.76 0 0 1-.535-.203l-.003-.003-1.293-1.164a4.2 4.2 0 0 0-2.864-1.108h-.146a.865.865 0 0 0-.818 1.134l1.36 4.112a2.53 2.53 0 0 0 2.405 1.735"
  }));
};
f.displayName = "FilledPlaneRight";
var z = ["size", "className"], _ = function(l) {
  var i, a, t = l.size, o = t === void 0 ? "medium" : t, c = l.className, s = c === void 0 ? "" : c, e = w(l, z), r = e, m = ["chui-icon", s].join(" "), n = r.color || "#000014", u = d({}, e, {
    color: void 0
  }), h = g[o] || $[o];
  return /* @__PURE__ */ v.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: n,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : h,
    height: (a = e.height) != null ? a : h,
    className: m
  }, u), /* @__PURE__ */ v.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 3a4.32 4.32 0 0 0-3.06 1.269 4.34 4.34 0 0 0-1.267 3.054H4.995A2.997 2.997 0 0 0 2 10.322v7.68A2.997 2.997 0 0 0 4.995 21h14.01A2.997 2.997 0 0 0 22 18.001v-7.68a2.997 2.997 0 0 0-2.995-2.998h-2.678a4.34 4.34 0 0 0-1.267-3.054A4.32 4.32 0 0 0 12 3m3.27 4.323a3.268 3.268 0 0 0-6.54 0zm-7.597 0v13.676H8.73V7.323zm7.598 13.676V7.323h1.056v13.676z",
    clipRule: "evenodd"
  }));
};
_.displayName = "FilledBaggage";
var x = ["size", "className"], A = function(l) {
  var i, a, t = l.size, o = t === void 0 ? "medium" : t, c = l.className, s = c === void 0 ? "" : c, e = w(l, x), r = e, m = ["chui-icon", s].join(" "), n = r.color || "#000014", u = d({}, e, {
    color: void 0
  }), h = g[o] || $[o];
  return /* @__PURE__ */ v.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: n,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : h,
    height: (a = e.height) != null ? a : h,
    className: m
  }, u), /* @__PURE__ */ v.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M3.406 7.436A2.936 2.936 0 0 1 6.342 4.5h11.336a2.936 2.936 0 0 1 2.936 2.936v3.721l-.227-.047A1.89 1.89 0 0 1 22 12.976v.028H2v-.028c0-.948.701-1.734 1.613-1.866l-.207.042zm16.208 0v3.654h-.823V9.52c0-.223.068-.434.199-.623l.068-.102c.316-.379.252-.902-.043-1.23-.335-.372-.885-.385-1.251-.152l-.008.005a1.03 1.03 0 0 1-.57.184h-3.044c-.197 0-.389-.065-.564-.18-.407-.29-.929-.194-1.25.127l-.008.008-.007.008c-.296.33-.355.846-.069 1.221l.07.105.005.006c.13.189.2.4.2.623v1.571h-1.057V9.52c0-.223.07-.434.2-.623l.068-.102c.316-.379.252-.902-.043-1.23-.335-.372-.885-.385-1.251-.152l-.008.005a1.03 1.03 0 0 1-.57.184H6.814c-.196 0-.388-.065-.564-.18-.406-.29-.928-.194-1.249.127l-.008.008-.007.008c-.296.33-.355.846-.069 1.221l.07.105.004.006c.131.189.2.4.2.623v1.571h-.784V7.436c0-1.062.859-1.936 1.936-1.936h11.336c1.062 0 1.936.86 1.936 1.936",
    clipRule: "evenodd"
  }), /* @__PURE__ */ v.createElement("path", {
    fill: void 0,
    d: "M3.886 19.204h16.228A1.887 1.887 0 0 0 22 17.318v-3.23H2v3.23c0 1.041.845 1.886 1.886 1.886"
  }));
};
A.displayName = "FilledBed";
export {
  _ as h,
  A as m,
  f as r
};
