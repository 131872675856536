import p, { createContext as C, useCallback as u, useMemo as x, useContext as y } from "react";
import { useIsomorphicLayoutEffect as D } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as M, LocalStorageKeys as v, redirectToSearch as A } from "@cvccorp-engines/components";
import { d as E, f as L } from "../formatUrlToSearch-CY8ixWli.js";
import { useToursStore as P } from "../store/ToursStore.js";
const f = C({});
function g(o) {
  const {
    setErrors: n,
    setAllDestiny: m,
    setDate: h,
    setDestinations: S,
    ...t
  } = P(), [e, i] = M(v.LAST_SEARCH_TOURS);
  D(() => {
    e && !t.destinations.length && (m(e.allDestiny), S(e.destinations), E(/* @__PURE__ */ new Date()).isAfter(e.date) || h(e.date));
  }, []);
  const d = u(() => {
    var l;
    let r = !1;
    const s = !((l = t.destinations) != null && l.length), a = !t.date;
    return r = s || a, [r, {
      destinations: s,
      date: a
    }];
  }, [t.date, t.destinations]), c = u(() => {
    const [r, s] = d();
    if (n(s), r) return;
    const a = L(t).replaceAll("+,+", ", ").replaceAll("+", " ");
    i({
      allDestiny: t.allDestiny,
      date: t.date,
      destinations: t.destinations
    }), A(a);
  }, [n, t, d, i]), T = x(() => ({
    handleSearch: c
  }), [c]);
  return /* @__PURE__ */ p.createElement(f.Provider, { ...o, value: T });
}
g.displayName = "MotorToursProvider";
function _() {
  const o = y(f);
  if (!o.handleSearch) throw new Error("Chamada do hook useMotorToursContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorToursContext");
  return o;
}
export {
  g as default,
  _ as useMotorToursContext
};
