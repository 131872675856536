import { OptionsBar, EngineProductsName } from "@cvccorp-engines/components";
import { EnginePackageFull } from "@cvccorp-engines/package-full";
import { useEnginesConfigContext } from "@cvccorp-engines/provider";
import EnginePackage from "../engine-package";
import { usePackagePackageFullStoreStore } from "../shared/Context/PackagePackageFullStore";

const CHOICE_PACKAGE = {
    product: "packages",
    value: "/pacotes-turisticos",
    label: "Aéreo + Hotel",
};

const CHOICE_PACKAGE_FULL = {
    product: "package-complete",
    value: "/pacotes-estatico",
    label: EngineProductsName.PACKAGE_FULL,
};

const choices = [CHOICE_PACKAGE, CHOICE_PACKAGE_FULL];

const EngineContent = props => {
    const { product } = props;
    const { choice, setChoice } = usePackagePackageFullStoreStore();
    const { navigationType } = useEnginesConfigContext();
    const isNavigationButton = navigationType === "button";

    const selectForm = isNavigationButton ? choice : choices.find(choice => choice.product === product)?.value;
    return (
        <>
            <OptionsBar
                variant={navigationType}
                options={choices}
                value={isNavigationButton ? choice : props.product}
                isChecked={
                    isNavigationButton
                        ? undefined
                        : option => {
                              return option.product === product;
                          }
                }
                onChange={setChoice}
            />
            <Form choice={selectForm} />
        </>
    );
};

const EnginePackagePackageFull = props => {
    return <EngineContent {...props} />;
};

interface FormProps {
    choice: string;
}

const forms = {
    [CHOICE_PACKAGE.value]: EnginePackage,
    [CHOICE_PACKAGE_FULL.value]: EnginePackageFull,
} as Record<string, any>;

function Form({ choice }: FormProps) {
    const Form = forms[choice];

    return <Form key={choice} />;
}

export default EnginePackagePackageFull;
