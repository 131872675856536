import { useState as S, useRef as h, useCallback as r, useEffect as I } from "react";
function A(T, m, e) {
  const {
    cacheTime: c,
    enabled: b,
    onSuccess: n,
    onError: u
  } = e ?? {}, [L, g] = S(!1), [P, k] = S(), [q, w] = S(!1), d = h(), a = h({}), s = h(), y = h(Date.now()), i = typeof c == "number" && c > 0, t = r(function() {
    if (y.current = Date.now(), a.current = {}, !i) {
      s.current = void 0;
      return;
    }
    s.current = y.current + c;
  }, [i, c]), f = r(function() {
    return typeof s.current > "u" ? !1 : s.current < y.current;
  }, []), C = r(function() {
    return !f();
  }, [f]), D = r(async () => {
    try {
      const l = m.toString(), E = a.current[l];
      if (f() && t(), i && C() && E)
        return Promise.resolve(E);
      w(!1), g(!0);
      const v = await T();
      k(v), a.current[l] = v, n == null || n(v);
    } catch (l) {
      u == null || u(l), w(!0);
    } finally {
      g(!1);
    }
  }, [m, i, f, t, u, n, T, C]), o = r(async function() {
    b && (d.current && clearTimeout(d.current), d.current = setTimeout(D, e == null ? void 0 : e.throttle));
  }, [D, b, e == null ? void 0 : e.throttle]), x = r(function() {
    t(), o();
  }, [o, t]);
  return I(() => {
    o();
  }, m), I(() => {
    t();
  }, [t]), {
    data: P,
    isLoading: L,
    invalidate: x,
    previous: a.current,
    refetch: async () => {
      o();
    },
    isError: q
  };
}
export {
  A as default
};
