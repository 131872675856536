import { useFlightStore as s } from "../store/FlightStore.js";
import a from "../utils/mergeDestinations.js";
function d() {
  const {
    multidestinations: t = {},
    calendarDates: e,
    destination: i,
    origin: r,
    pax: n,
    errors: o
  } = s();
  return a({
    multidestinations: t,
    calendarDates: e,
    errors: o,
    pax: n,
    destination: i,
    origin: r
  });
}
export {
  d as default
};
