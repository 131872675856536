import r from "react";
import f from "@cvccorp-components/chui-react-components/components/Select";
import { getCalendarMonths as S } from "@cvccorp-engines/api";
import { useFetch as v, useAutoCompleteItemSize as g, InputLoadingOverlay as h } from "@cvccorp-engines/components";
import { Container as b } from "./SelectedDate.styles.js";
const y = (i) => {
  const {
    value: o,
    onSelect: a,
    inputProps: s,
    error: l = "",
    label: m,
    destinations: c
  } = i, e = c.map(({
    zoneId: t
  }) => t).join(","), {
    data: p,
    isLoading: n
  } = v(() => S(e), [e], {
    enabled: !!e,
    throttle: 500,
    onSuccess: (t) => {
      t && o && !t.some((d) => d.value === o) && a(void 0);
    }
  }), u = g();
  return /* @__PURE__ */ r.createElement(b, null, /* @__PURE__ */ r.createElement(f, { open: n ? !1 : void 0, placeholder: "Selecione o mês", label: m, value: o, status: l ? "danger" : void 0, options: e ? p : [], onSelect: a, itemSize: u, disabled: n, inputProps: s, supportText: l }), n ? /* @__PURE__ */ r.createElement(h, null) : void 0);
};
y.displayName = "SelectDate";
export {
  y as default
};
