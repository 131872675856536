import i from "@emotion/styled";
const e = i.div`
    height: 56px;
    & fieldset {
        min-height: 56px;

        & > div {
            min-height: 56px;
        }
        input {
            min-height: calc(3.45rem - 2px);
        }
        @container (min-width: 1024px) {
            > div > div > div {
                height: 500px;
            }
        }
    }
`;
export {
  e as Container
};
