import e from "react";
import { useResponsive as s, Grid as n, Typography as t } from "@cvccorp-components/chui-react-components";
import "../../FilledPlay-Bo3nTh9_.js";
import "../../FilledBed-CZJxOTKc.js";
import "../../FilledMoney-Dh2WYsyR.js";
import { d as p, a as d } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineArrowRight-fe4YoXL9.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { Container as E } from "./styled.js";
const h = ({
  destination: a,
  origin: r,
  type: m
}) => {
  const {
    isMobile: o
  } = s(), l = m === "AIRPLANE" ? p : d, i = () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(n, { direction: "column" }, /* @__PURE__ */ e.createElement(t, { variant: "headline", scale: 7, weight: "bold" }, r.name), /* @__PURE__ */ e.createElement(t, { variant: "paragraph", scale: 3 }, r.address)), /* @__PURE__ */ e.createElement(l, { size: "xl" }), /* @__PURE__ */ e.createElement(n, { direction: "column" }, /* @__PURE__ */ e.createElement(t, { variant: "headline", scale: 7, weight: "bold" }, a.name), /* @__PURE__ */ e.createElement(t, { variant: "paragraph", scale: 3 }, a.address))), c = () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(l, { size: "lg" }), /* @__PURE__ */ e.createElement(n, { direction: "column" }, /* @__PURE__ */ e.createElement(t, { variant: "paragraph", scale: 3, weight: "bold" }, r.name, " | ", a.name)));
  return /* @__PURE__ */ e.createElement(E, null, o ? c() : i());
};
h.displayName = "TripDestination";
export {
  h as default
};
