import e from "react";
import u from "@cvccorp-components/chui-react-components/components/Counter";
import c from "../Label/Label.js";
function x(t) {
  const {
    helperText: r,
    label: n,
    value: a,
    min: m = 0,
    max: o,
    onChange: l
  } = t;
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(c, { text: n, helperText: r }, /* @__PURE__ */ e.createElement(u, { min: m, max: o, onChange: l, value: a })));
}
export {
  x as default
};
