import { _ as w, a as d, e as $, l as f } from "./Icon-o1sXhey4.js";
import a from "react";
var p = ["size", "className"], z = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, p), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m3.508 9.542.014-1.813a2.02 2.02 0 0 1 2.02-2.002h6.685q.36 0 .706.051a4.775 4.775 0 0 1 3.958 3.703A5.324 5.324 0 0 1 22 14.8c0 .617-.5 1.118-1.118 1.118H19.14a2.308 2.308 0 0 1-4.28 0H8.083a2.308 2.308 0 0 1-4.286-.012A2.02 2.02 0 0 1 2 13.899v-2.403c0-.939.64-1.728 1.508-1.954m7.377-.066h4.975a3.7 3.7 0 0 0-.503-1.084 3.77 3.77 0 0 0-3.13-1.665h-1.342zm-1 0v-2.75H5.542a1.02 1.02 0 0 0-1.02 1.012l-.014 1.738zm-3.942 6.886a1.308 1.308 0 1 0 0-2.615 1.308 1.308 0 0 0 0 2.615m12.365-1.308a1.308 1.308 0 1 1-2.616 0 1.308 1.308 0 0 1 2.616 0",
    clipRule: "evenodd"
  }));
};
z.displayName = "FilledCarRetro";
var N = ["size", "className"], _ = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, N), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M19.083 3.321a4.21 4.21 0 0 0-5.492 3.813 7 7 0 0 0-3.174 0 4.21 4.21 0 1 0-4.93 4.355 6.9 6.9 0 0 0-.426 2.678 6.92 6.92 0 0 0 2.454 5.038 6.949 6.949 0 0 0 11.007-7.72 4.23 4.23 0 0 0 3.118-2.439 4.2 4.2 0 0 0 .368-1.7l-.698-.004.698.002a4.21 4.21 0 0 0-2.925-4.023"
  }));
};
_.displayName = "FilledDisney";
var E = ["size", "className"], k = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, E), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M6.267 9.362a.925.925 0 1 0 0 1.85.925.925 0 0 0 0-1.85M8.285 10.287a.925.925 0 1 1 1.85 0 .925.925 0 0 1-1.85 0M11.986 9.362a.925.925 0 1 0 0 1.85.925.925 0 0 0 0-1.85"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m4.764 7.786-.003.044a1.95 1.95 0 0 0-1.49 1.896v2.178c-.745.169-1.301.835-1.301 1.63v2.787c0 .923.749 1.672 1.672 1.672h14.612c.604 0 1.16-.325 1.457-.852l2.193-3.894a.93.93 0 0 0-.81-1.385h-5.562V9.726a1.95 1.95 0 0 0-1.951-1.95H9.392A1.95 1.95 0 0 0 7.45 6.01h-.743a1.95 1.95 0 0 0-1.943 1.776m-.494 1.94a.95.95 0 0 1 .81-.94h4.321v-.01h4.18a.95.95 0 0 1 .95.95v2.136H4.272z",
    clipRule: "evenodd"
  }));
};
k.displayName = "FilledShip";
var x = ["size", "className"], M = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, x), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M7.387 17V7h-4.67a.74.74 0 0 0-.507.2.67.67 0 0 0-.21.482v1.752a.43.43 0 0 0 .106.289c.07.08.168.136.277.156a2.3 2.3 0 0 1 1.346.735c.344.388.532.879.532 1.386s-.188.998-.532 1.386c-.344.389-.82.649-1.346.735a.48.48 0 0 0-.277.156.43.43 0 0 0-.106.289v1.752c0 .18.076.354.21.482.135.128.317.2.507.2zm1.056 0V7h7.115v10zm8.171-10h4.669a.75.75 0 0 1 .507.2.68.68 0 0 1 .21.482v1.752a.43.43 0 0 1-.106.289.48.48 0 0 1-.277.156 2.3 2.3 0 0 0-1.346.735A2.1 2.1 0 0 0 19.74 12c0 .507.188.998.532 1.386.344.389.82.649 1.346.735.109.02.207.075.277.156.07.082.107.184.106.289v1.752a.65.65 0 0 1-.21.482.72.72 0 0 1-.507.2h-4.669z",
    clipRule: "evenodd"
  }));
};
M.displayName = "FilledTicketSearch";
var L = ["size", "className"], j = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, L), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M14.182 3.006A2.76 2.76 0 0 1 17.9 4.112l.004.008a2.76 2.76 0 0 1-.433 3.2h1.27a2.316 2.316 0 0 1 2.314 2.313 2.317 2.317 0 0 1-2.104 2.305v7.741c0 .907-.72 1.628-1.628 1.628h-2.825v-9.36h-1v9.36h-3v-9.36h-1v9.36H6.626c-.907 0-1.628-.72-1.628-1.628v-7.746a2.317 2.317 0 0 1-2.058-2.3A2.316 2.316 0 0 1 5.254 7.32h1.224a2.76 2.76 0 0 1-.433-3.2l.004-.007a2.76 2.76 0 0 1 3.719-1.106c.596.32 1.075.836 1.448 1.37.312.447.564.927.759 1.362.194-.435.447-.915.758-1.362.373-.534.852-1.05 1.448-1.37m-2.766 4.313a4 4 0 0 0-.253-.937 7.7 7.7 0 0 0-.767-1.433c-.325-.466-.695-.844-1.099-1.06a1.76 1.76 0 0 0-2.371.704 1.76 1.76 0 0 0 .708 2.37c.407.22.96.334 1.59.356zm3.309 0h-2.192a4 4 0 0 1 .253-.937c.189-.455.446-.972.767-1.433.325-.466.695-.844 1.099-1.06a1.76 1.76 0 0 1 2.371.704c.45.851.144 1.897-.708 2.37-.407.22-.96.334-1.59.356",
    clipRule: "evenodd"
  }));
};
j.displayName = "FilledGiftBox";
var R = ["size", "className"], y = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, R), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 25 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    stroke: "#000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 1.1,
    d: "M19.682 6.658a1.05 1.05 0 0 1-1.509-.056l-1.568-1.615a1.12 1.12 0 0 1-.06-1.548l.191-.2a.96.96 0 0 1 .406-.237l4.773-1.462a1.02 1.02 0 0 1 1.022.287 1.036 1.036 0 0 1 .056 1.434zM11.027 10.532a2.97 2.97 0 0 1-.8-2.558 6.4 6.4 0 0 1 .715-2 1.18 1.18 0 0 0-.559-1.642 1.11 1.11 0 0 0-1.245.256 6.57 6.57 0 0 0-1.852 3.424c-.211 1.044-.97 2.474-2.046 2.49a4.557 4.557 0 0 0-4.489 4.754 6.1 6.1 0 0 0 1.8 4.286l1.087 1.115a5.8 5.8 0 0 0 4.175 1.845 4.554 4.554 0 0 0 4.633-4.6c-.005-1.1 1.2-1.794 2.234-1.755a3.96 3.96 0 0 0 3.254-1.261 1.116 1.116 0 0 0-.713-1.861c-3.245-.272-3.928-.162-4.8-1.06z"
  }), /* @__PURE__ */ a.createElement("path", {
    stroke: "#fff",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 1.1,
    d: "m8.52 12.787 2.071 2.124"
  }), /* @__PURE__ */ a.createElement("path", {
    stroke: "#000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 2,
    d: "m11.894 11.421 5.495-5.63"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: "#fff",
    stroke: "#fff",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 1.1,
    d: "M6.407 18.574c.817 0 1.479-.68 1.479-1.517 0-.838-.662-1.517-1.479-1.517s-1.479.68-1.479 1.517c0 .838.662 1.517 1.479 1.517"
  }));
};
y.displayName = "FilledGuitar";
var F = ["size", "className"], V = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, F), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M2.5 5.883c0-1.901 1.578-3.41 3.5-3.41s3.5 1.509 3.5 3.41c0 1.156-.553 2.316-1.153 3.241-.21.325-.433.629-.65.903H10c1.814 0 3.321 1.436 3.321 3.25s-1.507 3.25-3.321 3.25c-1.026 0-1.821.804-1.821 1.75 0 .947.795 1.75 1.821 1.75h6.305c-.22-.281-.445-.593-.659-.925-.596-.928-1.146-2.085-1.146-3.22 0-1.9 1.578-3.41 3.5-3.41s3.5 1.51 3.5 3.41c0 1.157-.553 2.317-1.153 3.242a12.8 12.8 0 0 1-1.71 2.09 1 1 0 0 1-.09.076.75.75 0 0 1-.547.237h-8c-1.814 0-3.321-1.435-3.321-3.25s1.507-3.25 3.321-3.25c1.026 0 1.821-.803 1.821-1.75 0-.946-.795-1.75-1.821-1.75H6a.75.75 0 0 1-.521-.21 1 1 0 0 1-.128-.104l-.003-.002A13 13 0 0 1 3.646 9.1C3.05 8.175 2.5 7.018 2.5 5.884m3.5-.88c-.524 0-.922.408-.922.88 0 .471.398.88.922.88s.922-.409.922-.88-.398-.88-.922-.88m11.078 10.88c0-.472.398-.88.922-.88s.922.408.922.88c0 .471-.398.88-.922.88s-.922-.409-.922-.88",
    clipRule: "evenodd"
  }));
};
V.displayName = "FilledItinerary";
var H = ["size", "className"], B = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, H), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 23 15",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    d: "M7.5 2 3 4v5.5l.5.5 1-.5 1 .5 2 2 2 2.5 1.5-1 .5-1h1l1-1 .5-.5 1-.5.5-1.5-3-4.5-1 .5c-2 .4-2.5-.167-2.5-.5-1-1 .5-1.5.5-2 0-.4-1.333-.5-2-.5"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    d: "M19.258 2.896 16.044 1.52a.4.4 0 0 0-.091-.027l-2.825-.47a.4.4 0 0 0-.244.036L9.119 2.94a.4.4 0 0 0-.179.18l-.311.622a.4.4 0 0 0 .075.462l.645.645a.4.4 0 0 0 .36.109l2.252-.45a.4.4 0 0 1 .079-.008h.273c.118 0 .23.052.306.144.872 1.062 2.5 3.21 2.881 4.356.103.309 1.37 1.585 1.687 1.514l2-.444a.4.4 0 0 0 .313-.39V3.263a.4.4 0 0 0-.242-.368"
  }), /* @__PURE__ */ a.createElement("path", {
    stroke: "#fff",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 1.1,
    d: "m19.54 9.796-5.16.986M13.666 3.934 10.198 5.08a1.15 1.15 0 0 1-1.405-.61 1.164 1.164 0 0 1 .529-1.53l3.362-1.69a2.34 2.34 0 0 1 1.858-.108l4.973 1.82"
  }), /* @__PURE__ */ a.createElement("path", {
    stroke: "#fff",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 1.1,
    d: "M2.935 9.804H5.38l3.512 3.936a1.343 1.343 0 0 0 2.358-.876v-.615l.175.07a1.658 1.658 0 0 0 2.271-1.537h.489a1.467 1.467 0 0 0 1.174-2.348L12.22 4.412M9.833 2.677l-.358-.29a2.1 2.1 0 0 0-2.024-.259L3 3.908"
  }), /* @__PURE__ */ a.createElement("mask", {
    id: "FilledPartners_svg__a",
    fill: "#fff"
  }, /* @__PURE__ */ a.createElement("path", {
    d: "M0 2.205h2a.96.96 0 0 1 1 .917V9.54a.96.96 0 0 1-1 .917H0"
  })), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    d: "M0 2.205h2a.96.96 0 0 1 1 .917V9.54a.96.96 0 0 1-1 .917H0"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    d: "M0 1.105a1.1 1.1 0 0 0 0 2.2zm2 1.1v1.1h.046zm1 .917h1.1l-.001-.049zM3 9.54l1.099.05.001-.05zm-1 .917.045-1.099H2zm-2-1.1a1.1 1.1 0 0 0 0 2.2zm0-6.05h2v-2.2H0zm2.046-.002a.14.14 0 0 1-.1-.036l1.487-1.621a2.06 2.06 0 0 0-1.479-.54zm-.1-.036a.14.14 0 0 1-.045-.096l2.198-.1a2.06 2.06 0 0 0-.666-1.425zM1.9 3.122V9.54h2.2V3.122zm.001 6.367a.14.14 0 0 1 .045-.096l1.487 1.622c.401-.368.64-.88.666-1.425zm.045-.096a.14.14 0 0 1 .099-.036l-.09 2.199a2.06 2.06 0 0 0 1.478-.541zM2 9.356H0v2.2h2z",
    mask: "url(#FilledPartners_svg__a)"
  }), /* @__PURE__ */ a.createElement("mask", {
    id: "FilledPartners_svg__b",
    fill: "#fff"
  }, /* @__PURE__ */ a.createElement("path", {
    d: "M22.5 10.456h-2a.96.96 0 0 1-1-.917V3.122a.96.96 0 0 1 1-.917h2"
  })), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    d: "M22.5 10.456h-2a.96.96 0 0 1-1-.917V3.122a.96.96 0 0 1 1-.917h2"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: "#000",
    d: "M22.5 11.556a1.1 1.1 0 0 0 0-2.2zm-2-1.1v-1.1l-.045.001zm-1-.917h-1.1l.001.05zm0-6.417-1.099-.049-.001.05zm1-.917-.046 1.1h.046zm2 1.1a1.1 1.1 0 0 0 0-2.2zm0 6.051h-2v2.2h2zm-2.045.001a.14.14 0 0 1 .1.036l-1.488 1.622c.402.368.933.563 1.478.54zm.1.036a.14.14 0 0 1 .044.096l-2.198.1c.025.545.265 1.058.666 1.426zm.045.146V3.122h-2.2V9.54zm-.001-6.367a.14.14 0 0 1-.045.096l-1.487-1.621a2.06 2.06 0 0 0-.666 1.426zm-.045.096a.14.14 0 0 1-.1.036l.092-2.198a2.06 2.06 0 0 0-1.479.54zm-.054.037h2v-2.2h-2z",
    mask: "url(#FilledPartners_svg__b)"
  }), /* @__PURE__ */ a.createElement("path", {
    stroke: "#fff",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
    strokeWidth: 1.1,
    d: "m11.25 12.25-.978-.979M13.696 10.782l-1.468-1.468"
  }));
};
B.displayName = "FilledPartners";
var A = ["size", "className"], W = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, A), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m11.81 3.161-6.149 3.27A1.23 1.23 0 0 0 5 7.528v6.802c0 4.797 5.732 6.348 7.129 6.634.196.048.392.048.612 0 1.396-.31 7.129-1.861 7.129-6.634V7.528c0-.453-.245-.859-.662-1.097L13.06 3.16c-.417-.215-.882-.215-1.25 0m1.229 2.724 3.97 2.1.009.006c.38.222.66.635.66 1.112v4.392c0 1.764-1.064 2.917-2.177 3.629-1.106.706-2.32 1.02-2.8 1.122h-.004l-.004.002a1.6 1.6 0 0 1-.59 0l-.005-.002h-.005c-.466-.101-1.679-.416-2.788-1.122-1.115-.71-2.188-1.864-2.188-3.63v-4.39c0-.456.239-.89.68-1.123l3.96-2.096c.4-.229.883-.229 1.282 0m-.508.847a.25.25 0 0 0-.266 0l-.009.005-3.977 2.105a.28.28 0 0 0-.154.261v4.392c0 1.312.777 2.2 1.732 2.808.956.609 2.027.892 2.448.983.056.01.132.01.187 0 .43-.091 1.502-.373 2.456-.983.95-.607 1.723-1.494 1.723-2.809v-4.39a.33.33 0 0 0-.164-.268L12.54 6.737zm3.089 3.88a.48.48 0 0 0-.105-.686.51.51 0 0 0-.705.102l-2.865 3.77c-.02.025-.041.032-.056.033-.015 0-.031-.003-.05-.022l-1.519-1.623a.513.513 0 0 0-.711-.033.48.48 0 0 0-.033.694l1.518 1.622a1.088 1.088 0 0 0 1.658-.083l.002-.003z",
    clipRule: "evenodd"
  }));
};
W.displayName = "FilledSecurity";
var P = ["size", "className"], b = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, P), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M3.003 12c0-.792.102-1.56.294-2.29.752 2.647 2.582 4.149 4.494 4.149.695 0 1.253.207 1.603.5.342.284.485.64.422 1.02-.175 1.057-.02 1.867.337 2.518.348.634.866 1.073 1.315 1.427l.198.155c.381.298.673.526.87.8.133.186.222.397.214.687A8.997 8.997 0 0 1 3.003 12m13.554 7.76a9 9 0 0 0 4.414-7.075 8 8 0 0 1-.803.048 5 5 0 0 1-1.174-.124c-.393-.097-.802-.27-1.118-.587-.4-.399-.65-1.04-.813-1.561-.113-.361-.21-.76-.275-1.03-.028-.114-.05-.205-.065-.26-.114-.41-.483-1.277-1.505-2.3a1.62 1.62 0 0 1-.485-1.282c.026-.421.192-.817.371-1.136.155-.275.335-.518.488-.704A9 9 0 0 0 12 3.003a8.96 8.96 0 0 0-4.043.958l.176.79a.31.31 0 0 0 .26.241l2.461.348c2.848.402 2.934 4.483.106 5.006-.348.064-.386.548-.053.666l5.028 1.791c2.282.813 2.91 3.744 1.161 5.42-.39.375-.592.906-.547 1.445z",
    clipRule: "evenodd"
  }));
};
b.displayName = "FilledWorld";
var S = ["size", "className"], C = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, S), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M10.25 4.5a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5z"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M18.5 20V4a2 2 0 0 0-2-2h-9a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2M7.5 3.5h9a.5.5 0 0 1 .5.5v14H7V4a.5.5 0 0 1 .5-.5M12 20.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5",
    clipRule: "evenodd"
  }));
};
C.displayName = "FilledApp";
var G = ["size", "className"], q = function(l) {
  var i, t, s = l.size, o = s === void 0 ? "medium" : s, c = l.className, h = c === void 0 ? "" : c, e = w(l, G), n = e, m = ["chui-icon", h].join(" "), v = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = $[o] || f[o];
  return /* @__PURE__ */ a.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (t = e.height) != null ? t : r,
    className: m
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M4.65 22a2.07 2.07 0 0 1-1.08-.3 2.1 2.1 0 0 1-1-1.8V4.12a2.11 2.11 0 0 1 3.1-1.85l14.71 7.88A2.09 2.09 0 0 1 21.46 12a2.11 2.11 0 0 1-1.11 1.85L5.64 21.73a2.05 2.05 0 0 1-.99.27"
  }));
};
q.displayName = "FilledPlay";
export {
  W as a,
  y as b,
  V as c,
  _ as d,
  C as e,
  j as f,
  B as g,
  q as h,
  b as m,
  k as p,
  M as r,
  z as s
};
