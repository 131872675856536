import { create as t } from "zustand";
const n = {
  destination: !1,
  origin: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  pax: !1
}, o = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], l = t((e) => ({
  startCheckIn: [],
  preventOpenCalendar: !0,
  setValues(r) {
    return e(() => r);
  },
  setPreventOpenCalendar(r) {
    return e(() => ({
      preventOpenCalendar: r
    }));
  },
  setStartCheckin(r) {
    return e({
      startCheckIn: r
    });
  },
  setOpenPax: (r) => e(() => ({
    openPax: r
  })),
  openPax: !1,
  pax: o,
  calendarDates: "",
  origin: void 0,
  destination: void 0,
  errors: n,
  errorsPax: {},
  inputFocus: void 0,
  setDestination: (r) => e((a) => ({
    destination: r,
    startCheckIn: [],
    errors: {
      ...a.errors,
      destination: n.destination
    }
  })),
  setOrigin: (r) => e((a) => ({
    origin: r,
    startCheckIn: [],
    errors: {
      ...a.errors,
      origin: n.origin
    }
  })),
  setCalendarDates: (r) => {
    e((a) => ({
      calendarDates: r,
      inputFocus: "pax",
      errors: {
        ...a.errors,
        calendarDates: n.calendarDates
      }
    }));
  },
  setPax: (r) => e((a) => ({
    pax: r,
    errorsPax: {},
    startCheckIn: [],
    errors: {
      ...a.errors,
      pax: !1
    }
  })),
  setErrors: (r) => e(() => ({
    errors: r
  })),
  setErrorsPax: (r) => e(() => ({
    errorsPax: r
  })),
  setInputsFocus: (r) => e(() => ({
    inputFocus: r
  })),
  clearPax: () => e((r) => ({
    pax: o,
    errors: {
      ...r.errors,
      pax: !1
    },
    errorsPax: {}
  })),
  clearDates: () => e((r) => ({
    calendarDates: "",
    errors: {
      ...r.errors,
      calendarDates: n.calendarDates
    }
  })),
  reverse() {
    return e((r) => {
      var a, s;
      return {
        destination: r.origin,
        origin: r.destination,
        errors: {
          ...r.errors,
          destination: (a = r.errors) == null ? void 0 : a.origin,
          origin: (s = r.errors) == null ? void 0 : s.destination
        }
      };
    });
  }
}));
export {
  o as defaultPackageFullStorePax,
  l as usePackageFullStoreStore
};
