import t from "@emotion/styled";
const r = t.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  rowGap: 8,
  alignItems: "flex-start",
  gap: 12,
  width: "100%",
  ".date-picker": {
    display: "flex",
    alignItems: "flex-start",
    gap: 8,
    width: "100%"
  },
  ".css-1kl7p8h": {
    width: "100%"
  },
  "@container (min-width: 899px)": {
    gridTemplateColumns: "1.5fr 1fr"
  },
  "@container (max-width: 688px)": {
    gridTemplateColumns: "1fr"
  }
});
export {
  r as LocationAndDateWrapper
};
