"use client";
import e from "react";
import m from "@cvccorp-components/chui-react-components/components/Button";
import { h as p } from "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { useTheme as u } from "@emotion/react";
import { Wrapper as i } from "./InputGroup.styles.js";
function l(o) {
  const {
    onReverse: t,
    ...n
  } = o, {
    name: a
  } = u();
  return /* @__PURE__ */ e.createElement(i, { fullWidth: !0, ...n, renderButton: t ? () => /* @__PURE__ */ e.createElement(m, { color: a === "submarino" ? "primary" : "secondary", size: "lg", icon: /* @__PURE__ */ e.createElement(p, null), type: "button", variant: "text", className: "reverse-button", onClick: (r) => {
    r.stopPropagation(), r.preventDefault(), t == null || t();
  } }) : void 0 });
}
l.displayName = "InputGroup";
export {
  l as default
};
