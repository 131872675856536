import { useCallback as u, useMemo as l } from "react";
import n from "../useLocalStorage/useLocalStorage.js";
const f = (r, s = void 0) => {
  const [e, t] = n(r, s), o = u((a) => {
    t(JSON.stringify(a));
  }, [t]);
  return l(() => [typeof e == "string" ? JSON.parse(e) : e, o], [e, o]);
};
export {
  f as default
};
