import e from "react";
import m from "../LocationAndDate/index.js";
const u = (t) => {
  const {
    children: n,
    date: o,
    destinations: a,
    errors: r,
    allDestiny: i,
    onAllDestinyChange: l,
    onDateChange: s,
    onDestinationsChange: c
  } = t;
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(m, { date: o, errors: r, allDestiny: i, destinations: a, onDateChange: s, onAllDestinyChange: l, onDestinationsChange: c }), n);
};
export {
  u as default
};
