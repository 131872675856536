import { useCallback, useMemo } from "react";
import { EngineCar } from "@cvccorp-engines/car";
import { ChipProducts, type ProductTypes } from "@cvccorp-engines/components";
import { EngineFlight } from "@cvccorp-engines/flight";
import { EnginePackageFull } from "@cvccorp-engines/package-full";
import { useEnginesConfigContext } from "@cvccorp-engines/provider";
import EngineTickets from "@cvccorp-engines/ticket";
import EngineTours from "@cvccorp-engines/tours";
import EngineTravelInsurance from "@cvccorp-engines/travel-insurance";
import { useTheme } from "@emotion/react";
import { EngineBox } from "../components";
import EngineHotelsResorts from "../engine-hotels-resorts";
import EnginePackagePackageFull from "../engine-package-package-full";
import type { EngineWrapperProps } from "./EngineWrapper.types";
import useEngineWrapper, { getDefaultProductByTheme } from "./useEngineWrapper";
const ENGINES_CVC = {
    air: EngineFlight,
    hotel: EngineHotelsResorts,
    packages: EnginePackagePackageFull,
    cars: EngineCar,
    "package-complete": EnginePackagePackageFull,
    tickets: EngineTickets,
    tours: EngineTours,
    cru: null,
    disney: null,
    "travel-security": EngineTravelInsurance,
};

const ENGINES_SUBMARINO = {
    air: EngineFlight,
    hotel: EngineHotelsResorts,
    "package-complete": EnginePackageFull,
};

function EngineWrapper(props: EngineWrapperProps) {
    const { name: themeName } = useTheme();
    const productDefault = getDefaultProductByTheme(themeName);
    const { type = productDefault, hide } = props;
    const { navigationType } = useEnginesConfigContext();

    const engines = useMemo(() => {
        if (themeName === "cvc") {
            return ENGINES_CVC;
        }

        return ENGINES_SUBMARINO;
    }, [themeName]);

    const { product, pathname, productsTheme, setProduct } = useEngineWrapper({
        type,
    });

    const validatePackageName = useCallback(
        (product: ProductTypes): ProductTypes => {
            if (["/", productsTheme.link.packages].includes(pathname)) return productDefault;

            if (themeName === "cvc") {
                if (pathname === productsTheme.link["package-full"]) return "package-complete";
            }

            return product;
        },
        [pathname, productDefault, productsTheme.link, themeName],
    );

    const renderEngineProduct = useCallback(() => {
        const engineDefault = engines[productDefault];
        const engine = engines[validatePackageName(product)];
        return engine ? engine : engineDefault;
    }, [engines, product, productDefault, validatePackageName]);

    const EngineProduct = renderEngineProduct();

    return (
        <EngineBox>
            <ChipProducts productType={product} as={navigationType} onClick={setProduct} hide={hide} />
            <EngineProduct product={product} />
        </EngineBox>
    );
}

export default EngineWrapper;
