"use client";
import r from "react";
import U from "@cvccorp-components/chui-react-components/components/Checkbox";
import { setDataLayer as a, InputGroup as w, InputDatePicker as S } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CKf7tiKR.js";
import { CarsTags as l } from "../../constants/dataLayerTags.js";
import { useCarsStore as z } from "../../store/CarsStore.js";
import b from "../../utils/getDisabledOfficeHours.js";
import T from "../CarsLocation/CarsLocation.js";
import O from "../SelectHour/SelectHour.js";
import { LocationWrapper as M, DateWrapper as h } from "./LocationAndDate.styled.js";
import V from "./useTimeLocations.js";
function $() {
  const {
    errors: o,
    pickupDate: n,
    pickupTime: N,
    returnDate: m,
    returnTime: g,
    pickupLocation: i,
    returnLocation: c,
    showReturnLocation: p,
    reverse: v,
    setPickupDate: E,
    setPickupTime: f,
    setReturnDate: C,
    setReturnTime: s,
    clearPickupDate: K,
    clearReturnDate: y,
    setReturnLocation: u,
    setPickupLocation: t,
    setShowReturnLocation: G
  } = z(), R = () => {
    G(!p), a(l.TOGGLE_CHECKOUT_ORIGIN_OPTION);
  }, {
    pickupOptions: A,
    returnOptions: x
  } = V();
  function D() {
    return o != null && o.pickupLocation ? "Preencha o local de retirada" : "";
  }
  function _() {
    return o != null && o.returnLocation ? "Preencha o local de devolução" : "";
  }
  const H = [m ? {
    date: d(m).toDate(),
    variant: "border",
    subtitle: "Devolução",
    subtitleProps: {
      style: {
        transform: "translateY(-2px)",
        fontSize: 8
      }
    }
  } : void 0, n ? {
    date: d(n).toDate(),
    variant: "border",
    subtitle: "Retirada",
    subtitleProps: {
      style: {
        transform: "translateY(-2px)",
        fontSize: 8
      }
    }
  } : void 0].filter(Boolean);
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(M, null, p ? /* @__PURE__ */ r.createElement(w, { fullWidth: !0, className: "input-group", onReverse: () => v == null ? void 0 : v(), status: _() || D() ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ r.createElement(T, { label: "Local de retirada", value: i, onSelect: (e) => {
    t == null || t(e), a(l.SET_CHECKIN_ORIGIN);
  }, onClear: () => {
    t == null || t(void 0), E(void 0), f(void 0), a(l.SET_CHECKIN_ORIGIN);
  }, error: D() }), /* @__PURE__ */ r.createElement(T, { label: "Local de devolução", value: c, onSelect: (e) => {
    u == null || u(e), a(l.SET_CHECKOUT_ORIGIN);
  }, error: _(), onClear: () => {
    u == null || u(void 0), C(void 0), s(void 0), a(l.SET_CHECKOUT_ORIGIN);
  } })) : /* @__PURE__ */ r.createElement(T, { label: "Local de retirada", value: i, onSelect: (e) => {
    t == null || t(e), a(l.SET_CHECKIN_ORIGIN);
  }, error: D(), onClear: () => {
    t == null || t(void 0), a(l.SET_CHECKIN_ORIGIN);
  } }), /* @__PURE__ */ r.createElement(U, { color: "primary", falseValue: "false", label: "Devolução em outro local ou destino", size: "md", trueValue: "true", checked: p, onChange: R })), /* @__PURE__ */ r.createElement(h, null, /* @__PURE__ */ r.createElement(S, { label: "Retirada", value: new Date(n || ""), onChange: (e) => {
    if (!e) return;
    const [I] = Array.isArray(e) ? e : [e];
    E == null || E(I), a(l.SET_CHECKIN_DATE);
  }, disableDates: (e) => b(e, i == null ? void 0 : i.officeHours), onClear: K, error: o == null ? void 0 : o.pickupDate, min: d().add(1, "d").toDate(), max: m ? d(m).add(-1, "d").toDate() : void 0, options: H }), /* @__PURE__ */ r.createElement(O, { options: A, label: "Horário", value: N, placeholder: "Horário de retirada", onSelect: (e) => {
    e && (f == null || f(e), a(l.SET_CHECKIN_TIME));
  } })), /* @__PURE__ */ r.createElement(h, null, /* @__PURE__ */ r.createElement(S, { label: "Devolução", value: new Date(m || ""), onChange: (e) => {
    if (!e) return;
    const [I] = Array.isArray(e) ? e : [e];
    C == null || C(I), a(l.SET_CHECKOUT_DATE);
  }, disableDates: (e) => b(e, (c == null ? void 0 : c.officeHours) || (i == null ? void 0 : i.officeHours)), min: n ? d(n).add(1, "d").toDate() : void 0, onClear: y, error: o == null ? void 0 : o.returnDate, options: H, calendarDateVisible: {
    month: n ? d(n).toDate().getMonth() : void 0,
    year: n ? d(n).toDate().getFullYear() : void 0
  } }), /* @__PURE__ */ r.createElement(O, { options: x, label: "Horário", value: g, placeholder: "Horário de devolução", onSelect: (e) => {
    e && (s == null || s(e), a(l.SET_CHECKOUT_TIME));
  } })));
}
export {
  $ as default
};
