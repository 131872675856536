import { getBaseHostname as l, formatDateToParam as s, encodeSearchURL as A } from "@cvccorp-engines/components";
import { countPax as O } from "@cvccorp-engines/pax";
import { FlightOptions as D } from "../store/FlightStore.types.js";
import I from "./formatSearchPrefix.js";
import L from "./mergeDestinations.js";
function x(a) {
  var n, p, h, i, P, m;
  const S = I(a), e = new URL(`${l()}/passagens/v2/search${S}`), C = L(a), {
    adultCount: g,
    childrenCount: u,
    babyCount: T
  } = O(a.pax);
  return a.choice && e.searchParams.append("fType", a.choice), a.classe && e.searchParams.append("CLA", a.classe), e.searchParams.append("ADT", String(g)), e.searchParams.append("CHD", String(u)), e.searchParams.append("INF", String(T)), e.searchParams.append("STO", "PACKAGE"), a.choice !== D.MULTIPLE_DESTINATION ? (e.searchParams.append("MCO1", ((p = (n = a.origin) == null ? void 0 : n.description) == null ? void 0 : p.toString()) ?? ""), e.searchParams.append("MCD1", ((i = (h = a.destination) == null ? void 0 : h.description) == null ? void 0 : i.toString()) ?? ""), e.searchParams.append("Date1", s((P = a.calendarDates) == null ? void 0 : P.startDate)), a.choice === D.GOING_AND_BACK && e.searchParams.append("Date2", s((m = a.calendarDates) == null ? void 0 : m.endDate))) : C.forEach(({
    destination: r,
    origin: t,
    calendarDates: c
  }, o) => {
    var d, f;
    e.searchParams.append(`MCO${o + 1}`, ((d = t == null ? void 0 : t.description) == null ? void 0 : d.toString()) ?? ""), e.searchParams.append(`MCD${o + 1}`, ((f = r == null ? void 0 : r.description) == null ? void 0 : f.toString()) ?? ""), e.searchParams.append(`Date${o + 1}`, s(c == null ? void 0 : c.startDate));
  }), A(e);
}
export {
  x as default
};
