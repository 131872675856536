import e, { Fragment as d } from "react";
import D from "../LocationAndDate/index.js";
const f = (t) => {
  const {
    children: a,
    onDateChange: n,
    onDateClear: o,
    max: r,
    min: i,
    destination: m,
    onDestinationChange: l,
    date: c,
    errors: s = {}
  } = t;
  return /* @__PURE__ */ e.createElement(d, null, /* @__PURE__ */ e.createElement(D, { errors: s, date: c, onDateClear: o, onDateChange: n, max: r, min: i, destination: m, onDestinationChange: l }), a);
};
f.displayName = "DefaultLocationForm";
export {
  f as default
};
