import o from "../../libs/fetcher.js";
import i from "../../utils/QueryString.js";
const n = async (t) => {
  try {
    const {
      data: r
    } = await o.get("/api/ingresso/locations", {
      params: {
        query: i.text(t).toString()
      }
    });
    return r;
  } catch {
    throw new Error("Error in Tickets Location");
  }
};
export {
  n as getTicketsLocations
};
