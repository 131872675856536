"use client";
import P, { useState as T, useMemo as C } from "react";
import { getCarsLocations as _ } from "@cvccorp-engines/api";
import { getParams as d, useError as x, Pesqinc as A } from "@cvccorp-engines/components";
function N(m) {
  const {
    error: f,
    label: g,
    value: s,
    inputProps: O,
    supportText: y,
    supportTextProps: R,
    onClear: c,
    onSelect: a
  } = m, [i, h] = T([]), E = d("packageGroup") ? String(d("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: u
  } = x(), b = async (o) => {
    var n, p;
    try {
      const e = await _(o);
      if ((n = e == null ? void 0 : e.locations) != null && n.length) {
        const r = (p = e.locations) == null ? void 0 : p.map((t) => ({
          label: t.description,
          value: {
            iata: t.IATA,
            zoneId: t.masterCode,
            description: t.description,
            type: t.type,
            officeHours: t.officeHours,
            city: t.city,
            state: t.state,
            country: t.country
          }
        }));
        h(r);
      } else {
        const r = l("Nenhuma cidade encontrada com o texto buscado", o, 200, "success");
        Object.assign(r, {
          _message: `EMPTYRESULT ${o}`
        }), u(r);
      }
    } catch (e) {
      const r = l("Ocorreu um erro ao tentar buscar a cidade", o, (e == null ? void 0 : e.message) || 500, "error");
      return Object.assign(r, {
        _message: `ERROR ${o}`,
        errorResponse: {
          error: e.message
        }
      }), u(r), [];
    }
  }, k = (o) => {
    if (!o) {
      c ? c == null || c() : a == null || a(s);
      return;
    }
    a == null || a(o);
  }, L = C(() => {
    const o = i;
    return s && o.push({
      value: s,
      label: s.description
    }), o;
  }, [i, s]), l = (o, n, p, e) => ({
    friendlyMessage: o,
    _message: `ERROR ${n}`,
    operation: "location",
    error: "Request failed with status code " + p,
    productType: "car",
    _level_name: e,
    packageGroup: E
  });
  return /* @__PURE__ */ P.createElement(A, { oneLineOption: !0, placeholder: "Cidade ou aeroporto", label: g, supportText: y, supportTextProps: R, error: f, value: s, onSearch: b, options: L, onSelect: k, inputProps: O });
}
N.displayName = "CarsLocation";
export {
  N as default
};
