function b(e) {
  const {
    adult: r = 0,
    baby: t = 0,
    children: a = 0
  } = e ?? {}, n = r < 1 || r > 8, d = a > 5, o = t > r;
  return [n || d || o, {
    baby: o && "Número de bebês maior que o de adultos"
  }];
}
export {
  b as default
};
