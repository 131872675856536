import S, { createContext as k, useCallback as l, useMemo as p, useContext as h } from "react";
import { useIsomorphicLayoutEffect as v } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as C, LocalStorageKeys as E, redirectToSearch as M } from "@cvccorp-engines/components";
import { d as x, f as P } from "../formatUrlToSearch-C_Df-S6H.js";
import { useTicketsStore as A } from "../store/TicketsStore.js";
const u = k(void 0);
function w(e) {
  const {
    setErrors: i,
    setDestination: f,
    setDate: m,
    ...t
  } = A(), [o, n] = C(E.LAST_SEARCH_TICKETS);
  v(() => {
    o && !t.destination && (f(o.destination), x(/* @__PURE__ */ new Date()).isAfter(o.date) || m(o.date));
  }, []);
  const c = l(() => {
    let r = !1;
    const s = !t.destination, a = !t.date;
    return r = s || a, [r, {
      destination: s,
      date: a
    }];
  }, [t.date, t.destination]), d = l(() => {
    const [r, s] = c();
    if (i(s), r)
      return;
    const a = P(t).replaceAll("+-+", " - ").replaceAll("+", " ");
    n({
      date: t.date,
      destination: t.destination
    }), M(a);
  }, [i, t, c, n]), T = p(() => ({
    handleSearch: d
  }), [d]);
  return /* @__PURE__ */ S.createElement(u.Provider, { ...e, value: T });
}
w.displayName = "MotorTicketsProvider";
function B() {
  const e = h(u);
  if (!e)
    throw new Error("useMotorTickets must be used within a MotorTicketsProvider");
  return e;
}
export {
  w as default,
  B as useMotorTicketsContext
};
