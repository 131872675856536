import { classes as a } from "../constants/classes.js";
import c from "./countPax.js";
function l(r, t) {
  var o;
  const {
    description: i
  } = c(r), n = `${t ? " - " + ((o = a.find(({
    value: s
  }) => s === t)) == null ? void 0 : o.label) : ""}`;
  return `${i} ${n}`;
}
export {
  l as default
};
