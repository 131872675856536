import et, { createContext as rt, useCallback as nt, useEffect as st, useMemo as it } from "react";
import { useIsomorphicLayoutEffect as at } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as ot, LocalStorageKeys as ut, redirectToSearch as ct } from "@cvccorp-engines/components";
import { useFlightStore as dt } from "./store/FlightStore.js";
import ft from "./utils/formatUrlToSearch.js";
import lt from "./utils/getInitialValues.js";
import ht from "./utils/validateSearch.js";
var J = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function mt(_) {
  return _ && _.__esModule && Object.prototype.hasOwnProperty.call(_, "default") ? _.default : _;
}
var Z = { exports: {} };
(function(_, C) {
  (function(g, c) {
    _.exports = c();
  })(J, function() {
    var g = 1e3, c = 6e4, w = 36e5, O = "millisecond", h = "second", y = "minute", v = "hour", D = "day", k = "week", p = "month", z = "quarter", L = "year", x = "date", N = "Invalid Date", R = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, K = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, Q = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], n = t % 100;
        return "[" + t + (e[(n - 20) % 10] || e[n] || e[0]) + "]";
      }
    }, P = function(t, e, n) {
      var r = String(t);
      return !r || r.length >= e ? t : "" + Array(e + 1 - r.length).join(n) + t;
    }, X = {
      s: P,
      z: function(t) {
        var e = -t.utcOffset(), n = Math.abs(e), r = Math.floor(n / 60), s = n % 60;
        return (e <= 0 ? "+" : "-") + P(r, 2, "0") + ":" + P(s, 2, "0");
      },
      m: function o(t, e) {
        if (t.date() < e.date()) return -o(e, t);
        var n = 12 * (e.year() - t.year()) + (e.month() - t.month()), r = t.clone().add(n, p), s = e - r < 0, i = t.clone().add(n + (s ? -1 : 1), p);
        return +(-(n + (e - r) / (s ? r - i : i - r)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: p,
          y: L,
          w: k,
          d: D,
          D: x,
          h: v,
          m: y,
          s: h,
          ms: O,
          Q: z
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, j = "en", T = {};
    T[j] = Q;
    var q = "$isDayjsObject", U = function(t) {
      return t instanceof A || !(!t || !t[q]);
    }, E = function o(t, e, n) {
      var r;
      if (!t) return j;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        T[s] && (r = s), e && (T[s] = e, r = s);
        var i = t.split("-");
        if (!r && i.length > 1) return o(i[0]);
      } else {
        var u = t.name;
        T[u] = t, r = u;
      }
      return !n && r && (j = r), r || !n && j;
    }, f = function(t, e) {
      if (U(t)) return t.clone();
      var n = typeof e == "object" ? e : {};
      return n.date = t, n.args = arguments, new A(n);
    }, a = X;
    a.l = E, a.i = U, a.w = function(o, t) {
      return f(o, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var A = function() {
      function o(e) {
        this.$L = E(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[q] = !0;
      }
      var t = o.prototype;
      return t.parse = function(e) {
        this.$d = function(n) {
          var r = n.date, s = n.utc;
          if (r === null) return /* @__PURE__ */ new Date(NaN);
          if (a.u(r)) return /* @__PURE__ */ new Date();
          if (r instanceof Date) return new Date(r);
          if (typeof r == "string" && !/Z$/i.test(r)) {
            var i = r.match(R);
            if (i) {
              var u = i[2] - 1 || 0, d = (i[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(i[1], u, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, d)) : new Date(i[1], u, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, d);
            }
          }
          return new Date(r);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return a;
      }, t.isValid = function() {
        return this.$d.toString() !== N;
      }, t.isSame = function(e, n) {
        var r = f(e);
        return this.startOf(n) <= r && r <= this.endOf(n);
      }, t.isAfter = function(e, n) {
        return f(e) < this.startOf(n);
      }, t.isBefore = function(e, n) {
        return this.endOf(n) < f(e);
      }, t.$g = function(e, n, r) {
        return a.u(e) ? this[n] : this.set(r, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, n) {
        var r = this, s = !!a.u(n) || n, i = a.p(e), u = function(b, $) {
          var Y = a.w(r.$u ? Date.UTC(r.$y, $, b) : new Date(r.$y, $, b), r);
          return s ? Y : Y.endOf(D);
        }, d = function(b, $) {
          return a.w(r.toDate()[b].apply(r.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice($)), r);
        }, l = this.$W, m = this.$M, M = this.$D, S = "set" + (this.$u ? "UTC" : "");
        switch (i) {
          case L:
            return s ? u(1, 0) : u(31, 11);
          case p:
            return s ? u(1, m) : u(0, m + 1);
          case k:
            var H = this.$locale().weekStart || 0, F = (l < H ? l + 7 : l) - H;
            return u(s ? M - F : M + (6 - F), m);
          case D:
          case x:
            return d(S + "Hours", 0);
          case v:
            return d(S + "Minutes", 1);
          case y:
            return d(S + "Seconds", 2);
          case h:
            return d(S + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, n) {
        var r, s = a.p(e), i = "set" + (this.$u ? "UTC" : ""), u = (r = {}, r[D] = i + "Date", r[x] = i + "Date", r[p] = i + "Month", r[L] = i + "FullYear", r[v] = i + "Hours", r[y] = i + "Minutes", r[h] = i + "Seconds", r[O] = i + "Milliseconds", r)[s], d = s === D ? this.$D + (n - this.$W) : n;
        if (s === p || s === L) {
          var l = this.clone().set(x, 1);
          l.$d[u](d), l.init(), this.$d = l.set(x, Math.min(this.$D, l.daysInMonth())).$d;
        } else u && this.$d[u](d);
        return this.init(), this;
      }, t.set = function(e, n) {
        return this.clone().$set(e, n);
      }, t.get = function(e) {
        return this[a.p(e)]();
      }, t.add = function(e, n) {
        var r, s = this;
        e = Number(e);
        var i = a.p(n), u = function(M) {
          var S = f(s);
          return a.w(S.date(S.date() + Math.round(M * e)), s);
        };
        if (i === p) return this.set(p, this.$M + e);
        if (i === L) return this.set(L, this.$y + e);
        if (i === D) return u(1);
        if (i === k) return u(7);
        var d = (r = {}, r[y] = c, r[v] = w, r[h] = g, r)[i] || 1, l = this.$d.getTime() + e * d;
        return a.w(l, this);
      }, t.subtract = function(e, n) {
        return this.add(-1 * e, n);
      }, t.format = function(e) {
        var n = this, r = this.$locale();
        if (!this.isValid()) return r.invalidDate || N;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", i = a.z(this), u = this.$H, d = this.$m, l = this.$M, m = r.weekdays, M = r.months, S = r.meridiem, H = function($, Y, W, tt) {
          return $ && ($[Y] || $(n, s)) || W[Y].slice(0, tt);
        }, F = function($) {
          return a.s(u % 12 || 12, $, "0");
        }, I = S || function(b, $, Y) {
          var W = b < 12 ? "AM" : "PM";
          return Y ? W.toLowerCase() : W;
        };
        return s.replace(K, function(b, $) {
          return $ || function(Y) {
            switch (Y) {
              case "YY":
                return String(n.$y).slice(-2);
              case "YYYY":
                return a.s(n.$y, 4, "0");
              case "M":
                return l + 1;
              case "MM":
                return a.s(l + 1, 2, "0");
              case "MMM":
                return H(r.monthsShort, l, M, 3);
              case "MMMM":
                return H(M, l);
              case "D":
                return n.$D;
              case "DD":
                return a.s(n.$D, 2, "0");
              case "d":
                return String(n.$W);
              case "dd":
                return H(r.weekdaysMin, n.$W, m, 2);
              case "ddd":
                return H(r.weekdaysShort, n.$W, m, 3);
              case "dddd":
                return m[n.$W];
              case "H":
                return String(u);
              case "HH":
                return a.s(u, 2, "0");
              case "h":
                return F(1);
              case "hh":
                return F(2);
              case "a":
                return I(u, d, !0);
              case "A":
                return I(u, d, !1);
              case "m":
                return String(d);
              case "mm":
                return a.s(d, 2, "0");
              case "s":
                return String(n.$s);
              case "ss":
                return a.s(n.$s, 2, "0");
              case "SSS":
                return a.s(n.$ms, 3, "0");
              case "Z":
                return i;
            }
            return null;
          }(b) || i.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, n, r) {
        var s, i = this, u = a.p(n), d = f(e), l = (d.utcOffset() - this.utcOffset()) * c, m = this - d, M = function() {
          return a.m(i, d);
        };
        switch (u) {
          case L:
            s = M() / 12;
            break;
          case p:
            s = M();
            break;
          case z:
            s = M() / 3;
            break;
          case k:
            s = (m - l) / 6048e5;
            break;
          case D:
            s = (m - l) / 864e5;
            break;
          case v:
            s = m / w;
            break;
          case y:
            s = m / c;
            break;
          case h:
            s = m / g;
            break;
          default:
            s = m;
        }
        return r ? s : a.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(p).$D;
      }, t.$locale = function() {
        return T[this.$L];
      }, t.locale = function(e, n) {
        if (!e) return this.$L;
        var r = this.clone(), s = E(e, n, !0);
        return s && (r.$L = s), r;
      }, t.clone = function() {
        return a.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, o;
    }(), V = A.prototype;
    return f.prototype = V, [["$ms", O], ["$s", h], ["$m", y], ["$H", v], ["$W", D], ["$M", p], ["$y", L], ["$D", x]].forEach(function(o) {
      V[o[1]] = function(t) {
        return this.$g(t, o[0], o[1]);
      };
    }), f.extend = function(o, t) {
      return o.$i || (o(t, A, f), o.$i = !0), f;
    }, f.locale = E, f.isDayjs = U, f.unix = function(o) {
      return f(1e3 * o);
    }, f.en = T[j], f.Ls = T, f.p = {}, f;
  });
})(Z);
var B = Z.exports;
const G = /* @__PURE__ */ mt(B);
var $t = { exports: {} };
(function(_, C) {
  (function(g, c) {
    _.exports = c(B);
  })(J, function(g) {
    function c(h) {
      return h && typeof h == "object" && "default" in h ? h : {
        default: h
      };
    }
    var w = c(g), O = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(y) {
        return y + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return w.default.locale(O, null, !0), O;
  });
})($t);
G.locale("pt-br");
const _t = rt(void 0);
function pt(_) {
  const {
    setErrors: C,
    setValues: g,
    ...c
  } = dt(), [w, O] = ot(ut.LAST_SEARCH_FLIGHTS);
  at(() => {
    w && !c.destination && g({
      ...w,
      calendarDates: G(/* @__PURE__ */ new Date()).isAfter(w.calendarDates.startDate) ? c.calendarDates : w.calendarDates
    });
  }, []);
  const h = nt(() => {
    const [v, D] = ht(c);
    if (C({
      ...D
    }), v) return;
    const k = ft(c);
    O({
      backToFirstOrigin: c.backToFirstOrigin,
      calendarDates: c.calendarDates,
      choice: c.choice,
      classe: c.classe,
      destination: c.destination,
      multidestinations: c.multidestinations,
      origin: c.origin,
      pax: c.pax
    }), ct(k);
  }, [c, C, O]);
  st(() => {
    const v = lt();
    g(v);
  }, [g]);
  const y = it(() => ({
    handleSearch: h
  }), [h]);
  return /* @__PURE__ */ et.createElement(_t.Provider, { ..._, value: y });
}
pt.displayName = "EngineFlightProvider";
export {
  pt as E,
  _t as a,
  G as d
};
