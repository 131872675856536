import a, { useState as V, useMemo as q } from "react";
import K from "@cvccorp-components/chui-react-components/components/Button";
import { _ as U, a as R, e as J, l as Q, P as k } from "./PesqincFight-Cy3sxUzZ.js";
import { InputGroup as X, InputDatePickerRange as Y, InputDatePicker as Z } from "@cvccorp-engines/components";
import { PaxFlights as ee } from "@cvccorp-engines/pax";
import { useTheme as te } from "@emotion/react";
import ae from "./hooks/useMergedMultDestination.js";
import { useFlightStore as ne } from "./store/FlightStore.js";
import { FlightOptions as y } from "./store/FlightStore.types.js";
import { LocationAndDateWrapper as oe } from "./styled.js";
var le = ["size", "className"], O = function(e) {
  var u, h, s = e.size, c = s === void 0 ? "medium" : s, p = e.className, x = p === void 0 ? "" : p, i = U(e, le), f = i, v = ["chui-icon", x].join(" "), D = f.color || "#000014", g = R({}, i, {
    color: void 0
  }), r = J[c] || Q[c];
  return /* @__PURE__ */ a.createElement("svg", R({
    xmlns: "http://www.w3.org/2000/svg",
    fill: D,
    viewBox: "0 0 24 24",
    width: (u = i.width) != null ? u : r,
    height: (h = i.height) != null ? h : r,
    className: v
  }, g), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M10 9.53a.5.5 0 0 0-.5.5v6.59a.5.5 0 0 0 1 0v-6.59a.5.5 0 0 0-.5-.5M13.606 9.676a.5.5 0 0 1 .854.354v6.59a.5.5 0 0 1-1 0v-6.59a.5.5 0 0 1 .146-.354"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M21.24 4.91h-5.5v-.82a1.82 1.82 0 0 0-1.77-1.82h-4a1.82 1.82 0 0 0-1.83 1.82v.82H2.75a.5.5 0 1 0 0 1h1.49l1.21 14.16a1.81 1.81 0 0 0 1.79 1.67h9.45a1.81 1.81 0 0 0 1.81-1.66l1.19-14.16h1.55a.5.5 0 0 0 0-1zm-12.1-.82a.82.82 0 0 1 .82-.82h4a.82.82 0 0 1 .79.82v.82H9.14zm8.38 15.9a.83.83 0 0 1-.82.75H7.24a.82.82 0 0 1-.81-.75L5.24 5.91h13.42z",
    clipRule: "evenodd"
  }));
};
O.displayName = "OutlineBin";
function fe(_) {
  const {
    errors: e,
    max: u,
    min: h,
    origin: s,
    destination: c,
    isMult: p = !1,
    hidePax: x = !1,
    dates: i = [],
    onRemove: f,
    onReverse: v,
    onDateClear: D,
    onDateChange: g,
    onOriginChange: r,
    onDestinationChange: d
  } = _, $ = te(), [S, P] = V(!1), C = ae(), {
    choice: b,
    pax: F,
    setPax: j,
    clearPax: B,
    classe: G
  } = ne(), m = b === y.GOING_AND_BACK, L = Array.isArray(i) ? i : [i], N = m ? L : L[0], H = m ? "Escolha as datas" : "Escolha a data", W = q(() => {
    if (b !== y.MULTIPLE_DESTINATION) return [];
    const n = C.reduce((o, t, l) => {
      var T, A;
      if (!((T = t == null ? void 0 : t.calendarDates) != null && T.startDate)) return o;
      const E = o[(A = t.calendarDates) == null ? void 0 : A.startDate] ?? [];
      return E.push({
        currentIndex: l,
        ...t
      }), o[t.calendarDates.startDate] = E, o;
    }, {});
    return Object.entries(n).map(([o, t]) => {
      const l = t.map((E) => E.currentIndex + 1);
      return {
        date: new Date(o),
        subtitle: l.length > 1 ? "Voos" : `Voo ${l.join("")}`,
        variant: "border",
        subtitleProps: {
          weight: "bold",
          style: {
            color: $.colors.feedback.active.default[500]
          }
        }
      };
    });
  }, [b, C, $.colors.feedback.active.default]), w = () => {
    var n, o, t, l;
    if (!((n = e == null ? void 0 : e.calendarDates) != null && n.endDate) && !((o = e == null ? void 0 : e.calendarDates) != null && o.startDate))
      return !1;
    if (m && ((t = e == null ? void 0 : e.calendarDates) != null && t.endDate || (l = e == null ? void 0 : e.calendarDates) != null && l.startDate))
      return "Preencha as datas";
    if (e.calendarDates.startDate) return "Preencha a data";
  }, I = () => e != null && e.destination ? "Preencha o campo de destino" : "", M = () => e != null && e.origin ? "Preencha o campo de origem" : "";
  function z(n, o) {
    const {
      city: t,
      state: l
    } = n ?? {};
    return t && l ? `${t}, ${l}` : o;
  }
  return /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(X, { fullWidth: !0, onReverse: () => v == null ? void 0 : v(c, s), status: I() || M() ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ a.createElement(k, { supportText: M(), error: M(), placeholder: "De onde você vai sair?", label: z(s, "Origem"), value: s, onSelect: (n) => {
    r == null || r(n);
  }, onClear: () => {
    r == null || r(void 0);
  } }), /* @__PURE__ */ a.createElement(k, { supportText: I(), error: I(), placeholder: "Para onde você vai?", value: c, onSelect: (n) => {
    d == null || d(n);
  }, label: z(c, "Destino"), onClear: () => {
    d == null || d(void 0);
  } })), /* @__PURE__ */ a.createElement(oe, { isMult: p }, m ? /* @__PURE__ */ a.createElement(
    Y,
    {
      showRangeCount: !0,
      max: u,
      min: h,
      value: N,
      onChange: g,
      onClear: D,
      error: {
        startDate: w() || "",
        endDate: w() ? " " : void 0
      }
    }
  ) : /* @__PURE__ */ a.createElement(Z, { label: H, value: N, onChange: g, onClear: D, range: m, twoMonths: m || p, max: u, min: h, options: W, showRangeCount: !0, error: w() }), f ? /* @__PURE__ */ a.createElement(K, { icon: /* @__PURE__ */ a.createElement(O, null), variant: "text", color: "neutral", size: "lg", type: "button", onClick: f }) : void 0, !x && /* @__PURE__ */ a.createElement(ee, { open: S, onToggle: P, value: F, classe: G, onConfirm: (n) => {
    j(n), P(!1);
  }, onClear: () => {
    B(), P(!1);
  }, error: e == null ? void 0 : e.pax })));
}
export {
  fe as L,
  O as v
};
