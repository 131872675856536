import u from "@cvccorp-components/chui-react-components/lib/chuiDate";
import { calcAge as c } from "@cvccorp-engines/pax";
function S(e, i) {
  let r = !1;
  const s = (e == null ? void 0 : e.reduce((t, g, a) => (g.childrenDateOfBirth.forEach((n, o) => {
    if (!u(n).isValid()) {
      t[`${String(a)}-${String(o)}`] = "Informe a data de nascimento", r = !0;
      return;
    }
    if (i && new Date(n).getTime() > new Date(i).getTime()) {
      t[`${String(a)}-${String(o)}`] = "Data é posterior ao fim da viagem*", r = !0;
      return;
    }
    const f = c(n, i);
    (f > 17 || f < 0) && (r = !0, t[`${String(a)}-${String(o)}`] = "Idade não está entre 0 e 17 anos*");
  }), t), {})) ?? {};
  return [r, s];
}
export {
  S as default
};
