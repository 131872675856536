import { DEFAULT_ROOM as o } from "@cvccorp-engines/pax";
import { create as n } from "zustand";
import { getResortsPaxParam as D, getResortsStartDateParam as d, getResortsEndDateParam as i, getResortsDestinationParam as l } from "../utils/getResortsParams.js";
import "@cvccorp-engines/components";
import "@cvccorp-components/chui-react-components/lib/chuiDate";
const t = {
  destination: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  pax: !1
}, f = n((a) => ({
  pax: D(),
  calendarDates: {
    startDate: d(),
    endDate: i()
  },
  destination: l(),
  errors: t,
  errorsPax: {},
  openPax: !1,
  setDestination: (r) => a((e) => {
    var s;
    return {
      destination: r,
      inputFocus: (s = e.calendarDates) != null && s.startDate ? "pax" : "dates",
      errors: {
        ...e.errors,
        destination: t.destination
      }
    };
  }),
  setCalendarDates: (r) => a((e) => ({
    calendarDates: r,
    inputFocus: "pax",
    errors: {
      ...e.errors,
      calendarDates: t.calendarDates
    }
  })),
  setPax: (r) => a((e) => ({
    pax: r,
    errorsPax: {},
    errors: {
      ...e.errors,
      pax: !1
    }
  })),
  setErrors: (r) => a(() => ({
    errors: r
  })),
  setErrorsPax: (r) => a(() => ({
    errorsPax: r
  })),
  setOpenPax: (r) => a(() => ({
    openPax: r
  })),
  clearPax: () => a((r) => ({
    pax: [o],
    errors: {
      ...r.errors,
      pax: !1
    },
    errorsPax: {}
  })),
  clearDates: () => a((r) => ({
    calendarDates: {
      endDate: void 0,
      startDate: void 0
    },
    errors: {
      ...r.errors,
      calendarDates: t.calendarDates
    }
  }))
}));
export {
  f as useResortsStore
};
