import { FlightOptions as m } from "../store/FlightStore.types.js";
function O(n) {
  var d, r;
  let t = !1;
  const f = () => Object.entries(n.multidestinations).reduce((N, [E, s]) => {
    var g;
    const D = !s.destination, l = !s.origin, u = !((g = s.calendarDates) != null && g.startDate);
    return t = D || l || u, {
      ...N,
      [Number(E)]: {
        destination: D,
        origin: l,
        calendarDates: {
          startDate: u,
          endDate: !1
        }
      }
    };
  }, {}), e = !n.destination, a = !n.classe, i = !n.origin, c = !(n.choice !== m.GOING_AND_BACK || (d = n.calendarDates) != null && d.endDate), o = !((r = n.calendarDates) != null && r.startDate);
  t = e || i || c || o || a;
  const h = n.choice === m.MULTIPLE_DESTINATION ? f() : {};
  return [t, {
    destination: e,
    origin: i,
    multidestinations: h,
    classe: a,
    calendarDates: {
      endDate: c,
      startDate: o
    }
  }];
}
export {
  O as default
};
