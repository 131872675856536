import i from "@emotion/styled";
const p = i.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    .chui-input > div {
        height: 56px;
    }
`, o = i.div(({
  theme: t
}) => `
    display: flex;
    justify-content: center;
    gap: 4px;
    @media (max-width: ${t.breakpoints.layoutGridSm}) {
        flex-direction: column;
    }
`);
export {
  o as DateWrapper,
  p as LocationWrapper
};
