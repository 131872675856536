import { useState as l, useCallback as u, useMemo as r } from "react";
const g = (o = !1) => {
  const [e, t] = l(o), s = u(() => {
    t((a) => !a);
  }, []);
  return r(() => ({
    value: e,
    toggle: s,
    setValue: t
  }), [s, e]);
};
export {
  g as default
};
