import { FlightClasses as C, DEFAULT_TRAVERS as d } from "@cvccorp-engines/pax";
import { FlightOptions as D } from "../store/FlightStore.types.js";
function w(i) {
  const a = /* @__PURE__ */ new Map(), e = /* @__PURE__ */ new Map();
  return i.forEach((t, s) => {
    const n = s.startsWith("MCO"), o = s.startsWith("MCD");
    if (!n && !o) return;
    const c = n ? "MCO" : "MCD", g = s.replace(c, "");
    n ? a.set(g, t) : e.set(g, t);
  }), {
    originMastercodes: a,
    destinationMastercodes: e,
    totalOrigin: a.size,
    totalDestination: e.size
  };
}
function I(i) {
  const a = /* @__PURE__ */ new Map();
  return i.forEach((e, t) => {
    if (!t.startsWith("Date")) return;
    const n = t.replace("Date", "");
    a.set(n, (/* @__PURE__ */ new Date(e + " 00:00")).toISOString());
  }), {
    dates: a
  };
}
function A(i, a) {
  const e = [], t = i.split("/");
  t.splice(0, t.length - a * 2);
  for (let s = 0; s < t.length; s += 2)
    e.push([t[s], t[s + 1]]);
  return e;
}
function y() {
  var p, h;
  if (!window) return {};
  const {
    searchParams: i,
    pathname: a
  } = new URL(window.location.href);
  if (i.size === 0) return {};
  const {
    originMastercodes: e,
    destinationMastercodes: t,
    totalOrigin: s
  } = w(i), {
    dates: n
  } = I(i), o = A(a, s), c = n.size, g = i.get("CLA") ?? C.ALL;
  let l = D.GOING_AND_BACK;
  c === 1 && (l = D.ONLY_GOING), c > 2 && (l = D.MULTIPLE_DESTINATION);
  const u = {
    adult: +(i.get("ADT") ?? d.adult),
    baby: +(i.get("INF") ?? d.baby),
    children: +(i.get("CHD") ?? d.children)
  }, f = !!e.get("1"), M = !!t.get("1"), O = !!(n.get("1") && n.get("2"));
  return {
    choice: l,
    pax: u,
    classe: g,
    origin: f ? {
      description: e.get("1"),
      city: e.get("1"),
      iata: (p = o[0]) == null ? void 0 : p[0]
    } : void 0,
    destination: M ? {
      description: t.get("1"),
      city: t.get("1"),
      iata: (h = o[0]) == null ? void 0 : h[1]
    } : void 0,
    calendarDates: O ? {
      startDate: n.get("1"),
      endDate: n.get("2")
    } : void 0,
    multidestinations: o.splice(1, o.length).map(([S, m], r) => ({
      calendarDates: {
        startDate: n.get(String(r + 2)),
        endDate: n.get(String(r + 3))
      },
      origin: {
        iata: S,
        description: e.get(String(r + 2)),
        city: e.get(String(r + 2))
      },
      destination: {
        iata: m,
        description: t.get(String(r + 2)),
        city: t.get(String(r + 2))
      }
    }))
  };
}
export {
  y as default
};
