import o from "react";
import { _ as y, a as v, e as w, l as z, m as g } from "../../OutlineSingleUser-Dphw4daL.js";
import { useTheme as _ } from "@emotion/react";
import { Container as $, Item as N } from "./Short.styles.js";
var x = ["size", "className"], h = function(a) {
  var c, r, i = a.size, l = i === void 0 ? "medium" : i, e = a.className, m = e === void 0 ? "" : e, t = y(a, x), d = t, u = ["chui-icon", m].join(" "), p = d.color || "#000014", f = v({}, t, {
    color: void 0
  }), n = w[l] || z[l];
  return /* @__PURE__ */ o.createElement("svg", v({
    xmlns: "http://www.w3.org/2000/svg",
    fill: p,
    viewBox: "0 0 24 24",
    width: (c = t.width) != null ? c : n,
    height: (r = t.height) != null ? r : n,
    className: u
  }, f), /* @__PURE__ */ o.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M6.624 4.728A2.817 2.817 0 0 0 3.81 7.551v3.035A2.3 2.3 0 0 0 2 12.834v4.141a2.295 2.295 0 0 0 2.29 2.298h15.42c1.264 0 2.29-1.029 2.29-2.298v-4.14a2.3 2.3 0 0 0-1.792-2.245V7.552a2.817 2.817 0 0 0-2.813-2.823zm12.588 5.808V7.551a1.82 1.82 0 0 0-1.817-1.824H6.624a1.82 1.82 0 0 0-1.817 1.824v2.985h.728v-1a1.04 1.04 0 0 0-.191-.596l-.004-.006-.066-.1c-.271-.356-.216-.845.064-1.157l.007-.008.008-.007c.303-.305.795-.396 1.18-.122a.98.98 0 0 0 .539.173h2.893c.19 0 .375-.063.545-.176l.007-.005c.345-.22.866-.208 1.182.145.278.31.339.807.04 1.165l-.065.098a1.04 1.04 0 0 0-.19.597v.999h1.014v-1a1.04 1.04 0 0 0-.19-.596l-.005-.006-.066-.1c-.27-.356-.215-.845.064-1.157l.007-.008.008-.007c.303-.305.796-.396 1.18-.122a.98.98 0 0 0 .539.173h2.893c.19 0 .376-.063.545-.176l.007-.005c.346-.22.866-.208 1.182.145.278.31.339.807.04 1.165l-.065.098a1.04 1.04 0 0 0-.19.597v.999zm-1.562 0v-1c0-.384.12-.742.33-1.048l.09-.134.018-.021a.06.06 0 0 0 .015-.045c0-.022-.01-.05-.033-.076-.008-.01-.028-.023-.064-.027a.16.16 0 0 0-.096.02 1.77 1.77 0 0 1-.982.308h-2.893c-.37 0-.708-.124-.986-.311l-.007-.005-.007-.004a.08.08 0 0 0-.062-.016.16.16 0 0 0-.086.042.14.14 0 0 0-.03.077.08.08 0 0 0 .014.054l.011.014.082.124c.21.306.33.664.33 1.049v.999zm-11.319-1v1h4.356v-1c0-.384.12-.742.33-1.048l.089-.134.02-.021a.06.06 0 0 0 .014-.045c0-.022-.01-.05-.033-.076-.009-.01-.028-.023-.064-.027a.16.16 0 0 0-.096.02 1.77 1.77 0 0 1-.982.308H7.072c-.37 0-.708-.124-.987-.311l-.006-.005-.007-.004a.08.08 0 0 0-.062-.016.16.16 0 0 0-.086.042.14.14 0 0 0-.03.077.08.08 0 0 0 .014.054l.01.014.083.124c.21.306.33.664.33 1.049m-2.041 2c-.714 0-1.294.581-1.294 1.298v.044h18.008v-.044c0-.717-.58-1.299-1.294-1.299zm-1.294 5.44v-3.098h18.008v3.097c0 .717-.58 1.299-1.294 1.299H4.29a1.297 1.297 0 0 1-1.294-1.299",
    clipRule: "evenodd"
  }));
};
h.displayName = "OutlineBed";
const E = (s) => {
  const {
    peopleQuantity: a,
    roomsQuantity: c
  } = s, i = _().colors.support.primary, l = [{
    Icon: h,
    quantity: c,
    key: "rooms"
  }, {
    Icon: g,
    quantity: a,
    key: "people"
  }].filter(({
    quantity: e
  }) => e);
  if (l.length)
    return /* @__PURE__ */ o.createElement($, null, l.map(({
      Icon: e,
      quantity: m,
      key: t
    }) => /* @__PURE__ */ o.createElement(N, { key: t }, /* @__PURE__ */ o.createElement(e, { size: "sm", color: i }), m)));
};
E.displayName = "Short";
export {
  E as default
};
