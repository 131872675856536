import { ReactNode, createContext, useContext, useCallback, useMemo } from "react";
import { useIsomorphicLayoutEffect } from "@cvccorp-components/chui-react-components";
import { LocalStorageKeys, redirectToSearch, useParsedLocalStorage } from "@cvccorp-engines/components";
import dayjs from "@cvccorp-engines/date";
import { PackageStatePersisted, PackageStoreState, usePackageStore } from "../shared/Context/PackageStore";
import formatUrlToSearch from "./utils/formatUrlToSearch";
import validateChildrenPaxDate from "./utils/validateChildrenPaxDate";

interface MotorPackageContextValues {
    handleSearch: () => void;
}

const MotorPackageContext = createContext<MotorPackageContextValues>(undefined as unknown as MotorPackageContextValues);

function MotorPackageProvider(props: { children: ReactNode }) {
    const { setErrors, setErrorsPax, setDestination, setCalendarDates, setPax, setOrigin, ...packageStoreState } =
        usePackageStore();
    const [persistedPackage, setPersistedPackage] = useParsedLocalStorage<PackageStatePersisted>(
        LocalStorageKeys.LAST_SEARCH_PACKAGES,
        undefined,
    );

    useIsomorphicLayoutEffect(() => {
        if (!packageStoreState.destination && persistedPackage) {
            setDestination(persistedPackage.destination);
            setOrigin(persistedPackage.origin);
            setPax(persistedPackage.pax);
            if (!dayjs(new Date()).isAfter(persistedPackage.calendarDates.startDate))
                setCalendarDates(persistedPackage.calendarDates);
        }
    }, []);

    const validateSearch = useCallback((): [error: boolean, errors: PackageStoreState["errors"]] => {
        let hasError = false;

        const pax = !Boolean(packageStoreState.pax);
        const destination = !Boolean(packageStoreState.destination);
        const origin = !Boolean(packageStoreState.origin);
        const endDate = !Boolean(packageStoreState.calendarDates?.endDate);
        const startDate = !Boolean(packageStoreState.calendarDates?.startDate);

        hasError = destination || origin || endDate || startDate || pax;

        return [
            hasError,
            {
                destination,
                origin,
                pax,
                calendarDates: {
                    endDate,
                    startDate,
                },
            },
        ];
    }, [
        packageStoreState.calendarDates?.endDate,
        packageStoreState.calendarDates?.startDate,
        packageStoreState.destination,
        packageStoreState.origin,
        packageStoreState.pax,
    ]);

    const handleSearch = useCallback(() => {
        const endDate = packageStoreState.calendarDates?.endDate;
        const [error, errors] = validateSearch();
        const [errorPax, paxErrors] = validateChildrenPaxDate(packageStoreState.pax, endDate);

        setErrors({ ...errors, pax: errorPax });
        setErrorsPax(paxErrors);

        if (error || errorPax) {
            return;
        }

        const formattedSearchURL = formatUrlToSearch(packageStoreState).replaceAll("+,+", ", ").replaceAll("+", " ");
        setPersistedPackage({
            calendarDates: packageStoreState.calendarDates,
            origin: packageStoreState.origin,
            destination: packageStoreState.destination,
            pax: packageStoreState.pax,
        });
        redirectToSearch(formattedSearchURL);
    }, [packageStoreState, setErrors, setErrorsPax, validateSearch, setPersistedPackage]);

    const value = useMemo(() => {
        return { handleSearch };
    }, [handleSearch]);

    return <MotorPackageContext.Provider {...props} value={value} />;
}

export function useMotorPackageContext() {
    const context = useContext(MotorPackageContext);

    if (!context)
        throw new Error(
            "Chamada do hook useMotorPackageContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorPackageContext",
        );

    return context;
}

export default MotorPackageProvider;
