import t, { useMemo as n } from "react";
import i from "@cvccorp-components/chui-react-components/components/Select";
import { useAutoCompleteItemSize as p } from "@cvccorp-engines/components";
import { usePax as l } from "../PaxPackages/PaxProvider/PaxProvider.js";
import s from "./getOptionsAgeInputSelect.js";
import { Container as c } from "./SelectAges.styles.js";
const a = (o) => {
  const {
    infantAge: e
  } = l(), m = p(), r = n(() => s(e), [e]);
  return /* @__PURE__ */ t.createElement(c, null, /* @__PURE__ */ t.createElement(i, { itemSize: m, ...o, options: r }));
};
a.displayName = "SelectAge";
export {
  a as default
};
