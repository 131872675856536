import n, { Children as s, isValidElement as p, cloneElement as C } from "react";
import { CollapsableContainer as E } from "./styled.js";
const r = () => {
}, c = (o) => {
  const {
    renderContentAfter: t = r,
    renderContentBefore: l = r,
    renderExpandedEngine: a,
    children: i,
    isExpanded: d
  } = o, m = s.map(i, (e) => p(e) ? C(e, {
    className: "engine-motor"
  }) : e);
  return /* @__PURE__ */ n.createElement("div", null, /* @__PURE__ */ n.createElement(E, null, l(), m, t()), d ? a() : void 0);
};
c.displayName = "CollapsableEngineContainer";
export {
  c as default
};
