import r from "react";
import { Typography as p } from "@cvccorp-components/chui-react-components";
import "../../FilledPlay-Bo3nTh9_.js";
import "../../FilledBed-CZJxOTKc.js";
import "../../FilledMoney-Dh2WYsyR.js";
import { m as i } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineArrowRight-fe4YoXL9.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { d as o } from "../../dayjs-CKf7tiKR.js";
import { useTheme as l } from "@emotion/react";
import { Container as n } from "./styled.js";
const s = ({
  initialDate: e,
  finalDate: m,
  dateFormat: t
}) => {
  const a = l().colors.support.primary;
  return /* @__PURE__ */ r.createElement(n, null, /* @__PURE__ */ r.createElement(i, { size: "sm", color: a }), /* @__PURE__ */ r.createElement(p, { variant: "paragraph", scale: 3, color: "support.primary" }, o(e.toString()).format(t), " - ", o(m.toString()).format(t)));
};
s.displayName = "DateIntervalLabel";
export {
  s as default
};
