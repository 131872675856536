class e {
  constructor(t = "") {
    this._text = t;
  }
  trim() {
    return this._text = this._text.replace(/^\s+|\s+$/g, ""), this;
  }
  text(t) {
    return this._text = String(t), this;
  }
  encodeURI() {
    return this._text = encodeURI(this._text), this;
  }
  normalizeNFD() {
    return this._text = this._text.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), this;
  }
  replaceSpace() {
    return this._text = this._text.replace(/ /g, "+"), this;
  }
  toString() {
    if (this._text)
      return this.trim()._text;
  }
}
const i = new e();
export {
  i as default
};
