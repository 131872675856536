"use client";
import e from "react";
import t from "@cvccorp-components/chui-react-components/components/Grid";
import l from "@cvccorp-components/chui-react-components/components/Typography";
import { Container as o } from "./Label.styles.js";
function m(r) {
  const {
    children: a,
    text: n,
    helperText: i
  } = r;
  return /* @__PURE__ */ e.createElement(o, null, /* @__PURE__ */ e.createElement(t, { container: !0, alignItems: "center", style: {
    padding: 0
  } }, /* @__PURE__ */ e.createElement(t, { style: {
    padding: 0
  }, item: !0, xs: 6 }, /* @__PURE__ */ e.createElement("div", { style: {
    maxWidth: 126
  } }, /* @__PURE__ */ e.createElement(l, { variant: "label", scale: 8, color: "neutral.black", weight: "bold" }, n), /* @__PURE__ */ e.createElement("br", null), /* @__PURE__ */ e.createElement(l, { variant: "label", scale: 8, color: "support.optional", weight: "regular", style: {
    maxWidth: 80
  } }, i))), /* @__PURE__ */ e.createElement(t, { style: {
    padding: 0,
    display: "flex",
    justifyContent: "flex-end"
  }, item: !0, xs: 6 }, a)));
}
m.displayName = "Label";
export {
  m as default
};
