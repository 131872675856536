import { useContext as o } from "react";
import { EngineResortsContext as t } from "./EngineResortsContext.js";
function s() {
  const e = o(t);
  if (!e) throw new Error("Chamada do hook useEngineResortsContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineResortsContext");
  return e;
}
export {
  s as useEngineResortsContext
};
