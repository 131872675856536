import { FC, Fragment } from "react";
import LocationAndDate from "../LocationAndDate";
import { DefaultLocationFormProps } from "./types";

const DefaultLocationForm: FC<DefaultLocationFormProps> = props => {
    const {
        children,
        onDateChange,
        onDateClear,
        max,
        min,
        origin,
        destination,
        onDestinationChange,
        onOriginChange,
        onOpenPax,
        onReverse,
        dates = [],
        errors = {},
        isMult = false,
    } = props;

    return (
        <Fragment>
            <LocationAndDate
                errors={errors}
                dates={dates}
                onDateClear={onDateClear}
                onDateChange={onDateChange}
                max={max}
                min={min}
                isMult={isMult}
                origin={origin}
                destination={destination}
                onDestinationChange={onDestinationChange}
                onOriginChange={onOriginChange}
                onReverse={onReverse}
                onOpenPax={onOpenPax}
            />

            {children}
        </Fragment>
    );
};

export default DefaultLocationForm;
