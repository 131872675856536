import { useMemo as m, useEffect as l } from "react";
import { useCarsStore as O } from "../../store/CarsStore.js";
import T from "../../utils/getOtpionsTime.js";
const g = "10:00";
function c(r) {
  const [s, i] = r.split(":").map(Number);
  return s * 60 + i;
}
function H(r) {
  const s = c(g);
  let i = null, n = Number.POSITIVE_INFINITY;
  for (const e of r) {
    const t = c(e.value);
    if (t === s)
      return e;
    t > s && t < n && (i = e, n = t);
  }
  return i || r[0];
}
function a() {
  const {
    pickupTime: r,
    pickupDate: s,
    returnTime: i,
    returnDate: n,
    pickupLocation: e,
    returnLocation: t,
    setPickupTime: I,
    setReturnTime: M
  } = O(), u = m(() => T(s, e == null ? void 0 : e.officeHours), [s, e == null ? void 0 : e.officeHours]);
  l(() => {
    if (!u.length || r) return;
    const f = H(u);
    I(f.value);
  }, [u]);
  const o = m(() => T(n, (t == null ? void 0 : t.officeHours) || (e == null ? void 0 : e.officeHours)), [e == null ? void 0 : e.officeHours, n, t == null ? void 0 : t.officeHours]);
  return l(() => {
    if (!o.length || i) return;
    const f = H(o);
    M(f.value);
  }, [o]), {
    pickupOptions: u,
    returnOptions: o
  };
}
export {
  a as default
};
