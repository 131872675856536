import { getBaseHostname as p, formatDateToParam as h, encodeSearchURL as m } from "@cvccorp-engines/components";
import { calcChildAges as u } from "@cvccorp-engines/pax";
function g(e) {
  var n, o, s, c, d;
  const a = new URL(`${p()}/hotel/search`);
  a.searchParams.append("CheckIn", h((n = e.calendarDates) == null ? void 0 : n.startDate)), a.searchParams.append("CheckOut", h((o = e.calendarDates) == null ? void 0 : o.endDate)), a.searchParams.append("Rooms", String(((s = e.pax) == null ? void 0 : s.length) ?? ""));
  const l = ((c = e.pax) == null ? void 0 : c.map((t) => {
    var r;
    return (r = t.adult) == null ? void 0 : r.toString();
  }).filter(Boolean)) || [];
  a.searchParams.append("Adults", l.join(","));
  const i = ((d = e.pax) == null ? void 0 : d.map((t) => {
    var r;
    return (r = t.children) == null ? void 0 : r.toString();
  }).filter(Boolean)) || [];
  return a.searchParams.append("Children", i.join(",")), a.searchParams.append("ChildAges", u(e.pax)), a.searchParams.append("showResorts", "true"), a.searchParams.append("inter", String(e.destination)), m(a);
}
export {
  g as default
};
