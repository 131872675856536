import S, { createContext as k, useCallback as x, useMemo as F, useContext as E } from "react";
import { useIsomorphicLayoutEffect as v } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as L, LocalStorageKeys as M, redirectToSearch as A } from "@cvccorp-engines/components";
import { d as y } from "../dayjs-CKf7tiKR.js";
import { usePackageFullStoreStore as B } from "../store/EnginePackageFullStore.js";
import w from "../utils/formatUrlToSearch.js";
import R from "../utils/validateChildrenPaxDate.js";
const f = k(void 0);
function T(o) {
  const {
    setErrors: c,
    setErrorsPax: l,
    setCalendarDates: p,
    setDestination: g,
    setPax: P,
    setOrigin: D,
    ...e
  } = B(), [t, d] = L(M.LAST_SEARCH_PACKAGE_FULL);
  v(() => {
    t && !e.origin && (D(t.origin), g(t.destination), P(t.pax), y(/* @__PURE__ */ new Date()).isAfter(t.calendarDates) || p(t.calendarDates));
  }, []);
  const u = x(() => {
    let r = !1;
    const n = !e.pax, s = !e.destination, a = !e.origin, i = !e.calendarDates;
    return r = s || a || i || n, [r, {
      destination: s,
      origin: a,
      pax: n,
      calendarDates: {
        startDate: i
      }
    }];
  }, [e.calendarDates, e.destination, e.origin, e.pax]), m = x(() => {
    const r = e.calendarDates, [n, s] = u(), [a, i] = R(e.pax, r);
    if (c({
      ...s,
      pax: a
    }), l(i), n || a)
      return;
    const C = w(e);
    d({
      calendarDates: e.calendarDates,
      destination: e.destination,
      origin: e.origin,
      pax: e.pax
    }), A(C);
  }, [e, c, l, u, d]), h = F(() => ({
    handleSearch: m
  }), [m]);
  return /* @__PURE__ */ S.createElement(f.Provider, { ...o, value: h });
}
T.displayName = "MotorPackageFullProvider";
function H() {
  const o = E(f);
  if (!o) throw new Error("Chamada do hook useMotorPackageFullContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorPackageFullContext");
  return o;
}
export {
  T as default,
  H as useMotorPackageFullContext
};
