function s(t) {
  const c = "áàâãéèêíïóôõöúñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÑ", a = "aaaaeeeiioooounAAAAEEEIIOOOOUN";
  let n = "";
  for (let o = 0; o < t.length; o++) {
    const e = t[o], r = c.indexOf(e), i = a[r] || e;
    n = n + i;
  }
  return n;
}
export {
  s as default
};
