const S = (f) => {
  const {
    closingTime: o,
    openingTime: r
  } = f, u = /* @__PURE__ */ new Set();
  function s(t) {
    const [e, i] = t.split(":").map(Number), g = /* @__PURE__ */ new Date();
    return g.setHours(e, i, 0, 0), g;
  }
  function l(t) {
    const e = t.getHours().toString().padStart(2, "0"), i = t.getMinutes().toString().padStart(2, "0");
    return `${e}:${i}`;
  }
  function c(t) {
    const e = t.getMinutes();
    return e > 0 && e <= 30 ? t.setMinutes(30, 0, 0) : e > 30 && t.setHours(t.getHours() + 1, 0, 0, 0), t;
  }
  const n = c(s(r));
  let m = c(s(o));
  for (r === "00:00" && o === "00:00" && (m = s("23:00")); n <= m; )
    u.add(l(n)), n.setMinutes(n.getMinutes() + 30);
  return [...u.values()];
};
export {
  S as workingTime
};
