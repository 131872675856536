import { FlightOptions as d } from "../store/FlightStore.types.js";
import m from "./mergeDestinations.js";
function l(i) {
  var o, e;
  const t = m(i), [n] = t;
  return i.choice !== d.MULTIPLE_DESTINATION ? `/${(o = n.origin) == null ? void 0 : o.iata}/${(e = n.destination) == null ? void 0 : e.iata}` : t.reduce((r, a) => {
    var s, f;
    return r += "/" + [(s = a.origin) == null ? void 0 : s.iata, (f = a.destination) == null ? void 0 : f.iata].filter((c) => !!c).join("/"), r;
  }, "");
}
export {
  l as default
};
