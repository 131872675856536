import { Grid as ChuiGrid } from "@cvccorp-components/chui-react-components";
import styled from "@emotion/styled";

export const Grid = styled(ChuiGrid)`
    gap: 32px;

    @media (max-width: 600px) {
        gap: 12px;
    }
`;

export const EngineContainer = styled.div`
    padding: 16px 0;
`;

export const ChipsContainer = styled.div`
    display: flex;
    gap: 16px;
`;
