import i from "@emotion/styled";
const o = i.div`
    min-width: 320px;
    padding: 18px;
`, t = i.div`
    display: block;
`;
export {
  o as Container,
  t as Label
};
