"use client";
import w, { createContext as b, useCallback as h, useMemo as x } from "react";
import { useIsomorphicLayoutEffect as I } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as N, LocalStorageKeys as _, setDataLayer as z, redirectToSearch as H } from "@cvccorp-engines/components";
import { d as c } from "../dayjs-CKf7tiKR.js";
import { CarsTags as j } from "../constants/dataLayerTags.js";
import { useCarsStore as J } from "../store/CarsStore.js";
import { formatUrlToSearch as K } from "../utils/formatUrlToSearch.js";
const M = b(void 0);
function U(D) {
  const {
    errors: p,
    pickupLocation: r,
    showReturnLocation: s,
    returnLocation: i,
    pickupDate: a,
    returnDate: n,
    pickupTime: u,
    returnTime: d,
    setErrors: l,
    setPickupDate: k,
    setPickupLocation: C,
    setPickupTime: S,
    setReturnDate: v,
    setReturnLocation: P,
    setReturnTime: T,
    setShowReturnLocation: R
  } = J(), [o, m] = N(_.LAST_SEARCH_CARS);
  I(() => {
    if (o && !r) {
      const e = c(/* @__PURE__ */ new Date());
      C(o.pickupLocation), R(o.showReturnLocation), P(o.returnLocation), e.isAfter(E(a, u)) || (S(o.pickupTime), k(o.pickupDate), v(o.returnDate), T(o.returnTime));
    }
  }, []);
  const E = (e, t) => {
    const [y, g] = t.split(":");
    return c(e).add(Number(y), "hours").add(Number(g), "minutes");
  }, f = h(() => {
    let e = !1;
    const t = {
      ...p
    };
    return !r || r.zoneId <= 0 ? (t.pickupLocation = "Preencha o local de retirada", e = !0) : t.pickupLocation = !1, s && (!i || i.zoneId <= 0) ? (t.returnLocation = "Preencha o local de devolução", e = !0) : t.returnLocation = !1, a ? t.pickupDate = !1 : (t.pickupDate = "Preencha a data de retirada", e = !0), n ? a && c(n) < c(a) ? (t.returnDate = "A data de devolução deve ser posterior à data de retirada", e = !0) : t.returnDate = !1 : (t.returnDate = "Preencha a data de devolução", e = !0), l({
      ...t
    }), e;
  }, [p, a, r, n, i, l, s]), L = h(() => {
    if (!f() && r) {
      const e = K(s, {
        pickupLocation: r,
        returnLocation: i,
        pickupDate: a,
        returnDate: n,
        pickupTime: u,
        returnTime: d
      });
      z(j.SEARCH_CARS), m({
        pickupDate: a,
        pickupLocation: r,
        pickupTime: u,
        returnDate: n,
        returnLocation: i,
        returnTime: d,
        showReturnLocation: s
      }), e && H(e);
    }
  }, [a, r, u, n, i, d, s, f, m]), A = x(() => ({
    handleSearch: L
  }), [L]);
  return /* @__PURE__ */ w.createElement(M.Provider, { ...D, value: A });
}
U.displayName = "EngineCarsProvider";
export {
  M as EngineCarsContext,
  U as default
};
