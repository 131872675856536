import e from "react";
import i from "@cvccorp-components/chui-react-components/components/DatePicker/DatePickerDivider";
import t from "@cvccorp-components/chui-react-components/components/Grid";
import C from "@cvccorp-components/chui-react-components/components/Spinner";
import { InputDatePicker as h } from "@cvccorp-engines/components";
import { useCalendarPicker as P } from "./useCalendarPicker.js";
function I(o) {
  const {
    min: c,
    max: m,
    errors: a,
    options: d,
    isMobile: n,
    isLoading: l,
    currentDate: s,
    initialDate: u,
    priceWrapperDirection: r,
    clearDates: E,
    handleChange: g,
    handleDisableDates: p,
    handleChangeCalendar: D
  } = P(o), f = (a == null ? void 0 : a.calendarDates.endDate) || (a == null ? void 0 : a.calendarDates.startDate);
  return /* @__PURE__ */ e.createElement(h, { showRangeCount: !0, range: !1, label: "Escolha a data", max: m, min: c, options: d, isLoading: l, value: s[0], calendarDateVisible: u, error: f ? "Preencha a data" : "", onClear: E, onChange: g, disableDates: p, onChangeCalendar: D, content: l ? /* @__PURE__ */ e.createElement(t, { container: !0, alignItems: "center" }, /* @__PURE__ */ e.createElement(t, { item: !0 }, /* @__PURE__ */ e.createElement(C, { color: "secondary" })), /* @__PURE__ */ e.createElement(t, { item: !0 }, "Carregando preços e datas disponíveis")) : /* @__PURE__ */ e.createElement(t, { container: !0, spacing: n ? 0 : 2 }, /* @__PURE__ */ e.createElement(t, { item: !0 }, /* @__PURE__ */ e.createElement(t, { alignItems: "center", container: !0, direction: r, style: n ? {
    fontSize: 13,
    marginInlineEnd: 16
  } : {} }, "Preço normal", /* @__PURE__ */ e.createElement(i, { variant: "regular" }))), /* @__PURE__ */ e.createElement(t, { item: !0 }, /* @__PURE__ */ e.createElement(t, { alignItems: "center", container: !0, direction: r, style: n ? {
    fontSize: 13,
    marginInlineEnd: 16
  } : {} }, "Menor Preço", /* @__PURE__ */ e.createElement(i, { variant: "good" }))), /* @__PURE__ */ e.createElement(t, { item: !0 }, /* @__PURE__ */ e.createElement(t, { alignItems: "center", container: !0, direction: r, style: n ? {
    fontSize: 13,
    marginInlineEnd: 16
  } : {} }, "Preço promocional", /* @__PURE__ */ e.createElement(i, { variant: "bad" })))) });
}
I.displayName = "CalendarPicker";
export {
  I as default
};
