"use client";
import i, { useRef as S, useState as b, useCallback as C } from "react";
import O from "@cvccorp-components/chui-react-components/components/Autocomplete";
import A from "@cvccorp-components/chui-react-components/components/Typography";
import E from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import L from "../../hooks/useAutoCompleteItemSize/useAutoCompleteItemSize.js";
import { Container as q } from "./Pesqinc.styles.js";
function z(a) {
  const {
    error: t,
    value: p,
    label: s,
    options: u,
    inputProps: c,
    placeholder: m,
    supportText: f,
    supportTextProps: d,
    oneLineOption: h = !1,
    onClose: r,
    onSearch: e,
    ...g
  } = a, o = S(), [y, n] = b(!1), T = L(h), {
    isMobile: v
  } = E(), w = C((l, x) => {
    if (o.current && clearTimeout(o.current), l.length > 2) {
      const P = l.toLowerCase().replace(/^\s+|\s+$/g, "");
      o.current = setTimeout(async () => {
        try {
          n(!0), await (e == null ? void 0 : e(P, x));
        } finally {
          n(!1);
        }
      }, 700);
    }
  }, [e]);
  return /* @__PURE__ */ i.createElement(O, { itemSize: T, loading: y, inputProps: {
    role: "search",
    supportTextProps: d,
    fullWidth: !0,
    ...c
  }, filterOption: () => !0, status: t ? "danger" : void 0, placeholder: m || s, noOption: /* @__PURE__ */ i.createElement(q, null, /* @__PURE__ */ i.createElement(A, { variant: "paragraph" }, "Digite pelo menos 3 letras")), value: p, label: s, options: u, dropdownAttributes: {
    style: {
      width: 530,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  }, onClose: () => {
    v && (r == null || r());
  }, onSearch: w, supportText: t ? String(t) : f, ...g });
}
z.displayName = "Pesqinc";
export {
  z as default
};
