import styled, { CSSObject } from "@emotion/styled";

export const Root = styled.div(() => ({
    position: "relative" as CSSObject["position"],
    zIndex: 700,
}));

export const Wrapper = styled.div(() => ({
    boxSizing: "border-box" as CSSObject["boxSizing"],
    containerType: "inline-size" as CSSObject["containerType"],
}));

export const Container = styled.div`
    box-sizing: border-box;
    z-index: ${({ theme }) => theme.zIndex.popup};
    background: ${props => props.theme.colors.neutral.white};
    width: 100%;
    margin: 0px auto;
    border-radius: 16px;
    container-type: inline-size;

    padding-top: ${({ theme }) => theme.spacing.size[6]};
    padding-bottom: ${({ theme }) => theme.spacing.size[8]};
    padding-inline: ${({ theme }) => theme.spacing.size[8]};

    @container (max-width: 899px) {
        padding-inline: ${({ theme }) => theme.spacing.size[5]};
        padding-block: ${({ theme }) => theme.spacing.size[5]};
    }
`;
