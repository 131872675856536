import e from "react";
import r from "@cvccorp-components/chui-react-components/components/Spinner";
import { Wrapper as a, Indicator as n } from "./InputLoadingOverlay.styles.js";
function t() {
  return /* @__PURE__ */ e.createElement(a, null, /* @__PURE__ */ e.createElement(n, { className: "loading-indicator" }, /* @__PURE__ */ e.createElement(r, { color: "secondary" })));
}
t.displayName = "InputLoadingOverlay";
export {
  t as default
};
