import { pesqIncService as h } from "@cvccorp-engines/api";
import { getParams as o } from "@cvccorp-engines/components";
const D = async () => {
  const t = o("Location");
  return t ? (await h("HOT", t)).locations.find((s) => s.description === t) ?? void 0 : void 0;
}, f = () => {
  const t = o("CheckIn");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, I = () => {
  const t = o("CheckOut");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, b = () => {
  var a;
  const t = o("Rooms"), n = o("Adults"), c = o("Children"), s = o("ChildAges"), r = [];
  if (!t) return [{
    adult: 2,
    children: 0,
    childrenDateOfBirth: []
  }];
  for (let e = 0; e < Number(t); e++) {
    const i = n == null ? void 0 : n.split(","), d = c == null ? void 0 : c.split(","), l = s ? s.split(";") : [], u = {
      adult: Number((i == null ? void 0 : i[e]) || 0),
      children: Number((d == null ? void 0 : d[e]) || 0),
      childrenDateOfBirth: (a = l == null ? void 0 : l[e]) == null ? void 0 : a.split(";").map((m) => new Date((/* @__PURE__ */ new Date()).setFullYear((/* @__PURE__ */ new Date()).getFullYear() - Number(m))).toISOString().split("T")[0])
    };
    r.push(u);
  }
  return r;
}, C = () => {
  const t = Number(o("Adults") || 0), n = Number(o("Children") || 0), c = o("CheckIn") || "", s = o("CheckOut") || "", r = Number(o("Rooms") || 0), a = decodeURI(o("Location") || ""), e = o("ZoneId") || "";
  return {
    adult: t,
    children: n,
    origDate: c,
    destDate: s,
    room: r,
    destDescription: a,
    destZoneId: e,
    durationUnit: "Milliseconds"
  };
};
export {
  D as getHotelDestinationParam,
  I as getHotelEndDateParam,
  C as getHotelParamsForLogs,
  b as getHotelPaxParam,
  f as getHotelStartDateParam
};
