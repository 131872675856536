import p from "@emotion/styled";
const d = p.div`
    padding: 0px;
    display: flex;

    > fieldset {
        > div {
            height: 56px;
            > input {
                padding: 20px 8px 15px;
            }
        }
    }
`;
export {
  d as Container
};
