import { create as a } from "zustand";
import { getTicketDestinationInitialValue as i, getTicketEndDateInitialValue as o } from "../utils/getTicketInitialValue.js";
const r = {
  destination: !1,
  date: !1
}, d = a((e) => ({
  destination: i(),
  date: o(),
  errors: r,
  setDestination: (t) => e({
    destination: t
  }),
  setDate: (t) => e({
    date: t
  }),
  setErrors: (t) => e({
    errors: t
  }),
  clearDate: () => e({
    date: void 0
  })
}));
export {
  d as useTicketsStore
};
