import i from "@emotion/styled";
const n = i.div`
    padding: 0px;
    > div > div {
        width: unset !important;
    }

    fieldset {
        .chui-input__addon--before--wrapper {
            height: 54px;
            padding-block: 16px;
            padding-inline-start: 8px;
            padding-inline-end: 0;
            display: flex;
            align-items: center;
            > input {
                height: auto;
                padding: 0px;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-inline-end: 4px;
            }
        }
    }
`, p = i.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0px 16px 16px 16px;
`;
export {
  p as Box,
  n as Container
};
