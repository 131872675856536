import m, { useState as B, useEffect as M, useCallback as F, useMemo as _ } from "react";
import j from "@cvccorp-components/chui-react-components/components/Autocomplete";
import G from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { getAffreightmentLocations as D } from "@cvccorp-engines/api";
import { getParams as z, useError as C } from "@cvccorp-engines/components";
import { getAffreightmentParamsForLogs as I } from "../../utils/getAffreightmentParams.js";
function O() {
  return O = Object.assign ? Object.assign.bind() : function(i) {
    for (var l = 1; l < arguments.length; l++) {
      var n = arguments[l];
      for (var r in n) ({}).hasOwnProperty.call(n, r) && (i[r] = n[r]);
    }
    return i;
  }, O.apply(null, arguments);
}
function U(i, l) {
  if (i == null) return {};
  var n = {};
  for (var r in i) if ({}.hasOwnProperty.call(i, r)) {
    if (l.includes(r)) continue;
    n[r] = i[r];
  }
  return n;
}
var q = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, p = q.size, V = {
  xsmall: p.xs,
  small: p.sm,
  medium: p.md,
  large: p.lg,
  xlarge: p.xl,
  xxlarge: p.xxl
}, Y = ["size", "className"], R = function(l) {
  var n, r, g = l.size, v = g === void 0 ? "medium" : g, w = l.className, T = w === void 0 ? "" : w, c = U(l, Y), x = c, h = ["chui-icon", T].join(" "), b = x.color || "#000014", y = O({}, c, {
    color: void 0
  }), d = p[v] || V[v];
  return /* @__PURE__ */ m.createElement("svg", O({
    xmlns: "http://www.w3.org/2000/svg",
    fill: b,
    viewBox: "0 0 24 24",
    width: (n = c.width) != null ? n : d,
    height: (r = c.height) != null ? r : d,
    className: h
  }, y), /* @__PURE__ */ m.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ m.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
R.displayName = "OutlinePinMap";
function te(i) {
  const {
    error: l,
    value: n,
    supportText: r,
    supportTextProps: g,
    inputProps: v,
    placeholder: w,
    label: T,
    originId: c,
    type: x,
    onSelect: h
  } = i, [b, y] = B([]), d = x === "origin", E = x === "destination";
  function S(e) {
    if (!(e != null && e.city)) return "";
    const {
      city: {
        name: t,
        state: {
          shortName: o,
          country: {
            name: s
          }
        }
      }
    } = e;
    return t && o && s ? t + " - " + o + ", " + s : "";
  }
  function P(e) {
    var t, o, s, a, f, u;
    return (t = e == null ? void 0 : e.city) != null && t.name ? ((s = (o = e == null ? void 0 : e.city) == null ? void 0 : o.state) == null ? void 0 : s.name) + ", " + ((u = (f = (a = e == null ? void 0 : e.city) == null ? void 0 : a.state) == null ? void 0 : f.country) == null ? void 0 : u.name) : (e == null ? void 0 : e.name) ?? "";
  }
  M(() => {
    async function e() {
      const t = await D(void 0, {
        isDestination: E,
        isOrigin: d
      });
      if ((t == null ? void 0 : t.length) > 0) {
        const o = t == null ? void 0 : t.map((s) => ({
          label: P(s),
          value: s
        }));
        y(o);
      }
    }
    e();
  }, []);
  const A = z("packageGroup") ? String(z("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: N
  } = C(), $ = F(async (e) => {
    const t = I(), o = {
      operation: "location",
      productType: "charter",
      packageGroup: A,
      ...t
    }, s = (/* @__PURE__ */ new Date()).getTime();
    try {
      const a = await D(e, {
        isDestination: E,
        isOrigin: d,
        origin: c
      });
      if ((a == null ? void 0 : a.length) > 0) {
        const f = a == null ? void 0 : a.map((u) => ({
          label: P(u),
          value: u
        }));
        y(f);
      } else {
        const u = (/* @__PURE__ */ new Date()).getTime() - s;
        return Object.assign(o, {
          duration: u,
          _message: `EMPTYRESULT ${e}`
        }), N(o), [];
      }
    } catch {
      const u = (/* @__PURE__ */ new Date()).getTime() - s;
      return Object.assign(o, {
        duration: u,
        _message: `ERROR ${e}`
      }), N(o), [];
    }
  }, [E, d, c, A, N]), W = _(() => {
    const e = b;
    return n && !e.some((t) => t.value.id === n.id) && e.push({
      value: n,
      label: P(n)
    }), e;
  }, [b, n]);
  M(() => {
    c && $();
  }, [c]);
  const {
    isMobile: L
  } = G(), k = _(() => L ? {
    inputProps: {
      supportTextProps: g,
      fullWidth: !0,
      ...v
    },
    filterOption: () => !0,
    dropdownAttributes: {
      style: {
        width: 530,
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    renderOption: ({
      option: e
    }) => {
      var t;
      return /* @__PURE__ */ m.createElement(j.Option, { fullWidth: !0, label: (t = e.value) == null ? void 0 : t.name, supportText: S(e.value), onSelect: () => h(e.value), addonBefore: /* @__PURE__ */ m.createElement(R, null), style: {
        display: "flex"
      } });
    }
  } : {
    supportTextProps: g,
    renderOption: ({
      option: e
    }) => {
      var t;
      return /* @__PURE__ */ m.createElement(j.Option, { fullWidth: !0, label: (t = e.value) == null ? void 0 : t.name, supportText: S(e.value), onSelect: () => h(e.value), addonBefore: /* @__PURE__ */ m.createElement(R, null) });
    },
    inputProps: v
  }, [L]);
  return {
    label: T,
    placeholder: w,
    supportText: r,
    onSelect: (e) => {
      h(e);
    },
    options: W,
    value: n,
    error: l,
    ...k,
    onSearch: $
  };
}
export {
  te as default
};
