import y, { createContext as g, useCallback as u, useMemo as C } from "react";
import { useIsomorphicLayoutEffect as A } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as L, LocalStorageKeys as T, useQueryParam as B, EngineQueryParams as O, redirectToSearch as Q } from "@cvccorp-engines/components";
import { d as _ } from "../../dayjs-CKf7tiKR.js";
import { useResortsStore as w } from "../../store/ResortsStore.js";
import H from "../../utils/formatUrlToSearch.js";
import U from "../../utils/validateChildrenPaxDate.js";
const b = g(void 0);
function j(x) {
  var S, f;
  const {
    setErrors: c,
    setErrorsPax: d,
    setDestination: E,
    setCalendarDates: P,
    setPax: R,
    ...e
  } = w(), [l, m] = L(T.LAST_SEARCH_RESORTS, void 0), h = B(O.SHOW_RESORTS, void 0);
  A(() => {
    if (l && !h) {
      const {
        destination: a,
        calendarDates: t,
        pax: r
      } = l;
      E(a), R(r), _(/* @__PURE__ */ new Date()).isAfter(t.startDate) || P(t);
    }
  }, []);
  const D = u(() => {
    var i, s;
    let a = !1;
    const t = !e.pax, r = !e.destination, o = !((i = e.calendarDates) != null && i.endDate), n = !((s = e.calendarDates) != null && s.startDate);
    return a = r || o || n || t, [a, {
      destination: r,
      pax: t,
      calendarDates: {
        endDate: o,
        startDate: n
      }
    }];
  }, [(S = e.calendarDates) == null ? void 0 : S.endDate, (f = e.calendarDates) == null ? void 0 : f.startDate, e.destination, e.pax]), p = u(() => {
    var s;
    const a = (s = e.calendarDates) == null ? void 0 : s.endDate, [t, r] = D(), [o, n] = U(e.pax, a);
    if (c({
      ...r,
      pax: o
    }), d(n), t || o)
      return;
    const i = H(e).replaceAll("+,+", ", ").replaceAll("+", " ");
    m({
      calendarDates: e.calendarDates,
      destination: e.destination,
      pax: e.pax
    }), Q(i);
  }, [e, c, d, D, m]), v = C(() => ({
    handleSearch: p
  }), [p]);
  return /* @__PURE__ */ y.createElement(b.Provider, { ...x, value: v });
}
j.displayName = "EngineResortsProvider";
export {
  b as EngineResortsContext,
  j as default
};
