import n from "./countPax.js";
function a(r) {
  const {
    description: t
  } = n(r);
  return t;
}
export {
  a as default
};
