import { getCalendarDatesAsDate, SearchButton } from "@cvccorp-engines/components";
import dayjs from "@cvccorp-engines/date";
import { usePackageStore } from "../../shared/Context/PackageStore";
import DefaultLocationForm from "../DefaultLocationForm";
import { useMotorPackageContext } from "../EnginePackageContext";
import * as S from "../styled";

const MAX_CHECKOUT_DAYS_FROM_NOW = 330;
function SingleDestinationForm() {
    const { handleSearch } = useMotorPackageContext();

    const {
        origin,
        errors,
        destination,
        calendarDates,
        reverse,
        setOrigin,
        setOpenPax,
        clearDates,
        setDestination,
        setCalendarDates,
    } = usePackageStore();

    return (
        <S.MotorContainer>
            <DefaultLocationForm
                errors={errors}
                origin={origin}
                destination={destination}
                onDestinationChange={setDestination}
                onOriginChange={setOrigin}
                onOpenPax={() => setOpenPax(true)}
                dates={getCalendarDatesAsDate(calendarDates)}
                onReverse={reverse}
                onDateClear={clearDates}
                onDateChange={dates => {
                    if (!dates) return;

                    const [startDate, endDate] = dates;

                    setCalendarDates({
                        startDate: startDate?.toJSON(),
                        endDate: endDate?.toJSON(),
                    });
                }}
                min={dayjs().add(1, "d").toDate()}
                max={dayjs().add(MAX_CHECKOUT_DAYS_FROM_NOW, "d").toDate()}
            >
                <S.buttonContainer>
                    <SearchButton aria-label="Buscar aéreo mais hotel" onClick={handleSearch} />
                </S.buttonContainer>
            </DefaultLocationForm>
        </S.MotorContainer>
    );
}

export default SingleDestinationForm;
