"use client";
import n, { useEffect as o } from "react";
import { SearchButton as i } from "@cvccorp-engines/components";
import { useEngineTravelInsuranceContext as m } from "../../context/EngineTravelInsuranceContext.js";
import { useTravelInsuranceStore as c } from "../../store/TravelInsuranceStore.js";
import { getTravelInsuranceDestinationParam as l } from "../../utils/getParams.js";
import s from "../LocationAndDate/LocationAndDate.js";
import { EngineContainer as u, ButtonContainer as f } from "./EngineTravelInsuranceForm.styles.js";
const p = () => {
  const {
    handleSearch: a
  } = m(), {
    setDestination: e
  } = c();
  return o(() => {
    async function t() {
      const r = await l();
      r && (e == null || e(r));
    }
    t();
  }, [e]), /* @__PURE__ */ n.createElement(u, null, /* @__PURE__ */ n.createElement(s, null), /* @__PURE__ */ n.createElement(f, null, /* @__PURE__ */ n.createElement(i, { "aria-label": "Buscar seguro viagem", onClick: a })));
};
p.displayName = "EngineTravelInsuranceForm";
export {
  p as default
};
