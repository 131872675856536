import e from "react";
import { DEFAULT_ROOM as p } from "../../constants/defaultValues.js";
import s from "../../utils/formatPaxPackages.js";
import x from "../Dropdown/Dropdown.js";
import g from "./PaxContent/PaxContent.js";
import C, { PaxConsumer as d } from "./PaxProvider/PaxProvider.js";
function k(f) {
  const {
    calendarDates: r,
    value: i = [p],
    error: n,
    open: l,
    onToggle: c,
    onConfirm: a,
    onClear: t,
    infantAge: u = 0
  } = f;
  return /* @__PURE__ */ e.createElement(C, { rooms: i, endDate: r == null ? void 0 : r.endDate, infantAge: u }, /* @__PURE__ */ e.createElement(d, null, ({
    rooms: o,
    onClearRooms: P,
    errors: m
  }) => /* @__PURE__ */ e.createElement(x, { open: l, onToggle: c, error: n, supportText: n ? "Preencha os ocupantes corretamente" : "", onClickOutside: () => {
    m || a(o);
  }, onConfirm: () => {
    m || a(o);
  }, onClear: () => {
    t == null || t(), P();
  }, label: s(o), renderContent: () => /* @__PURE__ */ e.createElement(g, null) })));
}
k.displayName = "PaxPackages";
export {
  k as default
};
