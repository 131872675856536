import r, { useState as M, useMemo as A } from "react";
import S from "@cvccorp-components/chui-react-components/components/Autocomplete";
import D from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { getFlightsLocations as q } from "@cvccorp-engines/api";
import { getParams as y, useError as B, Pesqinc as F } from "@cvccorp-engines/components";
import k from "./utils/getFlightsParams.js";
function x() {
  return x = Object.assign ? Object.assign.bind() : function(a) {
    for (var n = 1; n < arguments.length; n++) {
      var t = arguments[n];
      for (var l in t) ({}).hasOwnProperty.call(t, l) && (a[l] = t[l]);
    }
    return a;
  }, x.apply(null, arguments);
}
function E(a, n) {
  if (a == null) return {};
  var t = {};
  for (var l in a) if ({}.hasOwnProperty.call(a, l)) {
    if (n.includes(l)) continue;
    t[l] = a[l];
  }
  return t;
}
var G = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, d = G.size, N = {
  xsmall: d.xs,
  small: d.sm,
  medium: d.md,
  large: d.lg,
  xlarge: d.xl,
  xxlarge: d.xxl
}, I = ["size", "className"], z = function(n) {
  var t, l, p = n.size, h = p === void 0 ? "medium" : p, i = n.className, s = i === void 0 ? "" : i, o = E(n, I), f = o, b = ["chui-icon", s].join(" "), T = f.color || "#000014", O = x({}, o, {
    color: void 0
  }), c = d[h] || N[h];
  return /* @__PURE__ */ r.createElement("svg", x({
    xmlns: "http://www.w3.org/2000/svg",
    fill: T,
    viewBox: "0 0 24 24",
    width: (t = o.width) != null ? t : c,
    height: (l = o.height) != null ? l : c,
    className: b
  }, O), /* @__PURE__ */ r.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m9.924 7.938 1.003 1.775c.232.4-.05.934-.566.934H7.776c-.401 0-.774-.132-1.075-.397L5.421 9.14C4.81 8.597 3.99 8.3 3.146 8.3h-.143a.03.03 0 0 0-.015.004.1.1 0 0 0-.016.013.1.1 0 0 0-.008.019l.001.014v.001l1.342 3.898c.222.653.867 1.108 1.6 1.108h2.925q.06-.016.124-.016h10.677c.772 0 1.402-.605 1.402-1.347 0-.74-.63-1.347-1.402-1.347h-3.431a.67.67 0 0 1-.445-.174L11.58 6.7h-.001a1.05 1.05 0 0 0-.723-.273c-.8 0-1.317.822-.932 1.51m-.107 6.344H5.91c-1.15 0-2.165-.71-2.517-1.745l-1.34-3.895c-.216-.623.273-1.27.952-1.27h.143c1.072 0 2.132.377 2.927 1.089l1.279 1.105c.114.102.25.154.423.154h2.057l-.757-1.34v-.002c-.732-1.306.25-2.877 1.78-2.877.52 0 1.005.181 1.384.525l4.093 3.694h3.302c1.303 0 2.365 1.02 2.365 2.273s-1.062 2.274-2.366 2.274h-3.303l-4.106 3.709c-.38.345-.884.525-1.385.525-1.53 0-2.512-1.571-1.78-2.877v-.001zm1.094.006-1.003 1.776c-.385.688.132 1.51.932 1.51.265 0 .529-.096.723-.272V17.3l3.346-3.021-3.988-.009zm5.521-4.479-.008-.007z",
    clipRule: "evenodd"
  }));
};
z.displayName = "OutlinePlaneRight";
var W = ["size", "className"], _ = function(n) {
  var t, l, p = n.size, h = p === void 0 ? "medium" : p, i = n.className, s = i === void 0 ? "" : i, o = E(n, W), f = o, b = ["chui-icon", s].join(" "), T = f.color || "#000014", O = x({}, o, {
    color: void 0
  }), c = d[h] || N[h];
  return /* @__PURE__ */ r.createElement("svg", x({
    xmlns: "http://www.w3.org/2000/svg",
    fill: T,
    viewBox: "0 0 24 24",
    width: (t = o.width) != null ? t : c,
    height: (l = o.height) != null ? l : c,
    className: b
  }, O), /* @__PURE__ */ r.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ r.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
_.displayName = "OutlinePinMap";
const C = (a) => {
  const {
    error: n,
    value: t,
    supportTextProps: l,
    placeholder: p,
    label: h = "Destino",
    onSelect: i,
    onClear: s
  } = a, {
    isMobile: o
  } = D(), [f, b] = M([]);
  function T(e) {
    if (!e) return "";
    const m = e.description, u = [e.city, e.state && [e.state, e.city].join(", ")].filter((v) => !!v).join(" - ");
    return e.type === "AIRPORT" ? m : u;
  }
  const O = y("packageGroup") ? String(y("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: c
  } = B(), j = async (e) => {
    var P, $;
    const m = k(), u = {
      operation: "location",
      productType: "flights",
      packageGroup: O,
      ...m
    }, v = (/* @__PURE__ */ new Date()).getTime();
    try {
      const g = await q(e);
      if (((P = g == null ? void 0 : g.locations) == null ? void 0 : P.length) > 0) {
        const R = ($ = g == null ? void 0 : g.locations) == null ? void 0 : $.map((w) => ({
          label: w.description,
          value: w
        }));
        b(R);
      } else {
        const w = (/* @__PURE__ */ new Date()).getTime() - v;
        return Object.assign(u, {
          duration: w,
          _message: `EMPTYRESULT ${e}`
        }), c(u), [];
      }
    } catch {
      const w = (/* @__PURE__ */ new Date()).getTime() - v;
      return Object.assign(u, {
        duration: w,
        _message: `ERROR ${e}`
      }), c(u), [];
    }
  }, L = A(() => {
    const e = f;
    return t && e.push({
      value: t,
      label: t.description
    }), e;
  }, [f, t]);
  return /* @__PURE__ */ r.createElement(F, { label: h, placeholder: p, supportTextProps: l, onSearch: j, onSelect: function(m) {
    if (!m) {
      s ? s == null || s() : i == null || i(t);
      return;
    }
    i(m);
  }, options: L, error: n, value: t, renderOption: ({
    option: e
  }) => {
    var v;
    const {
      type: m
    } = e.value, u = {
      AIRPORT: /* @__PURE__ */ r.createElement(z, null),
      CITY: /* @__PURE__ */ r.createElement(_, null)
    };
    return /* @__PURE__ */ r.createElement(S.Option, { fullWidth: !0, supportText: T(e.value), label: [(v = e.value) == null ? void 0 : v.city, e.value.state].filter((P) => P).join(" - "), onSelect: () => i(e.value), addonBefore: u[m] });
  }, itemSize: o ? 80 : 60 });
};
C.displayName = "PesqincFight";
export {
  C as P,
  E as _,
  x as a,
  d as e,
  N as l
};
