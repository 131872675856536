import { Grid as a } from "@cvccorp-components/chui-react-components";
import i from "@emotion/styled";
const n = i(a)`
    gap: 32px;

    @media (max-width: 600px) {
        gap: 12px;
    }
`, e = i.div`
    padding: 16px 0;
`, t = i.div`
    display: flex;
    gap: 8px;
    align-items: center;

    @media (max-width: 600px) {
        display: none;
    }
`;
export {
  e as EngineContainer,
  n as Grid,
  t as TravelClassContainer
};
