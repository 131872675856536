import n from "@emotion/styled";
const e = n.form`
    display: grid;
    width: 100%;
    container-type: inline-size;
    grid-template-columns: 1.8fr 300px 300px 120px;
    column-gap: ${({
  theme: i
}) => i.spacing.size[5]};

    @container (min-width: 1366px) {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 120px;
        column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
    }

    @container (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: ${({
  theme: i
}) => i.spacing.size[5]};
    }
`, r = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;
    @container (max-width: 1024px) {
        margin: 0;
    }
`;
export {
  r as ButtonContainer,
  e as MotorContainer
};
