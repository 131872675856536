import { useContext as o } from "react";
import { EngineCarsContext as t } from "./EngineCarsContext.js";
function a() {
  const e = o(t);
  if (!e) throw new Error("Chamada do hook useCarsContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorPackageContext");
  return e;
}
export {
  a as default
};
