"use client";
import e from "react";
import a from "@cvccorp-components/chui-react-components/components/Select";
import { useAutoCompleteItemSize as p } from "@cvccorp-engines/components";
import { Container as u } from "./SelectedHour.styled.js";
const s = (t) => {
  const {
    value: o,
    placeholder: r,
    options: l,
    inputProps: m,
    label: n,
    onSelect: c
  } = t, i = p();
  return /* @__PURE__ */ e.createElement(u, null, /* @__PURE__ */ e.createElement(a, { itemSize: i, label: n, options: l, placeholder: r, value: o, onSelect: c, inputProps: m }));
};
s.displayName = "SelectHour";
export {
  s as default
};
