import p, { useState as O, useMemo as h } from "react";
import R from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { getTicketsLocations as $ } from "@cvccorp-engines/api";
import { useError as v, Pesqinc as P } from "@cvccorp-engines/components";
import { getTicketsParamsForLogs as S } from "../../utils/getTicketInitialValue.js";
import { LocationContainer as k } from "./styled.js";
const M = ({
  error: i,
  value: r,
  onSelect: l,
  supportText: d,
  supportTextProps: f,
  onClear: n,
  label: E = "Destino"
}) => {
  const [u, T] = O(() => []);
  function a(e) {
    return e ? `${e.provider !== null ? `${e.provider} - ` : ""}${e != null && e.cityName ? e.cityName : ""} - ${e != null && e.stateName ? e.stateName : ""}` : "";
  }
  const {
    throwError: m
  } = v(), b = async (e) => {
    var y, N;
    const s = S(), c = {
      channel: "WEB",
      friendlyMessage: "EMPTYRESULT",
      _message: `EMPTYRESULT - ${e}`,
      operation: "location",
      productType: "services",
      packageGroup: "STANDALONE",
      provider: "LA",
      tags: [],
      _level_name: "info",
      ...s
    };
    try {
      const o = await $(e);
      if (((y = o == null ? void 0 : o.cities) == null ? void 0 : y.length) > 0) {
        const L = (N = o == null ? void 0 : o.cities) == null ? void 0 : N.map((t) => ({
          label: a({
            cityName: t.cityName,
            stateName: t.stateName,
            provider: t.name,
            cityId: t.cityId,
            countryName: t.countryName,
            description: t.description
          }),
          value: {
            cityName: t.cityName,
            stateName: t.stateName,
            provider: t.name,
            cityId: t.cityId,
            countryName: t.countryName,
            description: t.description
          }
        }));
        T(L);
      } else
        return Object.assign(c, {
          _message: `EMPTYRESULT ${e}`
        }), m(c), [];
    } catch {
      return Object.assign(c, {
        _message: `ERROR ${e}`
      }), m(c), [];
    }
  }, g = h(() => {
    const e = u;
    return r && e.push({
      value: r,
      label: a(r)
    }), e;
  }, [u, r]);
  return /* @__PURE__ */ p.createElement(k, null, /* @__PURE__ */ p.createElement(P, { label: i || E, supportText: d, supportTextProps: f, error: i, onSearch: b, onSelect: function(s) {
    if (!s) return n ? n == null ? void 0 : n() : l(r);
    l(s);
  }, options: g, value: r, renderOption: ({
    option: e
  }) => /* @__PURE__ */ p.createElement(R.Option, { fullWidth: !0, supportText: a(e.value), label: a(e.value), onSelect: () => l(e.value) }) }));
};
M.displayName = "SingleTickets";
export {
  M as default
};
