import { encodeSearchURL, formatDateToParam, getBaseHostname } from "@cvccorp-engines/components";
import { calcChildAges, countPaxRoom } from "@cvccorp-engines/pax";
import { PackageStoreState } from "../../shared/Context/PackageStore";

function formatUrlToSearch(params: PackageStoreState): string {
    const url = new URL(`${getBaseHostname()}/pacotes-turisticos/search`);

    const { adultByRoom, childrenByRoom } = countPaxRoom(params.pax);

    url.searchParams.append("IataOri", params.origin?.iata || "");
    url.searchParams.append("IataDes", params.destination?.iata || "");
    url.searchParams.append("CheckIn", formatDateToParam(params.calendarDates?.startDate) || "");
    url.searchParams.append("CheckOut", formatDateToParam(params.calendarDates?.endDate) || "");
    url.searchParams.append("Rooms", String(params.pax?.length));
    url.searchParams.append("Adults", adultByRoom || "");
    url.searchParams.append("Children", childrenByRoom || "");
    url.searchParams.append("ChildAges", calcChildAges(params.pax));
    url.searchParams.append("ZoneIdOrigin", String(params.origin?.zoneId) || "");
    url.searchParams.append("ZoneId", String(params.destination?.zoneId) || "");
    url.searchParams.append("OriginDesc", params.origin?.description || "");
    url.searchParams.append("DestinationDesc", params.destination?.description || "");
    url.searchParams.append("DestinationLat", String(params.destination?.latitude) || "");
    url.searchParams.append("DestinationLon", String(params.destination?.longitude) || "");
    url.searchParams.append("Classes", "eco");
    url.searchParams.append("Direct", "false");
    url.searchParams.append("CityOrigin", params.origin?.city || "");
    url.searchParams.append("City", params.destination?.city || "");
    url.searchParams.append("StateOrigin", params.origin?.state || "");
    url.searchParams.append("State", params.destination?.state || "");
    url.searchParams.append("CountryOrigin", params.origin?.country || "");
    url.searchParams.append("Country", params.destination?.country || "");

    return encodeSearchURL(url);
}

export default formatUrlToSearch;
