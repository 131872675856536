import c from "./removeAccentuation.js";
function m(a) {
  let e = [a.pathname].join(""), o = "?";
  return a.searchParams.forEach((t, r) => {
    e = e + o + [r, decodeURI(c(t))].join("="), o = "&";
  }), e;
}
export {
  m as default
};
