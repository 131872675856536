"use client";
import e from "react";
import { DEFAULT_TRAVERS as p } from "../../constants/defaultValues.js";
import x from "../../utils/formatPaxFlights.js";
import P from "../Dropdown/Dropdown.js";
import g from "./PaxContent/PaxContent.js";
import C, { PaxConsumer as E } from "./PaxProvider/PaxProvider.js";
function h(l) {
  const {
    open: m,
    value: s = p,
    classe: i,
    error: n,
    onToggle: c,
    onConfirm: r,
    onClear: f
  } = l;
  return /* @__PURE__ */ e.createElement(C, { classe: i, travelers: s }, /* @__PURE__ */ e.createElement(E, null, ({
    travelers: t,
    classe: o,
    errors: a,
    onClearTraveler: u
  }) => /* @__PURE__ */ e.createElement(P, { error: n, supportText: n ? "Preencha quantos passageiros e a classe" : "", onToggle: c, onClear: () => {
    f(), u();
  }, onClickOutside: () => {
    a || r({
      ...t,
      classe: o
    });
  }, onConfirm: () => {
    a || r({
      ...t,
      classe: o
    });
  }, open: m, label: x(t, o), renderContent: () => /* @__PURE__ */ e.createElement(g, null) })));
}
h.displayName = "PaxFlights";
export {
  h as default
};
