import { OptionsBar } from "@cvccorp-engines/components";
import { EngineHotel } from "@cvccorp-engines/hotel";
import { EngineResort } from "@cvccorp-engines/resort";
import { useHotelsResortsStore } from "../shared/Context/HotelsResortsStore";

const EngineHotelsResorts = (): JSX.Element => {
    return <MotorContent />;
};

const choices = [
    {
        value: "hotels",
        label: "Todos",
    },
    {
        value: "resorts",
        label: "Resorts",
    },
];

interface FormProps {
    choice: string;
}

const forms = {
    hotels: EngineHotel,
    resorts: EngineResort,
} as Record<string, any>;

function Form({ choice }: FormProps) {
    const Form = forms[choice];

    return <Form key={choice} />;
}

const MotorContent = () => {
    const { choice, setChoice } = useHotelsResortsStore();

    return (
        <>
            <OptionsBar options={choices} value={choice} onChange={setChoice} />
            <Form choice={choice} />
        </>
    );
};

export default EngineHotelsResorts;
