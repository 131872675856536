import { _ as w, a as h, e as p, l as f } from "./Icon-o1sXhey4.js";
import n from "react";
var g = ["size", "className"], z = function(a) {
  var i, l, o = a.size, t = o === void 0 ? "medium" : o, r = a.className, c = r === void 0 ? "" : r, e = w(a, g), m = e, d = ["chui-icon", c].join(" "), v = m.color || "#000014", u = h({}, e, {
    color: void 0
  }), s = p[t] || f[t];
  return /* @__PURE__ */ n.createElement("svg", h({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : s,
    height: (l = e.height) != null ? l : s,
    className: d
  }, u), /* @__PURE__ */ n.createElement("path", {
    fill: void 0,
    d: "M21.05 7.883a.5.5 0 0 0-.001-.707l-2.46-2.46a.5.5 0 1 0-.707.707l1.606 1.607h-9.253a.5.5 0 1 0 0 1h9.256l-1.61 1.617a.5.5 0 0 0 .708.706zM13.77 16.97a.5.5 0 1 0 0-1H4.517l1.607-1.607a.5.5 0 1 0-.708-.707l-2.46 2.46a.5.5 0 0 0 0 .707l2.46 2.46a.5.5 0 1 0 .708-.707L4.517 16.97z"
  }));
};
z.displayName = "OutlineDataTransferHorizontal";
export {
  z as h
};
