import o from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { MOBILE_OPTION_HEIGHT as i, ONE_LINE_OPTION_HEIGHT as O, DEFAULT_OPTION_HEIGHT as s } from "../../constants/optionHeight.js";
function p(e = !1) {
  const {
    isMobile: t
  } = o();
  return t ? i : e ? O : s;
}
export {
  p as default
};
