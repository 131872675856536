import t from "@emotion/styled";
const n = t.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 16
}));
export {
  n as Container
};
