import { useState, useMemo } from "react";
import useIsomorphicLayoutEffect from "@cvccorp-components/chui-react-components/hooks/useIsomorphicLayoutEffect";
import useWindow from "@cvccorp-components/chui-react-components/hooks/useWindow";
import {
    PRODUCTS_LINK_CVC,
    PRODUCTS_LINK_SUBMARINO,
    type ProductConfiguration,
    productsCvc,
    productsSubmarino,
} from "@cvccorp-engines/components";
import { useEnginesConfigContext, type EnginesConfigNavigationType } from "@cvccorp-engines/provider";
import { useTheme } from "@emotion/react";
import type { ChipProductTypes } from "../components";
import type { EngineWrapperProps } from "./EngineWrapper.types";

export const getDefaultProductByTheme = (theme: string): ChipProductTypes => {
    if (theme === "submarino") return "air";
    return "packages";
};

export default function useEngineWrapper(props: Pick<EngineWrapperProps, "type">) {
    const { type } = props;
    const _window = useWindow();
    const { name: themeName } = useTheme();
    const [pathname, setPathname] = useState("");
    const { navigationType } = useEnginesConfigContext();
    const [currentProduct, setCurrentProduct] = useState<ChipProductTypes>(type || getDefaultProductByTheme(themeName));

    const productsTheme = useMemo<{
        link: Record<string, string>;
        products: ProductConfiguration[];
    }>(() => {
        if (themeName === "submarino")
            return {
                link: PRODUCTS_LINK_SUBMARINO,
                products: productsSubmarino,
            };
        return { link: PRODUCTS_LINK_CVC, products: productsCvc };
    }, [themeName]);

    function getCurrentProduct(
        product: ChipProductTypes,
        as: EnginesConfigNavigationType,
    ): { product: ChipProductTypes; checked: boolean } {
        const defaultProduct = product || getDefaultProductByTheme(themeName);
        let checked = false;
        let current = defaultProduct;

        if (as === "link") {
            const context = new URLSearchParams(_window.location.search).get("context") as ChipProductTypes;
            const { pathname } = _window.location;
            setPathname(pathname);
            const currentLocation = pathname.startsWith("/p/") ? pathname.replace(/^\/p/, "") : pathname;
            const location = productsTheme.products.find(currentProduct => {
                return currentLocation === currentProduct.link;
            });

            const isResorts = context === "resorts" && location?.type === "hotel";

            if (isResorts) {
                return {
                    checked: true,
                    product: "resorts",
                };
            }

            if (["cru", "disney"].includes(location?.type ?? "")) {
                return { checked: true, product: defaultProduct };
            }
            current = location?.type ?? defaultProduct;
            checked = Boolean(location);
        }

        return { product: current, checked };
    }

    const setProduct = (product: ChipProductTypes) => {
        setCurrentProduct(product);
    };

    useIsomorphicLayoutEffect(() => {
        const pathname = _window.location.pathname;
        setPathname(pathname);
    }, [_window]);

    useIsomorphicLayoutEffect(() => {
        const { product: current } = getCurrentProduct(type, navigationType);
        setCurrentProduct(current);
    }, [navigationType, type]);

    return { product: currentProduct, setProduct, pathname, productsTheme };
}
