import { Fragment, useMemo } from "react";
import { InputGroup, InputDatePickerRange, setDataLayer, type CalendarDates } from "@cvccorp-engines/components";
import dayjs from "@cvccorp-engines/date";
import { PaxPackages } from "@cvccorp-engines/pax";
import { usePackageStore } from "../../shared/Context/PackageStore";
import SinglePackage from "../../shared/PesqInc/SinglePackage";
import { LocationAndDateWrapper } from "../styled";
import { LocationAndDateProps } from "./types";

function LocationAndDate({
    max,
    min,
    dates = [],
    errors,
    origin,
    isMult = false,
    destination,
    onReverse,
    onDateClear,
    onDateChange,
    onOriginChange,
    onDestinationChange,
}: LocationAndDateProps) {
    const { openPax, pax, setOpenPax, clearPax, setPax } = usePackageStore();

    const formatedPaxDates = useMemo<CalendarDates>(() => {
        return {
            endDate: dates[1]?.toJSON(),
            startDate: dates[0]?.toJSON(),
        };
    }, [dates]);

    const handleDateError = () => {
        const errorsDate = {
            startDate: "",
            endDate: "",
        };

        if (errors?.calendarDates?.startDate) {
            errorsDate.startDate = "Preencha a data de ida";
        }

        if (errors?.calendarDates?.endDate) {
            errorsDate.endDate = "Preencha a data de volta";
        }

        if (errors?.calendarDates?.startDate && errors?.calendarDates?.endDate) {
            errorsDate.startDate = "Preencha a data de ida";
            errorsDate.endDate = "Preencha a data de volta";
        }

        if (!errors?.calendarDates?.startDate && !errors?.calendarDates?.endDate) {
            errorsDate.startDate = "";
            errorsDate.endDate = "";
        }

        return errorsDate;
    };

    const handleDestinationError = () => {
        return errors?.destination ? "Preencha o campo de destino" : "";
    };

    const handleOriginError = () => {
        return errors?.origin ? "Preencha o campo de origem" : "";
    };

    const handleReverse = () => {
        setDataLayer({
            event: "pacote_home_engajamento",
            category: "pacote_busca",
            action: "click-swap",
            label: `${origin?.iata} - ${destination?.iata}`,
        });
        onReverse?.(destination, origin);
    };

    const handleDisableDates = (date: string) => {
        if (dates?.length === 2) return false;
        if (dates[0]) {
            const selectedDate = dayjs(dates[0]);
            const currentDate = dayjs(date);
            const maxAllowedDate = selectedDate.add(29, "day");
            const minAllowedDate = selectedDate.subtract(29, "day");

            return currentDate.isAfter(maxAllowedDate) || currentDate.isBefore(minAllowedDate);
        }
        return false;
    };

    const dateError = handleDateError();
    const originError = handleOriginError();
    const destinationError = handleDestinationError();

    return (
        <Fragment>
            <InputGroup
                fullWidth
                onReverse={handleReverse}
                status={Boolean(destinationError || originError) ? "danger" : undefined}
                hideIcon
                hideClearIcon={false}
            >
                <SinglePackage
                    supportText={originError}
                    error={originError}
                    label="Origem"
                    placeholder="De onde você vai sair?"
                    value={origin}
                    onSelect={origin => {
                        onOriginChange?.(origin);
                    }}
                    onClear={() => {
                        onOriginChange?.(undefined);
                    }}
                />
                <SinglePackage
                    supportText={destinationError}
                    error={destinationError}
                    value={destination}
                    placeholder="Para onde vai?"
                    onSelect={destination => {
                        onDestinationChange?.(destination);
                    }}
                    onClear={() => {
                        onDestinationChange?.(undefined);
                    }}
                />
            </InputGroup>

            <LocationAndDateWrapper isMult={isMult}>
                <InputGroup fullWidth status={Boolean(dateError.startDate || dateError.endDate) ? "danger" : undefined}>
                    <InputDatePickerRange
                        value={dates}
                        max={max}
                        min={min}
                        showRangeCount
                        error={dateError}
                        onChange={dates => {
                            onDateChange?.(dates);
                        }}
                        onClear={onDateClear}
                        disableDates={handleDisableDates}
                    />
                </InputGroup>

                <PaxPackages
                    infantAge={1}
                    open={openPax}
                    error={errors.pax}
                    calendarDates={formatedPaxDates}
                    onConfirm={pax => {
                        setPax(pax);
                        setOpenPax(false);
                    }}
                    onClear={clearPax}
                    onToggle={setOpenPax}
                    value={pax}
                />
            </LocationAndDateWrapper>
        </Fragment>
    );
}
export default LocationAndDate;
