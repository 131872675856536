import a, { Fragment as u } from "react";
import d from "@cvccorp-components/chui-react-components/components/Counter";
import D from "@cvccorp-components/chui-react-components/components/Divider";
import { DEFAULT_ROOM as B } from "../../../constants/defaultValues.js";
import O from "../../Label/Label.js";
import { usePax as g } from "../PaxProvider/PaxProvider.js";
import v from "../PaxRooms/PaxRooms.js";
function n(r) {
  return /* @__PURE__ */ a.createElement(D, { color: "gray-light", size: "sm", variant: "line", vertical: !1, ...r });
}
n.displayName = "BaseDivider";
const p = () => {
  const {
    onChangeRooms: r,
    rooms: e
  } = g();
  function c() {
    r([...e, B]);
  }
  function h() {
    r([...e].slice(0, -1));
  }
  function o(i, t) {
    r([...e.slice(0, t), {
      ...e[t],
      [i]: e[t][i] + 1,
      childrenDateOfBirth: i === "children" ? [...e[t].childrenDateOfBirth, (/* @__PURE__ */ new Date()).toString()] : e[t].childrenDateOfBirth
    }, ...e.slice(t + 1)]);
  }
  function m(i, t) {
    r([...e.slice(0, t), {
      ...e[t],
      [i]: e[t][i] - 1,
      childrenDateOfBirth: i === "children" ? [...e[t].childrenDateOfBirth].slice(0, -1) : e[t].childrenDateOfBirth
    }, ...e.slice(t + 1)]);
  }
  function s(i, t, f) {
    const l = [...e];
    l[t].childrenDateOfBirth[f] = i, r(l);
  }
  return /* @__PURE__ */ a.createElement("div", null, /* @__PURE__ */ a.createElement(O, { text: "Quartos", helperText: "Adicione os quartos" }, /* @__PURE__ */ a.createElement(d, { min: 1, max: 8, onChange: (i) => {
    i > e.length ? c() : h();
  }, value: e.length })), /* @__PURE__ */ a.createElement(n, null), e.map((i, t) => /* @__PURE__ */ a.createElement(u, { key: t }, /* @__PURE__ */ a.createElement(
    v,
    {
      roomIndex: t,
      room: i,
      onAdd: o,
      onRemove: m,
      addChildDateOfBirth: s
    }
  ), t < e.length - 1 && /* @__PURE__ */ a.createElement(n, null))));
};
p.displayName = "PaxContent";
export {
  p as default
};
