"use client";
import r, { useState as g, useRef as c, useCallback as D, useMemo as p, useEffect as C } from "react";
import w from "@cvccorp-components/chui-react-components/components/Button";
import { m as W, a as x } from "../../OutlineArrowRight-fe4YoXL9.js";
import { useTheme as F } from "@emotion/react";
import V from "./products/cvc.js";
import X from "./products/submarino.js";
import { RelativeContainer as $, Nav as z, ScrollButtonWrapper as I, HIDDEN_CLASSNAME as N, ProductWrapper as U, LinkWrapper as j } from "./styled.js";
const H = 120, K = -120, q = ["package-complete", "packages"];
function re(R) {
  const {
    hide: l,
    productType: i,
    ...f
  } = R, [L, y] = g(!1), [M, O] = g(!1), {
    name: a
  } = F(), h = c(/* @__PURE__ */ new Map()), E = c(!1), T = c(null), b = c(null), k = c(null), u = c(null), d = D((t, ...e) => {
    if (!(e != null && e.length)) return;
    const n = new IntersectionObserver((o) => {
      o.forEach((s) => {
        t(s.isIntersecting, s.target);
      });
    }, {
      root: document.body,
      threshold: 1
    });
    return e.forEach((o) => o && n.observe(o)), n;
  }, []), v = (t) => {
    var e, n, o;
    (o = (e = u.current) == null ? void 0 : e.scroll) == null || o.call(e, {
      left: ((n = u.current) == null ? void 0 : n.scrollLeft) + t,
      behavior: "smooth"
    });
  }, _ = (t) => {
    var e, n;
    (n = (e = h.current.get(t)) == null ? void 0 : e.scrollIntoView) == null || n.call(e, {
      block: "center",
      inline: "center",
      behavior: "smooth"
    });
  }, m = p(() => (a === "submarino" ? X : V).filter((e) => l ? !(l != null && l.includes(e.type)) : !0), [l, a]);
  C(() => {
    const t = d((e, n) => {
      n.classList.contains("last") ? y(!e) : O(!e);
    }, k.current, b.current);
    return () => {
      t == null || t.disconnect();
    };
  }, [d]), C(() => {
    var t, e;
    E.current || (E.current = !0, (e = (t = T.current) == null ? void 0 : t.scrollIntoView) == null || e.call(t, {
      inline: "center",
      block: "center"
    }));
  }, []);
  const A = p(() => a === "cvc" ? ["cru", "rock-in-rio", "cvc-me-leva", "disney", "app", "partnerships", "ticket-trip", "cvc+", "club"] : [], [a]), B = p(() => {
    const t = m.find(({
      type: e
    }) => i === e && !!e || q.some((n) => n === i));
    return (t == null ? void 0 : t.label) ?? "";
  }, [m, i]);
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement($, null, /* @__PURE__ */ r.createElement(z, { ref: u }, /* @__PURE__ */ r.createElement(I, { left: 0, show: M, onClick: () => v(K), role: "button" }, /* @__PURE__ */ r.createElement(w, { icon: /* @__PURE__ */ r.createElement(W, null), size: "sm", color: "white", shadow: !0 })), /* @__PURE__ */ r.createElement("span", { className: `${N} first`, ref: k }), m.map((t) => {
    const e = t.type, n = A.includes(e) || f.as === "link", o = B === t.label, s = t.label + e, S = /* @__PURE__ */ r.createElement(U, null, t.icon, t.label);
    return /* @__PURE__ */ r.createElement(j, { key: t.label, ref: (P) => (h.current.set(e, P), P), onClick: () => {
      _(e);
    }, underline: o }, n ? /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement("a", { key: s, href: t.link, target: t.target, "aria-current": o ? "page" : void 0 }, S)) : /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement("button", { key: s, onClick: () => {
      f.onClick(e);
    }, type: "button" }, S)));
  }), /* @__PURE__ */ r.createElement("span", { className: `${N} last`, ref: b }), /* @__PURE__ */ r.createElement(I, { show: L, onClick: () => v(H), role: "button", right: 0 }, /* @__PURE__ */ r.createElement(w, { icon: /* @__PURE__ */ r.createElement(x, null), size: "sm", color: "white", shadow: !0 })))));
}
export {
  re as default
};
