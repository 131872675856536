import e from "@emotion/styled";
const r = e.ul`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0;
    margin: 0;
`, t = e.li`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({
  theme: i
}) => i.colors.support.primary};
`;
export {
  r as Container,
  t as Item
};
