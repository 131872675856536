import e from "react";
import { r as s, m as t, h as o } from "../../../FilledBed-CZJxOTKc.js";
import { r as i, p as a, s as n } from "../../../FilledMoney-Dh2WYsyR.js";
const l = {
  air: "/passagens-aereas",
  hotel: "/hotel",
  packages: "/pacotes-turisticos",
  resort: "https://www.submarinoviagens.com.br/promocoes/especial-resorts",
  destiny: "https://www.submarinoviagens.com.br/destinos",
  exchange: "https://submarinoviagens.meucambio.com.br/?xd_co_f=OTc4OTQ0ZWMtMWMyOS00ZTQ5LTk3MjgtM2ExZDM3NDM4Njhh#/"
}, p = [{
  label: "Passagens",
  type: "air",
  icon: /* @__PURE__ */ e.createElement(s, { size: "md" }),
  link: "/passagens-aereas",
  id: "menu-passagens"
}, {
  label: "Hotéis",
  type: "hotel",
  icon: /* @__PURE__ */ e.createElement(t, { size: "md" }),
  link: "/hotel",
  id: "menu-hoteis"
}, {
  label: "Pacotes",
  type: "packages",
  icon: /* @__PURE__ */ e.createElement(o, { size: "md" }),
  link: "/pacotes-turisticos",
  id: "menu-pacotes"
}, {
  label: "Resorts",
  type: "resorts",
  icon: /* @__PURE__ */ e.createElement(i, { size: "md" }),
  link: "https://www.submarinoviagens.com.br/promocoes/especial-resorts",
  id: "resorts"
}, {
  label: "Destinos",
  type: "destino",
  icon: /* @__PURE__ */ e.createElement(a, { size: "md" }),
  link: "https://www.submarinoviagens.com.br/destinos",
  id: "destino"
}, {
  label: "Meu Câmbio",
  type: "exchange",
  icon: /* @__PURE__ */ e.createElement(n, { size: "md" }),
  link: "https://www.submarinoviagens.com.br/destinos",
  id: "exchange"
}];
export {
  l as PRODUCTS_LINK_SUBMARINO,
  p as default
};
