import { _ as w, a as r, e as p, l as g } from "./Icon-o1sXhey4.js";
import a from "react";
var f = ["size", "className"], N = function(e) {
  var i, t, c = e.size, o = c === void 0 ? "medium" : c, d = e.className, v = d === void 0 ? "" : d, l = w(e, f), n = l, h = ["chui-icon", v].join(" "), m = n.color || "#000014", u = r({}, l, {
    color: void 0
  }), s = p[o] || g[o];
  return /* @__PURE__ */ a.createElement("svg", r({
    xmlns: "http://www.w3.org/2000/svg",
    fill: m,
    viewBox: "0 0 24 24",
    width: (i = l.width) != null ? i : s,
    height: (t = l.height) != null ? t : s,
    className: h
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M11.25 3.36c1.694 0 3.247.602 4.458 1.603l-.744.93a2.75 2.75 0 0 0-.313 2.947l.428.855a.75.75 0 0 0 1.006.336c.684-.342.861-.894 1.975-1.17l1.096-.208a.383.383 0 0 0 .313-.443c-.067-.352-.16-.608-.355-1.083l-.148-.338a8.5 8.5 0 0 0-1.557-2.287l-.301-.301a8.47 8.47 0 0 0-5.858-2.34 8.46 8.46 0 0 0-4.938 1.58l-.141.103A8.49 8.49 0 0 0 2.75 10.36a8.48 8.48 0 0 0 2.71 6.222.7.7 0 0 0 .117.116l.091.073q.309.268.642.507l.03.022.087.06.005-.008a.75.75 0 0 0 .808-1.25l.002-.002-.038-.027-.575-.455a7 7 0 0 1-.663-.667l1.081-.721a4.67 4.67 0 0 0 1.84-2.408l.228-.686c.15-.45.49-.81.93-.986l.309-.124a2.619 2.619 0 0 0 .054-4.84L7.998 4.16a7 7 0 0 1 3.252-.8",
    clipRule: "evenodd"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M20.277 9.719a.7.7 0 0 1 .919.919l-4.75 11.082a.7.7 0 0 1-1.306-.051l-1.157-3.416 1.584-1.53a.75.75 0 1 0-1.042-1.078l-1.585 1.53-3.735-1.396a.7.7 0 0 1-.03-1.299z",
    clipRule: "evenodd"
  }));
};
N.displayName = "FilledTrips";
var _ = ["size", "className"], x = function(e) {
  var i, t, c = e.size, o = c === void 0 ? "medium" : c, d = e.className, v = d === void 0 ? "" : d, l = w(e, _), n = l, h = ["chui-icon", v].join(" "), m = n.color || "#000014", u = r({}, l, {
    color: void 0
  }), s = p[o] || g[o];
  return /* @__PURE__ */ a.createElement("svg", r({
    xmlns: "http://www.w3.org/2000/svg",
    fill: m,
    viewBox: "0 0 24 24",
    width: (i = l.width) != null ? i : s,
    height: (t = l.height) != null ? t : s,
    className: h
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M15.92 4.896c4.073 1.582 5.409 5.499 4.954 8.166-.156.976-.52 1.736-1.093 2.218-.585.49-1.319.635-2.084.508-1.098-.178-1.753-.922-2.119-1.558a4 4 0 0 1-.142-.27 3 3 0 0 1-.674.535c-.308.178-.627.29-.936.356l-1.003 5.925c-.144.888-.99 1.443-1.828 1.304-.885-.142-1.443-.962-1.305-1.826v-.005l1.005-5.93a3.3 3.3 0 0 1-.779-.654 3 3 0 0 1-.462-.722 4 4 0 0 1-.239.222c-.554.48-1.416.968-2.51.79h-.003c-.766-.127-1.414-.502-1.808-1.157-.386-.643-.483-1.482-.314-2.458.455-2.662 2.977-5.926 7.337-6.092l.016-.106c.194-1.102 1.233-1.8 2.337-1.611 1.099.188 1.857 1.205 1.662 2.29zm-2.15.636a7.86 7.86 0 0 0-3.305 4.231c-.588 1.765-.25 2.717.209 3.25.492.57 1.238.795 1.69.872.484.079 1.254.116 1.896-.256.598-.346 1.223-1.134 1.258-3.02.022-1.393-.3-3.256-1.748-5.077",
    clipRule: "evenodd"
  }));
};
x.displayName = "FilledVacation";
var z = ["size", "className"], E = function(e) {
  var i, t, c = e.size, o = c === void 0 ? "medium" : c, d = e.className, v = d === void 0 ? "" : d, l = w(e, z), n = l, h = ["chui-icon", v].join(" "), m = n.color || "#000014", u = r({}, l, {
    color: void 0
  }), s = p[o] || g[o];
  return /* @__PURE__ */ a.createElement("svg", r({
    xmlns: "http://www.w3.org/2000/svg",
    fill: m,
    viewBox: "0 0 24 24",
    width: (i = l.width) != null ? i : s,
    height: (t = l.height) != null ? t : s,
    className: h
  }, u), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M4.252 12a7.75 7.75 0 0 1 13.995-4.59l-.875-.235a.75.75 0 0 0-.388 1.449l2.249.602a1.25 1.25 0 0 0 1.53-.884l.603-2.249a.75.75 0 1 0-1.449-.388l-.285 1.064A9.25 9.25 0 0 0 2.752 12a.75.75 0 0 0 1.5.001"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M12.005 6.249a.75.75 0 0 1 .75.75v.226a3.05 3.05 0 0 1 1.366.598c.576.46.907 1.13.907 1.904a.75.75 0 0 1-1.5 0 .89.89 0 0 0-.344-.733c-.234-.187-.624-.335-1.18-.335-.554 0-.944.148-1.178.336a.89.89 0 0 0-.344.732c0 .48.144.72.363.908.274.235.71.425 1.397.654.677.226 1.377.49 1.899.938.577.494.887 1.163.887 2.047 0 .54-.137 1.005-.39 1.388-.25.377-.584.632-.916.802-.324.166-.663.26-.967.314V17a.75.75 0 1 1-1.5 0v-.224a3.4 3.4 0 0 1-.967-.313 2.4 2.4 0 0 1-.916-.802c-.253-.383-.39-.848-.39-1.388a.75.75 0 0 1 1.5 0c0 .277.067.449.141.56a.9.9 0 0 0 .348.295c.333.17.761.213 1.034.213.272 0 .7-.043 1.034-.213a.9.9 0 0 0 .348-.294c.074-.112.141-.284.141-.561 0-.48-.144-.721-.363-.909-.274-.234-.71-.424-1.397-.653-.678-.226-1.377-.49-1.9-.938-.576-.494-.886-1.163-.886-2.047 0-.773.33-1.443.906-1.903a3.05 3.05 0 0 1 1.367-.6V7a.75.75 0 0 1 .75-.75"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M20.502 11.25a.75.75 0 0 1 .75.75A9.25 9.25 0 0 1 4.37 17.226l-.286 1.069a.75.75 0 0 1-1.45-.388l.603-2.25a1.25 1.25 0 0 1 1.531-.883l2.25.602a.75.75 0 1 1-.389 1.45l-.87-.234A7.75 7.75 0 0 0 19.753 12a.75.75 0 0 1 .75-.75"
  }));
};
E.displayName = "FilledMoney";
export {
  N as p,
  x as r,
  E as s
};
