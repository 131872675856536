function s() {
  return s = Object.assign ? Object.assign.bind() : function(r) {
    for (var n = 1; n < arguments.length; n++) {
      var l = arguments[n];
      for (var e in l) ({}).hasOwnProperty.call(l, e) && (r[e] = l[e]);
    }
    return r;
  }, s.apply(null, arguments);
}
function i(r, n) {
  if (r == null) return {};
  var l = {};
  for (var e in r) if ({}.hasOwnProperty.call(r, e)) {
    if (n.includes(e)) continue;
    l[e] = r[e];
  }
  return l;
}
var t = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, a = t.size, m = {
  xsmall: a.xs,
  small: a.sm,
  medium: a.md,
  large: a.lg,
  xlarge: a.xl,
  xxlarge: a.xxl
};
export {
  i as _,
  s as a,
  a as e,
  m as l
};
