import u from "../../libs/fetcher.js";
import p from "../../utils/QueryString.js";
const f = {
  productType: "AIR,COMBO",
  includeAirProtection: !0
};
function g(n, e) {
  var o, i, a, c, s, m;
  const t = (a = (i = (o = n.city) == null ? void 0 : o.state) == null ? void 0 : i.country) == null ? void 0 : a.shortName, r = (m = (s = (c = e.city) == null ? void 0 : c.state) == null ? void 0 : s.country) == null ? void 0 : m.shortName;
  return t === "BR" && r !== "BR" ? -1 : t !== "BR" && r === "BR" || !t && r ? 1 : t && !r ? -1 : 0;
}
const h = async (n = "", e) => {
  try {
    const {
      data: t
    } = await u.get("/api/fretamento-online/v1/locations", {
      params: {
        name: p.text(n).toString(),
        ...f,
        ...e || {}
      }
    });
    return t.sort(g);
  } catch {
    throw new Error("Error in Locations");
  }
};
async function A({
  destination: n,
  origin: e,
  startCheckIn: t,
  numberOfMonths: r,
  rooms: o = []
}) {
  return (await u.get("/api/fretamento-online/v1/packages/calendar", {
    params: {
      origin: e,
      destination: n,
      numberOfMonths: r,
      startCheckIn: t,
      rooms: o.map((a) => a).join(" "),
      ...f
    }
  })).data;
}
export {
  A as getAffreightmentCalendar,
  h as getAffreightmentLocations
};
