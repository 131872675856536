import s from "react";
function n() {
  return n = Object.assign ? Object.assign.bind() : function(r) {
    for (var l = 1; l < arguments.length; l++) {
      var i = arguments[l];
      for (var e in i) ({}).hasOwnProperty.call(i, e) && (r[e] = i[e]);
    }
    return r;
  }, n.apply(null, arguments);
}
function f(r, l) {
  if (r == null) return {};
  var i = {};
  for (var e in r) if ({}.hasOwnProperty.call(r, e)) {
    if (l.includes(e)) continue;
    i[e] = r[e];
  }
  return i;
}
var p = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, a = p.size, w = {
  xsmall: a.xs,
  small: a.sm,
  medium: a.md,
  large: a.lg,
  xlarge: a.xl,
  xxlarge: a.xxl
}, $ = ["size", "className"], _ = function(l) {
  var i, e, m = l.size, o = m === void 0 ? "medium" : m, u = l.className, d = u === void 0 ? "" : u, t = f(l, $), v = t, g = ["chui-icon", d].join(" "), h = v.color || "#000014", x = n({}, t, {
    color: void 0
  }), c = a[o] || w[o];
  return /* @__PURE__ */ s.createElement("svg", n({
    xmlns: "http://www.w3.org/2000/svg",
    fill: h,
    viewBox: "0 0 24 24",
    width: (i = t.width) != null ? i : c,
    height: (e = t.height) != null ? e : c,
    className: g
  }, x), /* @__PURE__ */ s.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12.938 2.362a4.81 4.81 0 1 0-1.876 9.436 4.81 4.81 0 0 0 1.876-9.436M10.542 3.56a3.81 3.81 0 1 1 2.916 7.04 3.81 3.81 0 0 1-2.916-7.04",
    clipRule: "evenodd"
  }), /* @__PURE__ */ s.createElement("path", {
    fill: void 0,
    d: "M6.697 15.937A7.5 7.5 0 0 1 19.5 21.24a.5.5 0 0 0 1 0 8.5 8.5 0 1 0-17 0 .5.5 0 0 0 1 0 7.5 7.5 0 0 1 2.197-5.303"
  }));
};
_.displayName = "OutlineSingleUser";
export {
  f as _,
  n as a,
  a as e,
  w as l,
  _ as m
};
