import e from "react";
import { useResponsive as p, Grid as m, Typography as t } from "@cvccorp-components/chui-react-components";
import "../../FilledPlay-Bo3nTh9_.js";
import "../../FilledBed-CZJxOTKc.js";
import "../../FilledMoney-Dh2WYsyR.js";
import { r as l } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineArrowRight-fe4YoXL9.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { useTheme as n } from "@emotion/react";
import { Container as s } from "./styled.js";
const c = ({
  address: o,
  name: i
}) => {
  const {
    isMobile: r
  } = p(), a = n().colors.support.primary;
  return /* @__PURE__ */ e.createElement(s, null, /* @__PURE__ */ e.createElement(l, { size: r ? "lg" : "xl", style: {
    display: "block"
  }, color: a }), /* @__PURE__ */ e.createElement(m, { direction: "column" }, /* @__PURE__ */ e.createElement(t, { variant: r ? "paragraph" : "headline", scale: r ? 3 : 7, weight: "bold", color: "support.primary" }, i), o && !r ? /* @__PURE__ */ e.createElement(t, { variant: "paragraph", scale: 3, color: "support.primary" }, o) : void 0));
};
c.displayName = "DestinationLabel";
export {
  c as default
};
