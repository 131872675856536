import e from "react";
import { SearchButton as m, EngineProductsName as c } from "@cvccorp-engines/components";
import { useMotorPackageFullContext as s } from "../../context/EnginePackageFullContext.js";
import { usePackageFullStoreStore as u } from "../../store/EnginePackageFullStore.js";
import f from "../LocationAndDate/LocationAndDate.js";
import { EngineContainer as g, ButtonContainer as p } from "./DefaultLocationForm.styles.js";
const C = () => {
  const {
    handleSearch: t
  } = s(), {
    origin: o,
    destination: n,
    errors: r = {},
    reverse: a,
    setOrigin: i,
    setDestination: l
  } = u();
  return /* @__PURE__ */ e.createElement(g, null, /* @__PURE__ */ e.createElement(f, { errors: r, origin: o, destination: n, onDestinationChange: l, onOriginChange: i, onReverse: a }), /* @__PURE__ */ e.createElement(p, null, /* @__PURE__ */ e.createElement(m, { "aria-label": c.PACKAGE_FULL, onClick: t })));
};
C.displayName = "DefaultLocationForm";
export {
  C as default
};
