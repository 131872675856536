"use client";
import t from "react";
import a from "@cvccorp-components/chui-react-components/components/Button";
import C from "@cvccorp-components/chui-react-components/components/Dropdown";
import h from "@cvccorp-components/chui-react-components/components/Input";
import b from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { m as w } from "../../OutlineSingleUser-Dphw4daL.js";
import { useTheme as v } from "@emotion/react";
import { Container as y } from "./DropdownProps.styles.js";
function E(i) {
  const {
    label: l,
    supportText: s,
    error: m = !1,
    open: r,
    onClear: u,
    onToggle: e,
    onConfirm: n,
    renderContent: p,
    onClickOutside: o
  } = i, {
    name: d
  } = v(), {
    isMobile: c
  } = b(), f = () => /* @__PURE__ */ t.createElement(a, { color: "primary", disabled: !1, fullWidth: !1, loading: !1, shadow: !1, onClick: () => {
    n == null || n();
  }, size: "md", variant: "filled" }, "Aplicar");
  return /* @__PURE__ */ t.createElement(y, null, /* @__PURE__ */ t.createElement(C, { isOpen: r, onClose: () => {
    e == null || e(!1);
  }, onClickOutside: () => {
    c || (e == null || e(!1), o == null || o());
  }, className: "passenger-dropdown", renderContent: p, renderCancelButton: () => /* @__PURE__ */ t.createElement(a, { color: d === "submarino" ? "primary" : "secondary", onClick: u, variant: "text" }, "Limpar"), floating: {
    placement: "bottom",
    middleware: []
  }, renderConfirmButton: f, intrinsicAttributes: {
    style: {
      width: 330,
      maxHeight: "calc(100vh - 200px)"
    }
  } }, /* @__PURE__ */ t.createElement(h, { value: l, fullWidth: !0, onClick: () => {
    e == null || e(!r);
  }, addonAfter: /* @__PURE__ */ t.createElement(w, null), inputAttributes: {
    readOnly: !0
  }, supportText: s, status: m ? "danger" : r ? "focus" : void 0 })));
}
E.displayName = "Dropdown";
export {
  E as default
};
