import { FlightClasses as e } from "./classes.js";
const r = Object.freeze({
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}), c = Object.freeze({
  adult: 1,
  children: 0,
  baby: 0
}), l = Object.freeze({
  adult: 1,
  children: 0,
  elderly: 0
}), A = e.ALL;
export {
  A as DEFAULT_CLASSE,
  r as DEFAULT_ROOM,
  l as DEFAULT_TRAVEL_INSURANCE,
  c as DEFAULT_TRAVERS
};
