import i from "@emotion/styled";
const n = i.div`
    padding: 0px;
    /* container-type: inline-size; */

    fieldset {
        width: 100% !important;

        > div {
            height: 56px;
            > input {
                padding: 20px 8px 15px;
            }
        }
    }

    @container (min-width: 1024px) {
        display: flex;

        > div {
            width: 100%;
        }
    }
`;
export {
  n as LocationContainer
};
