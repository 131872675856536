import h from "@cvccorp-components/chui-react-components/lib/chuiDate";
import { c as l } from "../calcAge-B3J4JDE6.js";
function g(e, a) {
  if (!(e != null && e.length)) return [!1, {}];
  let r = !1;
  const t = {};
  return e.forEach((f, s) => {
    f.childrenDateOfBirth.forEach((i, c) => {
      const o = `${s}-${c}`;
      if (!h(i).isValid()) {
        t[o] = "Informe a data de nascimento", r = !0;
        return;
      }
      if (a && new Date(i).getTime() > new Date(a).getTime()) {
        t[o] = "Data é posterior ao fim da viagem", r = !0;
        return;
      }
      const n = l(i, a);
      (n < 0 || n > 17) && (t[o] = "Idade não está entre 0 e 17 anos", r = !0);
    });
  }), [r, t];
}
export {
  g as default
};
