import i from "@emotion/styled";
const p = i.div`
    position: relative;
    & > fieldset {
        width: 100%;
        min-height: 56px;

        & > div {
            height: 56px;
            & > input {
                padding: 20px 8px 15px;
            }
        }
    }

    @container (min-width: 1024px) {
        display: flex;

        > div {
            width: 100%;
        }
    }
`;
export {
  p as Container
};
