import { useCallback as l, useState as d, useEffect as f } from "react";
import { useEventListener as i } from "@cvccorp-components/chui-react-components";
import u from "../useEventCallback/useEventCallback.js";
function p(t, r) {
  const n = l(() => {
    if (typeof window > "u")
      return r;
    try {
      return window.localStorage.getItem(t) || r;
    } catch (o) {
      return console.warn(`Error reading localStorage key “${t}”:`, o), r;
    }
  }, [r, t]), [s, a] = d(n), g = u((o) => {
    typeof window > "u" && console.warn(`Tried setting localStorage key “${t}” even though environment is not a client`);
    try {
      const e = o instanceof Function ? o(s) : o;
      window.localStorage.setItem(t, typeof e == "string" ? e : JSON.stringify(e)), a(e), window.dispatchEvent(new Event("local-storage"));
    } catch (e) {
      console.warn(`Error setting localStorage key “${t}”:`, e);
    }
  });
  f(() => {
    a(n());
  }, []);
  const c = l((o) => {
    o != null && o.key && o.key !== t || a(n());
  }, [t, n]);
  return i("storage", c), i("local-storage", c), [s, g];
}
export {
  p as default
};
