import g, { createContext as A, useState as o, useEffect as x, useCallback as n, useMemo as S, useContext as L } from "react";
import { DEFAULT_TRAVERS as D, DEFAULT_CLASSE as m } from "../../../constants/defaultValues.js";
import F from "../../../validations/travelers.js";
const l = A({});
function R(h) {
  const {
    children: E,
    travelers: e,
    classe: s = m
  } = h, [c, a] = o(s), [C, t] = o(e), [u, T] = o();
  x(() => {
    t(e);
  }, [e]), x(() => {
    a(s);
  }, [s]);
  const v = n((r) => {
    const [P, p] = F(r);
    T(P ? p : void 0), t(r);
  }, []), d = n((r) => {
    a(r);
  }, []), i = n(() => {
    const r = e ? {
      ...e
    } : {
      ...D
    };
    t(r), a(s || m);
  }, [s, e]), f = S(() => ({
    classe: c,
    travelers: C,
    errors: u,
    onChangeClasse: d,
    onChangeTraveler: v,
    onClearTraveler: i
  }), [u, c, d, v, i, C]);
  return /* @__PURE__ */ g.createElement(l.Provider, { value: f }, E);
}
R.displayName = "PaxProvider";
const k = l.Consumer;
function y() {
  return L(l);
}
export {
  k as PaxConsumer,
  R as default,
  y as usePax
};
