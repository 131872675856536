function l(n) {
  if (!n) return {
    adultCount: 0,
    childrenCount: 0,
    babyCount: 0,
    elderlyCount: 0,
    total: 0,
    description: ""
  };
  const {
    adult: o = 0,
    baby: u = 0,
    children: e = 0,
    elderly: a = 0
  } = n, t = o + u + e + a;
  return {
    adultCount: o,
    childrenCount: e,
    babyCount: u,
    elderlyCount: a,
    total: t,
    description: t > 1 ? `${t} Viajantes` : "1 Viajante"
  };
}
export {
  l as default
};
