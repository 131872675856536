import { getBaseHostname as p, formatDateToParam as o, encodeSearchURL as h } from "@cvccorp-engines/components";
function g(e) {
  var t, r, n, s;
  const a = new URL(`${p()}/seguro-viagem/search`);
  a.searchParams.append("startDate", o((t = e.calendarDates) == null ? void 0 : t.startDate)), a.searchParams.append("endDate", o((r = e.calendarDates) == null ? void 0 : r.endDate)), a.searchParams.append("location", ((n = e.destination) == null ? void 0 : n.description) || ""), a.searchParams.append("zoneId", String((s = e.destination) == null ? void 0 : s.masterCode));
  const c = Array(e.pax.elderly).fill(70), d = Array(e.pax.adult).fill(30), l = Array(e.pax.children).fill(17), i = c.concat(d, l);
  return a.searchParams.append("paxs", String(i)), h(a);
}
export {
  g as default
};
