import t, { useMemo as I } from "react";
import { getCalendarDatesAsDate as J, InputGroup as M } from "@cvccorp-engines/components";
import { PaxPackages as N } from "@cvccorp-engines/pax";
import { usePackageFullStoreStore as g, defaultPackageFullStorePax as W } from "../../store/EnginePackageFullStore.js";
import G from "../CalendarPicker/CalendarPicker.js";
import u from "../SingleAffreightment/SingleAffreightment.js";
import { LocationAndDateWrapper as R } from "./LocationAndDate.styles.js";
function Q(D) {
  const {
    max: P,
    min: x,
    origin: r,
    destination: m,
    isMult: E = !1,
    onOriginChange: o,
    onDestinationChange: i
  } = D, {
    pax: S = W,
    errors: n,
    openPax: h,
    clearPax: A,
    setPax: L,
    setOpenPax: d
  } = g(), c = g((e) => J({
    startDate: e.calendarDates,
    endDate: ""
  })), O = I(() => {
    var e, a;
    return {
      endDate: (e = c[1]) == null ? void 0 : e.toJSON(),
      startDate: (a = c[0]) == null ? void 0 : a.toJSON()
    };
  }, [c]), b = () => n != null && n.destination ? "Preencha o campo de destino" : "", k = () => n != null && n.origin ? "Preencha o campo de origem" : "", y = (e) => {
    o == null || o(e);
  }, F = (e) => {
    i == null || i(e);
  };
  function p(e, a) {
    var f;
    return (f = e == null ? void 0 : e.city) != null && f.name ? e ? e.name : a : (e == null ? void 0 : e.name) ?? a;
  }
  const T = p(r, "Origem"), C = p(m, "Destino"), s = k(), l = b();
  return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(M, { fullWidth: !0, status: l || s ? "danger" : void 0 }, /* @__PURE__ */ t.createElement(u, { supportText: s, error: s, placeholder: "De onde você vai sair?", label: T, value: r, type: "origin", onSelect: y }), /* @__PURE__ */ t.createElement(u, { supportText: l, placeholder: "Para onde vai?", error: l, value: m, originId: r == null ? void 0 : r.id, type: "destination", onSelect: F, label: C, isDestination: !0 })), /* @__PURE__ */ t.createElement(R, { isMult: E }, /* @__PURE__ */ t.createElement(N, { infantAge: 1, value: S, error: n.pax, calendarDates: O, open: h, onToggle: d, onClear: A, onConfirm: (e) => {
    L(e), d(!1);
  } }), /* @__PURE__ */ t.createElement(G, { max: P, min: x })));
}
export {
  Q as default
};
