"use client";
import a, { useState as S, useMemo as T } from "react";
import b from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { getTravelSecurityLocations as x } from "@cvccorp-engines/api";
import { Pesqinc as y } from "@cvccorp-engines/components";
import { LocationContainer as O } from "./PesqincTravelInsurance.styles.js";
const P = (l) => {
  const {
    error: r,
    value: t,
    supportText: i,
    placeholder: p,
    supportTextProps: u,
    label: m = "Destino",
    onClear: o,
    onSelect: n
  } = l, [s, d] = S([]), f = async (e) => {
    try {
      const v = (await x(e)).locations.map((c) => ({
        label: c.description,
        value: c
      }));
      d(v);
    } catch {
      return [];
    }
  }, h = T(() => {
    const e = s;
    return t && e.push({
      value: t,
      label: t.description
    }), e;
  }, [s, t]);
  return /* @__PURE__ */ a.createElement(O, null, /* @__PURE__ */ a.createElement(y, { placeholder: p, label: r || m, supportText: i, supportTextProps: u, error: r, onSearch: f, onSelect: (e) => {
    if (!e) return o ? o == null ? void 0 : o() : n(t);
    n(e);
  }, options: h, value: t, renderOption: ({
    option: e
  }) => /* @__PURE__ */ a.createElement(b.Option, { fullWidth: !0, supportText: e.value.description, label: e.value.description, onSelect: () => n(e.value) }) }));
};
P.displayName = "PesqincTravelInsurance";
export {
  P as default
};
