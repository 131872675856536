"use client";
import e, { Fragment as u } from "react";
import p from "@cvccorp-components/chui-react-components/components/Divider";
import r from "@cvccorp-components/chui-react-components/components/Grid";
import E from "@cvccorp-components/chui-react-components/components/Select";
import n from "@cvccorp-components/chui-react-components/components/Typography";
import { classes as g } from "../../../constants/classes.js";
import { DEFAULT_CLASSE as v } from "../../../constants/defaultValues.js";
import { usePax as f } from "../PaxProvider/PaxProvider.js";
import b from "../PaxTravelers.js";
import { Container as h, Header as x, ClasseSelectWrapper as C } from "./PaxContent.styles.js";
function o(a) {
  return /* @__PURE__ */ e.createElement(p, { color: "gray-light", size: "sm", variant: "line", vertical: !1, ...a });
}
o.displayName = "Divider";
const P = () => {
  const {
    travelers: a,
    classe: s,
    errors: l,
    onChangeClasse: c,
    onChangeTraveler: i
  } = f(), m = (t) => {
    i({
      ...a,
      [t]: a[t] + 1
    });
  }, d = (t) => {
    i({
      ...a,
      [t]: a[t] - 1
    });
  };
  return /* @__PURE__ */ e.createElement(h, null, /* @__PURE__ */ e.createElement(x, null, /* @__PURE__ */ e.createElement(n, { variant: "label", scale: 6, color: "neutral.black", weight: "bold" }, "Viajantes"), /* @__PURE__ */ e.createElement("br", null), /* @__PURE__ */ e.createElement(n, { variant: "label", scale: 8, color: "neutral.black", weight: "regular" }, "Idades ao voltar da viagem")), /* @__PURE__ */ e.createElement(o, null), /* @__PURE__ */ e.createElement(b, { addPax: m, removePax: d, roomIndex: 0 }), /* @__PURE__ */ e.createElement(u, null, /* @__PURE__ */ e.createElement(o, null), /* @__PURE__ */ e.createElement(r, { container: !0, alignItems: "center", style: {
    paddingInline: 16,
    paddingBlockStart: 16
  } }, /* @__PURE__ */ e.createElement(r, { spacing: 0, item: !0, xs: 4 }, /* @__PURE__ */ e.createElement(n, { variant: "label", scale: 8, color: "neutral.black", weight: "bold" }, "Classe")), /* @__PURE__ */ e.createElement(r, { spacing: 0, item: !0, xs: 8 }, /* @__PURE__ */ e.createElement(C, null, /* @__PURE__ */ e.createElement(E, { options: g, value: s, inputProps: {
    fullWidth: !0
  }, onSelect: (t) => {
    c(t || v);
  }, shouldRenderDrawerMobile: !1, status: l != null && l.classe ? "danger" : void 0, supportText: l != null && l.classe ? "Preencha a classe" : void 0, dropdownAttributes: {
    style: {
      width: 200
    }
  } }))))));
};
P.displayName = "PaxContent";
export {
  P as default
};
