import r from "react";
import { Typography as a } from "@cvccorp-components/chui-react-components";
import "../../FilledPlay-Bo3nTh9_.js";
import "../../FilledBed-CZJxOTKc.js";
import "../../FilledMoney-Dh2WYsyR.js";
import { m } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineArrowRight-fe4YoXL9.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { d as o } from "../../dayjs-CKf7tiKR.js";
import { Container as p } from "./styled.js";
const i = ({
  date: t,
  dateFormat: e
}) => /* @__PURE__ */ r.createElement(p, null, /* @__PURE__ */ r.createElement(m, { size: "sm" }), /* @__PURE__ */ r.createElement(a, { variant: "paragraph", scale: 3 }, o(t).format(e)));
i.displayName = "TripDateLabel";
export {
  i as default
};
