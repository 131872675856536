"use client";
import t, { useRef as m, useMemo as S } from "react";
import { getCalendarDatesAsDate as k, InputGroup as y, InputDatePickerRange as A } from "@cvccorp-engines/components";
import { d as f } from "../../dayjs-CKf7tiKR.js";
import { PaxTravelInsurance as J } from "@cvccorp-engines/pax";
import { useTravelInsuranceStore as L } from "../../store/TravelInsuranceStore.js";
import M from "../PesqincTravelInsurance/PesqincTravelInsurance.js";
import { LocationAndDateWrapper as N } from "./LocationAndDate.styles.js";
const W = f().add(1.5, "year").toDate(), j = f().add(1, "day").toDate();
function H() {
  const {
    pax: P,
    destination: h,
    calendarDates: d,
    errors: e,
    openPax: v,
    clearPax: g,
    setOpenPax: l,
    setPax: x,
    setCalendarDates: R,
    setDestination: c,
    clearDates: C
  } = L(), E = m(null), I = m(null), T = S(() => k(d) || [], [d]), O = (a) => {
    if (!a) return;
    const [n, o] = a;
    R({
      startDate: n == null ? void 0 : n.toJSON(),
      endDate: o == null ? void 0 : o.toJSON()
    });
  }, r = () => {
    var n, o, i, D, u, p;
    const a = {
      startDate: "",
      endDate: ""
    };
    return (n = e == null ? void 0 : e.calendarDates) != null && n.startDate && (a.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (a.endDate = "Preencha a data de volta"), (i = e == null ? void 0 : e.calendarDates) != null && i.startDate && ((D = e == null ? void 0 : e.calendarDates) != null && D.endDate) && (a.startDate = "Preencha a data de ida", a.endDate = "Preencha a data de volta"), !((u = e == null ? void 0 : e.calendarDates) != null && u.startDate) && !((p = e == null ? void 0 : e.calendarDates) != null && p.endDate) && !(e != null && e.date) && (a.startDate = "", a.endDate = ""), a;
  }, s = () => e != null && e.destination ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(M, { placeholder: "Para onde você vai?", supportText: s(), error: s(), value: h, onSelect: c, inputProps: {
    inputRef: E
  }, onClear: () => c(void 0) }), /* @__PURE__ */ t.createElement(N, null, /* @__PURE__ */ t.createElement(y, { fullWidth: !0, status: r().startDate || r().endDate ? "danger" : void 0 }, /* @__PURE__ */ t.createElement(A, { value: T, inputOneProps: {
    inputRef: I
  }, max: W, min: j, showRangeCount: !0, error: r(), onChange: O, onClear: C, hasMinRange: 3, datePickerProps: {
    minRange: 1
  } })), /* @__PURE__ */ t.createElement(J, { onConfirm: (a) => {
    x(a), l(!1);
  }, open: v, onToggle: l, error: e.pax, onClear: g, value: P })));
}
export {
  H as default
};
