"use client";
var s = function(e) {
  return e == null || e === "undefined" || e === "null";
};
const p = () => window.location.href.replace("#rooms", "").replace("#freteRecommend", "").replace("#HotelSelected", "").replace("#FlightSelected", "").replace(/%252C/g, ",").split("?")[1];
function i(r, e) {
  try {
    const t = p();
    if (!r) return t.replace(/%257C/i, "|");
    const n = t.split("&"), a = [];
    for (let c = 0; c < n.length; c += 1) {
      const o = n[c].split("=");
      a[o[0]] = o[1];
    }
    const l = a[r].replace(/%257C/i, "|");
    return s(l) ? e : decodeURIComponent(l);
  } catch {
    return e;
  }
}
export {
  i as default
};
