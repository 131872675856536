import t from "react";
import d from "@cvccorp-components/chui-react-components/components/Checkbox";
import p from "../MultiToursLocation/MultiToursLocation.js";
import f from "../SelectDate/SelectDate.js";
import { LocationWrapper as h } from "./styled.js";
function C(n) {
  const {
    date: r,
    errors: e,
    allDestiny: l,
    destinations: a = [],
    onDateChange: c,
    onAllDestinyChange: i,
    onDestinationsChange: o
  } = n, m = () => e != null && e.date ? "Preencha o campo de data" : "", s = () => e != null && e.destinations ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(h, null, /* @__PURE__ */ t.createElement(p, { value: a, onSelect: (u) => o == null ? void 0 : o(u), error: s() }), /* @__PURE__ */ t.createElement(d, { color: "primary", falseValue: "false", label: "Buscar circuitos com ao menos um dos destinos escolhidos", size: "md", trueValue: "true", onChange: i, checked: l })), /* @__PURE__ */ t.createElement(f, { label: "Início", destinations: a, onSelect: c, value: r, error: m() }));
}
export {
  C as default
};
