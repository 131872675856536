import r, { createContext as a, useMemo as s, useContext as g } from "react";
const e = a({
  navigationType: "link"
});
function u(t) {
  const {
    children: o,
    navigationType: n
  } = t, i = s(() => ({
    navigationType: n
  }), [n]);
  return /* @__PURE__ */ r.createElement(e.Provider, { value: i }, o);
}
u.displayName = "EnginesConfigProvider";
function f() {
  return g(e);
}
export {
  u as default,
  f as useEnginesConfigContext
};
