import t from "@emotion/styled";
const o = t.div(() => ({
  containerType: "inline-size",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  columnGap: 16,
  marginBlockEnd: 24,
  "& .chui-carousel-shelf__container": {
    gap: 16
  },
  "@container (max-width: 899px)": {
    marginBlockEnd: 12
  }
})), i = t("a")(({
  disabled: n
}) => ({
  pointerEvents: n ? "none" : void 0,
  "*": {
    cursor: n ? "auto" : "pointer"
  },
  input: {
    pointerEvents: "none"
  }
}));
export {
  o as Container,
  i as Link
};
