import { getBaseHostname as et, encodeSearchURL as nt } from "@cvccorp-engines/components";
var E = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function rt(d) {
  return d && d.__esModule && Object.prototype.hasOwnProperty.call(d, "default") ? d.default : d;
}
var V = { exports: {} };
(function(d, v) {
  (function(S, M) {
    d.exports = M();
  })(E, function() {
    var S = 1e3, M = 6e4, H = 36e5, b = "millisecond", l = "second", _ = "minute", w = "hour", O = "day", P = "week", y = "month", F = "quarter", L = "year", T = "date", J = "Invalid Date", G = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, Q = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, K = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, A = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, X = {
      s: A,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), s = r % 60;
        return (e <= 0 ? "+" : "-") + A(n, 2, "0") + ":" + A(s, 2, "0");
      },
      m: function u(t, e) {
        if (t.date() < e.date()) return -u(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, y), s = e - n < 0, i = t.clone().add(r + (s ? -1 : 1), y);
        return +(-(r + (e - n) / (s ? n - i : i - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: y,
          y: L,
          w: P,
          d: O,
          D: T,
          h: w,
          m: _,
          s: l,
          ms: b,
          Q: F
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, x = "en", j = {};
    j[x] = K;
    var Z = "$isDayjsObject", z = function(t) {
      return t instanceof I || !(!t || !t[Z]);
    }, W = function u(t, e, r) {
      var n;
      if (!t) return x;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        j[s] && (n = s), e && (j[s] = e, n = s);
        var i = t.split("-");
        if (!n && i.length > 1) return u(i[0]);
      } else {
        var o = t.name;
        j[o] = t, n = o;
      }
      return !r && n && (x = n), n || !r && x;
    }, f = function(t, e) {
      if (z(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new I(r);
    }, a = X;
    a.l = W, a.i = z, a.w = function(u, t) {
      return f(u, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var I = function() {
      function u(e) {
        this.$L = W(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[Z] = !0;
      }
      var t = u.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, s = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (a.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var i = n.match(G);
            if (i) {
              var o = i[2] - 1 || 0, c = (i[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(i[1], o, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, c)) : new Date(i[1], o, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, c);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return a;
      }, t.isValid = function() {
        return this.$d.toString() !== J;
      }, t.isSame = function(e, r) {
        var n = f(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return f(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < f(e);
      }, t.$g = function(e, r, n) {
        return a.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, s = !!a.u(r) || r, i = a.p(e), o = function(D, $) {
          var Y = a.w(n.$u ? Date.UTC(n.$y, $, D) : new Date(n.$y, $, D), n);
          return s ? Y : Y.endOf(O);
        }, c = function(D, $) {
          return a.w(n.toDate()[D].apply(n.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice($)), n);
        }, h = this.$W, m = this.$M, p = this.$D, g = "set" + (this.$u ? "UTC" : "");
        switch (i) {
          case L:
            return s ? o(1, 0) : o(31, 11);
          case y:
            return s ? o(1, m) : o(0, m + 1);
          case P:
            var k = this.$locale().weekStart || 0, C = (h < k ? h + 7 : h) - k;
            return o(s ? p - C : p + (6 - C), m);
          case O:
          case T:
            return c(g + "Hours", 0);
          case w:
            return c(g + "Minutes", 1);
          case _:
            return c(g + "Seconds", 2);
          case l:
            return c(g + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, s = a.p(e), i = "set" + (this.$u ? "UTC" : ""), o = (n = {}, n[O] = i + "Date", n[T] = i + "Date", n[y] = i + "Month", n[L] = i + "FullYear", n[w] = i + "Hours", n[_] = i + "Minutes", n[l] = i + "Seconds", n[b] = i + "Milliseconds", n)[s], c = s === O ? this.$D + (r - this.$W) : r;
        if (s === y || s === L) {
          var h = this.clone().set(T, 1);
          h.$d[o](c), h.init(), this.$d = h.set(T, Math.min(this.$D, h.daysInMonth())).$d;
        } else o && this.$d[o](c);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[a.p(e)]();
      }, t.add = function(e, r) {
        var n, s = this;
        e = Number(e);
        var i = a.p(r), o = function(p) {
          var g = f(s);
          return a.w(g.date(g.date() + Math.round(p * e)), s);
        };
        if (i === y) return this.set(y, this.$M + e);
        if (i === L) return this.set(L, this.$y + e);
        if (i === O) return o(1);
        if (i === P) return o(7);
        var c = (n = {}, n[_] = M, n[w] = H, n[l] = S, n)[i] || 1, h = this.$d.getTime() + e * c;
        return a.w(h, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || J;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", i = a.z(this), o = this.$H, c = this.$m, h = this.$M, m = n.weekdays, p = n.months, g = n.meridiem, k = function($, Y, U, tt) {
          return $ && ($[Y] || $(r, s)) || U[Y].slice(0, tt);
        }, C = function($) {
          return a.s(o % 12 || 12, $, "0");
        }, N = g || function(D, $, Y) {
          var U = D < 12 ? "AM" : "PM";
          return Y ? U.toLowerCase() : U;
        };
        return s.replace(Q, function(D, $) {
          return $ || function(Y) {
            switch (Y) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return a.s(r.$y, 4, "0");
              case "M":
                return h + 1;
              case "MM":
                return a.s(h + 1, 2, "0");
              case "MMM":
                return k(n.monthsShort, h, p, 3);
              case "MMMM":
                return k(p, h);
              case "D":
                return r.$D;
              case "DD":
                return a.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return k(n.weekdaysMin, r.$W, m, 2);
              case "ddd":
                return k(n.weekdaysShort, r.$W, m, 3);
              case "dddd":
                return m[r.$W];
              case "H":
                return String(o);
              case "HH":
                return a.s(o, 2, "0");
              case "h":
                return C(1);
              case "hh":
                return C(2);
              case "a":
                return N(o, c, !0);
              case "A":
                return N(o, c, !1);
              case "m":
                return String(c);
              case "mm":
                return a.s(c, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return a.s(r.$s, 2, "0");
              case "SSS":
                return a.s(r.$ms, 3, "0");
              case "Z":
                return i;
            }
            return null;
          }(D) || i.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var s, i = this, o = a.p(r), c = f(e), h = (c.utcOffset() - this.utcOffset()) * M, m = this - c, p = function() {
          return a.m(i, c);
        };
        switch (o) {
          case L:
            s = p() / 12;
            break;
          case y:
            s = p();
            break;
          case F:
            s = p() / 3;
            break;
          case P:
            s = (m - h) / 6048e5;
            break;
          case O:
            s = (m - h) / 864e5;
            break;
          case w:
            s = m / H;
            break;
          case _:
            s = m / M;
            break;
          case l:
            s = m / S;
            break;
          default:
            s = m;
        }
        return n ? s : a.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(y).$D;
      }, t.$locale = function() {
        return j[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), s = W(e, r, !0);
        return s && (n.$L = s), n;
      }, t.clone = function() {
        return a.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, u;
    }(), B = I.prototype;
    return f.prototype = B, [["$ms", b], ["$s", l], ["$m", _], ["$H", w], ["$W", O], ["$M", y], ["$y", L], ["$D", T]].forEach(function(u) {
      B[u[1]] = function(t) {
        return this.$g(t, u[0], u[1]);
      };
    }), f.extend = function(u, t) {
      return u.$i || (u(t, I, f), u.$i = !0), f;
    }, f.locale = W, f.isDayjs = z, f.unix = function(u) {
      return f(1e3 * u);
    }, f.en = j[x], f.Ls = j, f.p = {}, f;
  });
})(V);
var R = V.exports;
const q = /* @__PURE__ */ rt(R);
var st = { exports: {} };
(function(d, v) {
  (function(S, M) {
    d.exports = M(R);
  })(E, function(S) {
    function M(l) {
      return l && typeof l == "object" && "default" in l ? l : {
        default: l
      };
    }
    var H = M(S), b = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(_) {
        return _ + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return H.default.locale(b, null, !0), b;
  });
})(st);
q.locale("pt-br");
function at(d) {
  var S, M, H, b, l, _, w;
  const v = new URL(`${et()}/ingresso/search`);
  return v.searchParams.set("originCode", ((S = d.destination) == null ? void 0 : S.cityId) || ""), v.searchParams.set("destinationCode", ((M = d.destination) == null ? void 0 : M.cityId) || ""), v.searchParams.set("location", `${(H = d.destination) == null ? void 0 : H.cityName} - ${(b = d.destination) == null ? void 0 : b.stateName} - ${(l = d.destination) == null ? void 0 : l.countryName} - ` || ""), v.searchParams.set("startDate", q(d.date).format("YYYY-MM-DD") || ""), v.searchParams.set("endDate", q(d.date).add(2, "months").format("YYYY-MM-DD") || ""), (_ = d.destination) != null && _.provider && v.searchParams.set("provider", (w = d.destination) == null ? void 0 : w.provider), v.searchParams.set("language", "pt-br"), nt(v);
}
export {
  q as d,
  at as f
};
