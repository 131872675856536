function i(t) {
  if (!(t != null && t.length))
    return {
      adultCount: 0,
      childrenCount: 0,
      adultByRoom: "",
      childrenByRoom: "",
      descriptions: {
        rooms: "",
        travelers: ""
      }
    };
  let e = 0, l = 0;
  const a = [], u = [];
  t.forEach((n) => {
    e += n.adult, l += n.children, a.push(n.adult), u.push(n.children);
  });
  const d = e + l;
  return {
    adultCount: e,
    childrenCount: l,
    adultByRoom: a.join(","),
    childrenByRoom: u.join(","),
    descriptions: {
      rooms: t.length > 1 ? `${t.length} Quartos` : "1 Quarto",
      travelers: d > 1 ? `${d} Viajantes` : "1 Viajante"
    }
  };
}
export {
  i as default
};
