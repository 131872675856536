"use client";
import a, { useRef as x } from "react";
import b from "@cvccorp-components/chui-react-components/components/InputDatePickerRange";
import { d as O } from "../../dayjs-CKf7tiKR.js";
import T from "../InputLoadingOverlay/InputLoadingOverlay.js";
import { Wrapper as W } from "./InputDatePickerRange.styles.js";
const E = (i) => {
  const {
    value: s,
    min: p,
    max: l,
    options: u,
    inputGroupProps: d,
    inputOneProps: m,
    inputTwoProps: c,
    content: f,
    isLoading: P = !1,
    error: e = {},
    showRangeCount: D = !0,
    twoMonths: h = !0,
    datePickerProps: g,
    onClear: C,
    onFocus: w,
    onChange: r,
    onChangeCalendar: y,
    disableDates: R = () => !1
  } = i, o = p ?? /* @__PURE__ */ new Date(), k = l ?? O(o).add(1, "year").toDate(), v = x(null);
  function n(t) {
    r == null || r(t);
  }
  return /* @__PURE__ */ a.createElement(W, { ref: v }, /* @__PURE__ */ a.createElement(b, { inputGroupProps: {
    fullWidth: !0,
    ...d
  }, inputOneProps: {
    fullWidth: !0,
    label: "Início",
    placeholder: "",
    supportText: e.startDate,
    status: e.startDate ? "danger" : void 0,
    ...m
  }, inputTwoProps: {
    label: "Fim",
    placeholder: "",
    supportText: e.endDate,
    status: e.endDate ? "danger" : void 0,
    fullWidth: !0,
    ...c
  }, datePickerProps: {
    twoMonths: h,
    options: u,
    onFocus: w,
    maxDate: k,
    minDate: o,
    disable: R,
    size: "sm",
    ...g
  }, value: s, showRangeCount: D, onConfirm: n, onClear: C, onChangeCalendar: y, content: f, onChange: (t) => {
    Array.isArray(t) && n(t);
  } }), P ? /* @__PURE__ */ a.createElement(T, null) : void 0);
};
E.displayName = "DatePickerRange";
export {
  E as default
};
