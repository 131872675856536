import { _ as w, a as d, e as g, l as $ } from "./Icon-o1sXhey4.js";
import c from "react";
var f = ["size", "className"], N = function(l) {
  var i, a, o = l.size, t = o === void 0 ? "medium" : o, s = l.className, m = s === void 0 ? "" : s, e = w(l, f), n = e, v = ["chui-icon", m].join(" "), h = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = g[t] || $[t];
  return /* @__PURE__ */ c.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: h,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (a = e.height) != null ? a : r,
    className: v
  }, u), /* @__PURE__ */ c.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M16.824 2.457a.5.5 0 0 1 0 .707l-8.765 8.764a.1.1 0 0 0-.023.111.1.1 0 0 0 .023.033l.005.005 8.76 8.76a.5.5 0 0 1-.708.707L7.36 12.786a1.1 1.1 0 0 1 0-1.572l8.758-8.757a.5.5 0 0 1 .707 0",
    clipRule: "evenodd"
  }));
};
N.displayName = "OutlineArrowLeft";
var _ = ["size", "className"], x = function(l) {
  var i, a, o = l.size, t = o === void 0 ? "medium" : o, s = l.className, m = s === void 0 ? "" : s, e = w(l, _), n = e, v = ["chui-icon", m].join(" "), h = n.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = g[t] || $[t];
  return /* @__PURE__ */ c.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: h,
    viewBox: "0 0 24 24",
    width: (i = e.width) != null ? i : r,
    height: (a = e.height) != null ? a : r,
    className: v
  }, u), /* @__PURE__ */ c.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M7.176 2.457a.5.5 0 0 1 .708 0l8.758 8.758a1.1 1.1 0 0 1 0 1.57l-8.758 8.759a.5.5 0 1 1-.708-.707l8.765-8.765a.1.1 0 0 0 0-.144l-.005-.004-8.76-8.76a.5.5 0 0 1 0-.707",
    clipRule: "evenodd"
  }));
};
x.displayName = "OutlineArrowRight";
export {
  x as a,
  N as m
};
