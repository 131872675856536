"use client";
import e from "react";
import { L as C } from "../../index-kwCrDZsL.js";
const L = (n) => {
  const {
    max: t,
    min: a,
    origin: o,
    errors: r,
    children: i,
    destination: s,
    dates: l = [],
    isMult: m = !1,
    hidePax: c = !1,
    onRemove: d,
    onReverse: f,
    onDateClear: g,
    onDateChange: u,
    onOriginChange: h,
    onDestinationChange: D
  } = n;
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(C, { onRemove: d, errors: r, dates: l, onDateClear: g, onDateChange: u, max: t, min: a, hidePax: c, isMult: m, origin: o, destination: s, onDestinationChange: D, onOriginChange: h, onReverse: f }), i);
};
export {
  L as default
};
