import i from "@emotion/styled";
const t = i.div(() => ({
  "fieldset > div > input": {
    paddingBlock: 0,
    height: 40
  },
  ".chui-input__wrapper": {
    height: 40
  }
})), d = i.div`
    width: 100%;
`, p = i.div`
    padding: 18px;
`;
export {
  t as ClasseSelectWrapper,
  d as Container,
  p as Header
};
