import o from "@emotion/styled";
const e = o.div`
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: ${({
  theme: i
}) => i.spacing.size[3]};
    }
`;
export {
  e as LocationWrapper
};
