import t from "./countPaxRoom.js";
function e(r) {
  const {
    descriptions: o
  } = t(r);
  return `${o.rooms} - ${o.travelers}`;
}
export {
  e as default
};
