import a from "@cvccorp-components/chui-react-components/components/InputGroup";
import n from "@emotion/styled";
const p = n(a)(({
  status: e,
  theme: r,
  hideIcon: t = !0,
  hideClearIcon: i = !1
}) => ({
  ".chui-input__addon--after": {
    backgroundColor: "transparent !important"
  },
  ".chui-input-group__separator": {
    height: 56,
    background: "transparent",
    "@media (max-width: 720px)": {
      height: 40,
      zIndex: "300!important"
    }
  },
  "fieldset > div": {
    height: 56,
    'button[aria-label="Expandir"]': {
      opacity: t ? 0 : "unset",
      width: t ? 0 : "unset",
      display: t ? "none" : "unset",
      visibility: t ? "hidden" : "visible"
    },
    'button[aria-label="Limpar"]': {
      opacity: i ? 0 : "unset",
      width: i ? 0 : "unset",
      display: i ? "none" : "unset",
      visibility: i ? "hidden" : "visible"
    }
  },
  ".reverse-button": {
    height: 56,
    width: 40,
    "@media (max-width: 720px)": {
      height: 40
    }
  },
  ".return-icon": {
    color: "rgba(0, 0, 0, 0.3)",
    width: 40,
    height: 40,
    display: "flex",
    zIndex: 2,
    background: "rgb(10, 0, 180)",
    borderRadius: "100%",
    "> svg": {
      margin: "auto",
      stroke: "#ffffff",
      width: 24,
      height: 24
    }
  },
  ".chui-input__help-text": {
    color: e === "danger" ? r.colors.feedback.danger[600] : "unset"
  }
}));
export {
  p as Wrapper
};
