import a, { useRef as r, Fragment as R } from "react";
import { InputDatePicker as D } from "@cvccorp-engines/components";
import P from "../SingleTickets/SingleTickets.js";
function h(l) {
  const {
    errors: e,
    max: c,
    min: i,
    destination: d,
    date: u,
    onDestinationChange: t,
    onDateChange: m,
    onDateClear: p
  } = l, f = r(null), s = r(null), n = () => e != null && e.destination ? "Preencha o campo de destino" : "", E = () => e != null && e.date ? "Preencha o campo de data" : "";
  return /* @__PURE__ */ a.createElement(R, null, /* @__PURE__ */ a.createElement(P, { supportText: n(), error: n(), label: "Destino", value: d, onSelect: (o) => {
    t == null || t(o);
  }, inputProps: {
    inputRef: f
  }, onClear: () => {
    t == null || t(void 0);
  } }), /* @__PURE__ */ a.createElement(D, { label: "Escolha a data", value: u, onChange: m, onClear: p, max: c, min: i, error: E(), inputRef: s }));
}
h.displayName = "LocationAndDate";
export {
  h as default
};
