import { _ as w, a as o, e as $, l as g } from "./Icon-o1sXhey4.js";
import d from "react";
var f = ["size", "className"], N = function(l) {
  var a, i, c = l.size, t = c === void 0 ? "medium" : c, v = l.className, s = v === void 0 ? "" : v, e = w(l, f), n = e, m = ["chui-icon", s].join(" "), r = n.color || "#000014", u = o({}, e, {
    color: void 0
  }), h = $[t] || g[t];
  return /* @__PURE__ */ d.createElement("svg", o({
    xmlns: "http://www.w3.org/2000/svg",
    fill: r,
    viewBox: "0 0 24 24",
    width: (a = e.width) != null ? a : h,
    height: (i = e.height) != null ? i : h,
    className: m
  }, u), /* @__PURE__ */ d.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M15.338 15.427H8.595a2.147 2.147 0 0 1-4.119 0h-.323c-.901 0-1.643-.742-1.643-1.643v-5.09c0-.907.718-1.658 1.643-1.658h12.785a4.55 4.55 0 0 1 4.553 4.554v2.194c0 .9-.742 1.643-1.642 1.643h-.393a2.147 2.147 0 0 1-4.118 0M4.153 8.037a.645.645 0 0 0-.643.657v1.816h3.495V8.037zm3.852 2.473h3.496V8.037H8.005zm4.496 0h3.495V8.037h-3.495zm4.495-2.473a3.55 3.55 0 0 1 3.495 3.552v2.195a.65.65 0 0 1-.642.643h-.342a2.146 2.146 0 0 0-2.511-1.714zm-1 5.16V11.51H3.51v2.273c0 .348.294.643.643.643h.273a2.146 2.146 0 0 1 4.22 0h6.642c.09-.489.347-.919.708-1.23m-9.46.479a1.146 1.146 0 1 0 0 2.291 1.146 1.146 0 0 0 0-2.291m9.715 1.146a1.146 1.146 0 1 1 2.292 0 1.146 1.146 0 0 1-2.292 0",
    clipRule: "evenodd"
  }));
};
N.displayName = "OutlineBus";
var z = ["size", "className"], _ = function(l) {
  var a, i, c = l.size, t = c === void 0 ? "medium" : c, v = l.className, s = v === void 0 ? "" : v, e = w(l, z), n = e, m = ["chui-icon", s].join(" "), r = n.color || "#000014", u = o({}, e, {
    color: void 0
  }), h = $[t] || g[t];
  return /* @__PURE__ */ d.createElement("svg", o({
    xmlns: "http://www.w3.org/2000/svg",
    fill: r,
    viewBox: "0 0 24 24",
    width: (a = e.width) != null ? a : h,
    height: (i = e.height) != null ? i : h,
    className: m
  }, u), /* @__PURE__ */ d.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m9.924 7.938 1.003 1.775c.232.4-.05.934-.566.934H7.776c-.401 0-.774-.132-1.075-.397L5.421 9.14C4.81 8.597 3.99 8.3 3.146 8.3h-.143a.03.03 0 0 0-.015.004.1.1 0 0 0-.016.013.1.1 0 0 0-.008.019l.001.014v.001l1.342 3.898c.222.653.867 1.108 1.6 1.108h2.925q.06-.016.124-.016h10.677c.772 0 1.402-.605 1.402-1.347 0-.74-.63-1.347-1.402-1.347h-3.431a.67.67 0 0 1-.445-.174L11.58 6.7h-.001a1.05 1.05 0 0 0-.723-.273c-.8 0-1.317.822-.932 1.51m-.107 6.344H5.91c-1.15 0-2.165-.71-2.517-1.745l-1.34-3.895c-.216-.623.273-1.27.952-1.27h.143c1.072 0 2.132.377 2.927 1.089l1.279 1.105c.114.102.25.154.423.154h2.057l-.757-1.34v-.002c-.732-1.306.25-2.877 1.78-2.877.52 0 1.005.181 1.384.525l4.093 3.694h3.302c1.303 0 2.365 1.02 2.365 2.273s-1.062 2.274-2.366 2.274h-3.303l-4.106 3.709c-.38.345-.884.525-1.385.525-1.53 0-2.512-1.571-1.78-2.877v-.001zm1.094.006-1.003 1.776c-.385.688.132 1.51.932 1.51.265 0 .529-.096.723-.272V17.3l3.346-3.021-3.988-.009zm5.521-4.479-.008-.007z",
    clipRule: "evenodd"
  }));
};
_.displayName = "OutlinePlaneRight";
var x = ["size", "className"], M = function(l) {
  var a, i, c = l.size, t = c === void 0 ? "medium" : c, v = l.className, s = v === void 0 ? "" : v, e = w(l, x), n = e, m = ["chui-icon", s].join(" "), r = n.color || "#000014", u = o({}, e, {
    color: void 0
  }), h = $[t] || g[t];
  return /* @__PURE__ */ d.createElement("svg", o({
    xmlns: "http://www.w3.org/2000/svg",
    fill: r,
    viewBox: "0 0 24 24",
    width: (a = e.width) != null ? a : h,
    height: (i = e.height) != null ? i : h,
    className: m
  }, u), /* @__PURE__ */ d.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ d.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
M.displayName = "OutlinePinMap";
var R = ["size", "className"], E = function(l) {
  var a, i, c = l.size, t = c === void 0 ? "medium" : c, v = l.className, s = v === void 0 ? "" : v, e = w(l, R), n = e, m = ["chui-icon", s].join(" "), r = n.color || "#000014", u = o({}, e, {
    color: void 0
  }), h = $[t] || g[t];
  return /* @__PURE__ */ d.createElement("svg", o({
    xmlns: "http://www.w3.org/2000/svg",
    fill: r,
    viewBox: "0 0 24 24",
    width: (a = e.width) != null ? a : h,
    height: (i = e.height) != null ? i : h,
    className: m
  }, u), /* @__PURE__ */ d.createElement("path", {
    fill: void 0,
    d: "M6.8 12.73a.84.84 0 0 0-.82.8.81.81 0 1 0 .82-.8M6.34 17.137a.81.81 0 1 1 .9 1.347.81.81 0 0 1-.9-1.348M12.02 12.73a.82.82 0 0 0-.82.8.81.81 0 1 0 .82-.8M11.57 17.137a.81.81 0 1 1 .9 1.346.81.81 0 0 1-.9-1.346M17.25 14.34a.81.81 0 0 0 0-1.61.83.83 0 0 0-.81.79.81.81 0 0 0 .81.82M16.94 17.063a.8.8 0 0 1 .31-.063.81.81 0 1 1 0 1.62.81.81 0 0 1-.749-1.12.8.8 0 0 1 .179-.26.8.8 0 0 1 .26-.177"
  }), /* @__PURE__ */ d.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M18.79 4.72h-2v-2a.5.5 0 0 0-1 0v2H8.21v-2a.5.5 0 0 0-1 0v2h-2a3 3 0 0 0-3 3v11.05a3 3 0 0 0 3 3h13.58a3 3 0 0 0 3-3V7.69a3 3 0 0 0-3-2.97m-13.54 1h2v1.35a.5.5 0 1 0 1 0V5.72h7.62v1.35a.5.5 0 0 0 1 0V5.72h2a2 2 0 0 1 2 2v2H3.29v-2a2 2 0 0 1 1.96-2m13.54 15.01v-.01H5.25a2 2 0 0 1-2-2v-8.07h17.5v8.12a2 2 0 0 1-1.96 1.96",
    clipRule: "evenodd"
  }));
};
E.displayName = "OutlineCalendar2";
export {
  N as a,
  _ as d,
  E as m,
  M as r
};
