import { getParams as t } from "@cvccorp-engines/components";
const m = () => {
  const o = parseInt(t("Adults") || "0", 10), n = parseInt(t("Children") || "0", 10), e = t("CheckIn") || "", s = t("CheckOut") || "", r = parseInt(t("Rooms") || "0", 10), c = decodeURI(t("Location") || ""), d = t("ZoneId") || "";
  return {
    adult: o,
    children: n,
    origDate: e,
    destDate: s,
    room: r,
    destDescription: c,
    destZoneId: d,
    durationUnit: "Milliseconds"
  };
};
export {
  m as getAffreightmentParamsForLogs
};
