"use client";
import { create as o } from "zustand";
import { getCarsPickupDateInitialValue as a, getCarsReturnDateInitialValue as u, getCarsOriginInitialValue as n, getCarsDestinationInitialValue as c, getCarsCustomLocationInitialValue as p, getCarsPickupTimeInitialValue as l, getCarsReturnTimeInitialValue as s } from "../utils/getCarsInitialValue.js";
const L = o((i) => ({
  pickupDate: a(),
  returnDate: u(),
  pickupLocation: n(),
  returnLocation: c(),
  showReturnLocation: p(),
  pickupTime: l(),
  returnTime: s(),
  errors: {
    pickupLocation: !1,
    returnLocation: !1,
    pickupDate: !1,
    returnDate: !1,
    pickupTime: !1,
    returnTime: !1
  },
  setPickupLocation: (e) => i(() => ({
    pickupLocation: e
  })),
  setReturnLocation: (e) => i(() => ({
    returnLocation: e
  })),
  setPickupDate: (e) => i(() => ({
    pickupDate: e
  })),
  setReturnDate: (e) => i(() => ({
    returnDate: e
  })),
  setShowReturnLocation: (e) => i(() => ({
    showReturnLocation: e,
    returnTime: void 0,
    returnDate: void 0
  })),
  setPickupTime: (e) => i(() => ({
    pickupTime: e
  })),
  setReturnTime: (e) => i(() => ({
    returnTime: e
  })),
  setErrors: (e) => i(() => ({
    errors: e
  })),
  clearPickupDate: () => i(() => ({
    pickupDate: void 0,
    pickupTime: void 0
  })),
  clearReturnDate: () => i(() => ({
    returnDate: void 0,
    returnTime: void 0
  })),
  reverse() {
    return i((e) => {
      var r, t;
      return {
        pickupLocation: e.returnLocation,
        returnLocation: e.pickupLocation,
        pickupDate: void 0,
        returnDate: void 0,
        pickupTime: void 0,
        returnTime: void 0,
        errors: {
          ...e.errors,
          pickupLocation: (r = e.errors) == null ? void 0 : r.returnLocation,
          returnLocation: (t = e.errors) == null ? void 0 : t.pickupLocation
        }
      };
    });
  }
}));
export {
  L as useCarsStore
};
