import EnginePackageProvider from "./EnginePackageContext";
import SingleDestinationForm from "./SingleDestinationForm";

const EnginePackage = () => {
    return (
        <EnginePackageProvider>
            <SingleDestinationForm />
        </EnginePackageProvider>
    );
};

export default EnginePackage;
