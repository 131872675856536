import r, { useRef as P, useMemo as g } from "react";
import { InputGroup as B, InputDatePickerRange as I } from "@cvccorp-engines/components";
import { d as u } from "../../dayjs-CKf7tiKR.js";
import { PaxPackages as J } from "@cvccorp-engines/pax";
import { useResortsStore as L } from "../../store/ResortsStore.js";
import { resortsOptions as N } from "../../utils/resortOptions.js";
import T from "../SingleResorts/SingleResorts.js";
import { LocationAndDateWrapper as W } from "./styled.js";
function Q({
  onDateChange: d,
  onDateClear: x,
  max: R = u(/* @__PURE__ */ new Date()).add(1.5, "year").toDate(),
  min: E,
  onDestinationChange: s,
  destination: v,
  dates: a = []
}) {
  const A = P(null), O = P(null), {
    setOpenPax: D,
    clearPax: S,
    pax: b,
    openPax: h,
    errors: e,
    setPax: w
  } = L(), y = g(() => {
    var t, n;
    return {
      endDate: (t = a[1]) == null ? void 0 : t.toJSON(),
      startDate: (n = a[0]) == null ? void 0 : n.toJSON()
    };
  }, [a]), k = (t) => {
    if ((a == null ? void 0 : a.length) === 2) return !1;
    if (a[0]) {
      const n = u(a[0]), o = u(t), l = n.add(29, "day"), c = n.subtract(29, "day");
      return o.isAfter(l) || o.isBefore(c);
    }
    return !1;
  }, i = () => {
    var n, o, l, c, p, m;
    const t = {
      startDate: "",
      endDate: ""
    };
    return (n = e == null ? void 0 : e.calendarDates) != null && n.startDate && (t.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (t.endDate = "Preencha a data de volta"), (l = e == null ? void 0 : e.calendarDates) != null && l.startDate && ((c = e == null ? void 0 : e.calendarDates) != null && c.endDate) && (t.startDate = "Preencha a data de ida", t.endDate = "Preencha a data de volta"), !((p = e == null ? void 0 : e.calendarDates) != null && p.startDate) && !((m = e == null ? void 0 : e.calendarDates) != null && m.endDate) && (t.startDate = "", t.endDate = ""), t;
  }, f = () => e != null && e.destination ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(T, { label: "Destino", options: N, error: !!f(), value: v, onSelect: (t) => {
    s == null || s(t);
  }, inputProps: {
    inputRef: A,
    supportText: f()
  } }), /* @__PURE__ */ r.createElement(W, null, /* @__PURE__ */ r.createElement(B, { fullWidth: !0, status: i().startDate || i().endDate ? "danger" : void 0 }, /* @__PURE__ */ r.createElement(I, { value: a, inputOneProps: {
    inputRef: O
  }, max: R, min: E, showRangeCount: !0, disableDates: k, error: i(), onChange: (t) => {
    d == null || d(t);
  }, onClear: x })), /* @__PURE__ */ r.createElement(J, { open: h, value: b, error: e.pax, calendarDates: y, onConfirm: (t) => {
    w(t), D(!1);
  }, onClear: S, onToggle: D })));
}
export {
  Q as default
};
