import { getParams as e } from "@cvccorp-engines/components";
const g = () => e("inter") ?? "false", D = () => {
  const t = e("CheckIn");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, d = () => {
  const t = e("CheckOut");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, f = () => {
  var m;
  const t = e("Rooms"), o = e("Adults"), r = e("Children"), i = e("ChildAges"), l = [];
  if (!t) return [{
    adult: 2,
    children: 0,
    childrenDateOfBirth: []
  }];
  for (let n = 0; n < Number(t); n++) {
    const s = o == null ? void 0 : o.split(","), a = r == null ? void 0 : r.split(","), c = i ? i.split(";") : [], u = {
      adult: Number(s == null ? void 0 : s[n]),
      children: Number(a == null ? void 0 : a[n]),
      childrenDateOfBirth: (m = c == null ? void 0 : c[n]) == null ? void 0 : m.split(";").map((h) => new Date((/* @__PURE__ */ new Date()).setFullYear((/* @__PURE__ */ new Date()).getFullYear() - Number(h))).toISOString().split("T")[0])
    };
    l.push(u);
  }
  return l;
};
export {
  g as getResortsDestinationParam,
  d as getResortsEndDateParam,
  f as getResortsPaxParam,
  D as getResortsStartDateParam
};
