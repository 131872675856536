import o from "../../libs/fetcher.js";
import i from "../../utils/QueryString.js";
const e = async (r) => {
  try {
    const {
      data: t
    } = await o.get("/api/flights/locations", {
      params: {
        query: i.text(r).toString()
      }
    });
    return t;
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  e as getFlightsLocations
};
