import a, { useState as x, useEffect as S, useMemo as T } from "react";
import b from "@cvccorp-components/chui-react-components/components/AutoCompleteTag";
import { getRecommendedDestinations as g } from "@cvccorp-engines/api";
import { useAutoCompleteItemSize as v } from "@cvccorp-engines/components";
import { LocationContainer as w } from "./MultiToursLocation.styles.js";
const y = (u) => {
  const {
    error: s,
    value: o,
    supportText: p,
    supportTextProps: c,
    label: i = "Destino",
    onSelect: m
  } = u, [l, d] = x(() => []);
  S(() => {
    async function t() {
      const e = await g();
      if (!(e != null && e.length)) return;
      const n = e.map((r) => ({
        label: r.name,
        value: {
          zoneId: r.masterCode,
          description: r.name
        }
      }));
      d(n);
    }
    t();
  }, []);
  const f = T(() => {
    const t = [...l];
    return o ? (o.forEach((e) => {
      const n = {
        label: e.description,
        value: e
      };
      t.push(n);
    }), [...t]) : t;
  }, [l, o]), h = v();
  return /* @__PURE__ */ a.createElement(w, null, /* @__PURE__ */ a.createElement(b, { placeholder: i, label: i, itemSize: h, supportText: p ?? s, loading: !1, inputProps: {
    supportTextProps: c,
    fullWidth: !0,
    inputAttributes: {
      readOnly: !0
    }
  }, onSelect: (t) => {
    t && m(t);
  }, options: f, status: s ? "danger" : void 0, value: o, maxSize: 5, dropdownAttributes: {
    style: {
      width: 530,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  } }));
};
y.displayName = "MultiToursLocation";
export {
  y as default
};
