import r from "react";
import { Pesqinc as i } from "@cvccorp-engines/components";
import n from "./useSingleAffreighmnet.js";
function f(e) {
  const t = n(e);
  return /* @__PURE__ */ r.createElement(i, { ...t });
}
f.displayName = "SingleAffreightments";
export {
  f as default
};
