import t from "react";
import { SearchButton as c } from "@cvccorp-engines/components";
import { d as l } from "../../formatUrlToSearch-C_Df-S6H.js";
import { useMotorTicketsContext as f } from "../../contexts/EngineTicketsContext.js";
import { useTicketsStore as u } from "../../store/TicketsStore.js";
import D from "../DefaultLocationForm/index.js";
import { MotorContainer as d, buttonContainer as p } from "./styled.js";
function C() {
  const {
    handleSearch: o
  } = f(), {
    destination: n,
    setDestination: r,
    date: a,
    setDate: i,
    errors: m,
    clearDate: s
  } = u();
  return /* @__PURE__ */ t.createElement(d, { twoItems: !0 }, /* @__PURE__ */ t.createElement(D, { errors: m, destination: n, onDestinationChange: r, date: new Date(a ?? ""), onDateChange: (e) => {
    e && i(e.toString());
  }, onDateClear: s, min: l().add(1, "d").toDate() }, /* @__PURE__ */ t.createElement(p, null, /* @__PURE__ */ t.createElement(c, { onClick: o }))));
}
C.displayName = "SingleDestinationForm";
export {
  C as default
};
