import T, { createContext as I, useCallback as v, useMemo as S, useContext as P } from "react";
import { useIsomorphicLayoutEffect as A } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as L, LocalStorageKeys as y, setDataLayer as R, redirectToSearch as _ } from "@cvccorp-engines/components";
import { d as B } from "../dayjs-CKf7tiKR.js";
import { useTravelInsuranceStore as q } from "../store/TravelInsuranceStore.js";
import w from "../utils/formatUrlToSearch.js";
const x = I(void 0);
function N(n) {
  var u, m;
  const {
    setErrors: i,
    setPax: E,
    setDestination: h,
    setCalendarDates: g,
    ...e
  } = q(), [a, c] = L(y.LAST_SEARCH_TRAVEL_INSURANCE);
  A(() => {
    a && !e.destination && (h(a.destination), E(a.pax), B(/* @__PURE__ */ new Date()).isAfter(a.calendarDates.startDate) || g(a.calendarDates));
  }, []);
  const d = v(() => {
    var D, p;
    let r = !1;
    const o = !e.pax, s = !e.destination, t = !((D = e.calendarDates) != null && D.endDate), f = !((p = e.calendarDates) != null && p.startDate);
    return r = s || t || f || o, [r, {
      destination: s,
      pax: o,
      calendarDates: {
        endDate: t,
        startDate: f
      }
    }];
  }, [(u = e == null ? void 0 : e.calendarDates) == null ? void 0 : u.endDate, (m = e == null ? void 0 : e.calendarDates) == null ? void 0 : m.startDate, e.destination, e.pax]), l = v(() => {
    var t;
    const [r, o] = d();
    if (i({
      ...o
    }), r)
      return;
    R({
      event: "resultado_busca_engajamento",
      category: "Ingresso-Pesquisa-Vendedor",
      action: (t = e.destination) == null ? void 0 : t.description
    });
    const s = w(e).replaceAll("+,+", ", ").replaceAll("+", " ");
    c({
      pax: e.pax,
      calendarDates: e.calendarDates,
      destination: e.destination
    }), _(s);
  }, [i, e, d, c]), C = S(() => ({
    handleSearch: l
  }), [l]);
  return /* @__PURE__ */ T.createElement(x.Provider, { ...n, value: C });
}
N.displayName = "EngineTravelInsuranceProvider";
function K() {
  const n = P(x);
  if (!n) throw new Error("Chamada do hook useEngineTravelInsuranceContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineTravelInsuranceProvider");
  return n;
}
export {
  N as default,
  K as useEngineTravelInsuranceContext
};
