function s(a, t) {
  const {
    allowEmpty: n = !0
  } = t || {}, r = new URLSearchParams();
  return Object.entries(a).forEach(([c, e]) => {
    !e && !n || r.append(c, e.trim());
  }), r;
}
export {
  s as default
};
