"use client";
import r, { useCallback as d } from "react";
import h from "@cvccorp-components/chui-react-components/components/Carousel/CarouselShelf";
import k from "@cvccorp-components/chui-react-components/components/Chip";
import { useTheme as v } from "@emotion/react";
import { Container as y, Link as E } from "./OptionsBar.styles.js";
function C(o) {
  const {
    options: i,
    value: m,
    variant: u,
    onChange: l,
    isChecked: c
  } = o, {
    name: s
  } = v(), p = d((n, t) => {
    n || l == null || l(t);
  }, [l]), f = (n) => {
    const {
      checked: t,
      value: e,
      children: a
    } = n;
    return u === "link" ? /* @__PURE__ */ r.createElement(E, { disabled: t, href: e, key: e, "aria-current": t ? "page" : void 0 }, a) : /* @__PURE__ */ r.createElement(r.Fragment, null, a);
  };
  return /* @__PURE__ */ r.createElement(y, null, /* @__PURE__ */ r.createElement(h, null, i.map((n) => {
    const {
      label: t,
      value: e
    } = n, a = (c == null ? void 0 : c(n)) || m === e;
    return /* @__PURE__ */ r.createElement(f, { checked: a, value: e, key: e }, /* @__PURE__ */ r.createElement(k, { onClick: () => p(a, e), checked: a, color: s === "cvc" ? "secondary" : "primary", variant: "filter", key: e }, t));
  })));
}
C.displayName = "OptionsBar";
export {
  C as default
};
