const l = [{
  label: "Resorts all inclusive do Brasil",
  value: "false"
}, {
  label: "Resorts all inclusive internacionais",
  value: "true"
}];
export {
  l as resortsOptions
};
