"use client";
import m, { createContext as l, useCallback as u, useMemo as d, useContext as p } from "react";
import f from "@cvccorp-components/chui-react-components/hooks/useWindow";
import { useTheme as v } from "@emotion/react";
const n = l({});
function w(i) {
  const {
    children: c,
    onError: e
  } = i, r = f(), {
    name: o
  } = v(), t = u((a) => {
    e == null || e({
      _app_name: "lib-fe-typescript-react-embedded-cms-motor",
      referer: r.location.origin,
      provider: o.toLocaleLowerCase(),
      level: 6,
      ...a
    });
  }, [r, o, e]), s = d(() => ({
    throwError: t
  }), [t]);
  return /* @__PURE__ */ m.createElement(n.Provider, { value: s }, c);
}
w.displayName = "ErrorProvider";
function b() {
  return p(n);
}
export {
  w as default,
  b as useError
};
