function d(t) {
  return t.reduce((e, {
    bestPriceWithTax: r
  }) => {
    const n = Math.ceil(r);
    return n < e ? n : e;
  }, 1 / 0);
}
function b(t, e) {
  return e ? "#14d264" : t ? "#ff7323" : "#a5a5b9";
}
function m(t) {
  if (!(t != null && t.length)) return [];
  const e = d(t);
  return t.reduce((r, {
    promotional: n,
    date: i,
    bestPriceWithTax: o
  }) => {
    const u = Math.ceil(o), s = e === u;
    if (r.some((c) => c.date === i)) return r;
    const f = b(n, s);
    return r.push({
      date: i,
      subtitle: new Intl.NumberFormat("pt-br").format(u),
      subtitleProps: {
        style: {
          fontWeight: 700,
          color: f
        }
      }
    }), r;
  }, []);
}
export {
  m as default
};
