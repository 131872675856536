import v, { createContext as L, useCallback as S, useMemo as y } from "react";
import { useIsomorphicLayoutEffect as g } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as A, LocalStorageKeys as C, redirectToSearch as P } from "@cvccorp-engines/components";
import { d as B } from "../dayjs-CKf7tiKR.js";
import { useHotelStore as T } from "../store/HotelStore.js";
import R from "../utils/formatUrlToSearch.js";
const U = L(void 0);
function _(u) {
  var d, m;
  const {
    setErrors: n,
    setDestination: h,
    setCalendarDates: x,
    setPax: E,
    ...e
  } = T(), [s, c] = A(C.LAST_SEARCH_HOTEL, void 0);
  g(() => {
    if (!e.destination && s) {
      const {
        calendarDates: t,
        destination: a,
        pax: o
      } = s;
      h(a), E(o), B(/* @__PURE__ */ new Date()).isAfter(t.startDate) || x(t);
    }
  }, []);
  const l = S(() => {
    var f, p;
    let t = !1;
    const a = !e.pax, o = !e.destination, r = !((f = e.calendarDates) != null && f.endDate), D = !((p = e.calendarDates) != null && p.startDate);
    return t = o || r || D || a, [t, {
      destination: o,
      pax: a,
      calendarDates: {
        endDate: r,
        startDate: D
      }
    }];
  }, [(d = e.calendarDates) == null ? void 0 : d.endDate, (m = e.calendarDates) == null ? void 0 : m.startDate, e.destination, e.pax]), i = S(() => {
    const [t, a] = l();
    if (n({
      ...a
    }), t)
      return;
    c({
      calendarDates: e.calendarDates,
      destination: e.destination,
      pax: e.pax
    });
    const o = R(e).replaceAll("+,+", ", ").replaceAll("+", " ");
    P(o);
  }, [e, n, l, c]), H = y(() => ({
    handleSearch: i
  }), [i]);
  return /* @__PURE__ */ v.createElement(U.Provider, { ...u, value: H });
}
_.displayName = "EngineHotelProvider";
export {
  U as EngineHotelContext,
  _ as default
};
