import l from "react";
import t from "../Counter/Counter.js";
import { usePax as u } from "./PaxProvider/PaxProvider.js";
const h = () => {
  const {
    travelers: a,
    onChangeTraveler: o
  } = u(), {
    adult: m,
    children: d,
    elderly: c
  } = a;
  function n(e) {
    o({
      ...a,
      [e]: ++a[e]
    });
  }
  function r(e) {
    o({
      ...a,
      [e]: --a[e]
    });
  }
  return /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement(t, { label: "Idosos", helperText: "Acima de 70 anos", max: 9, onChange: (e) => {
    e > c ? n("elderly") : r("elderly");
  }, value: c }), /* @__PURE__ */ l.createElement(t, { label: "Adultos", helperText: "Acima de 18 anos", min: 1, max: 9, onChange: (e) => {
    e > m ? n("adult") : r("adult");
  }, value: m }), /* @__PURE__ */ l.createElement(t, { label: "Crianças", helperText: "Até 17 anos", max: 9, onChange: (e) => {
    e > d ? n("children") : r("children");
  }, value: d }));
};
export {
  h as default
};
