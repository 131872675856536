var a = /* @__PURE__ */ ((e) => (e.ALL = "all", e.ECO_PREMIUM = "eco", e.EXEC_PREMIUM = "exe", e))(a || {});
const l = [{
  label: "Todas as classes",
  value: "all"
  /* ALL */
}, {
  label: "Econômica & Premium",
  value: "eco"
  /* ECO_PREMIUM */
}, {
  label: "Executiva & Premium",
  value: "exe"
  /* EXEC_PREMIUM */
}];
export {
  a as FlightClasses,
  l as classes
};
