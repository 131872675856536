import t from "@emotion/styled";
const i = t.div(() => ({
  width: "100%",
  "& fieldset": {
    height: "fit-content"
  },
  ".chui-input__wrapper": {
    height: 56
  }
}));
export {
  i as Wrapper
};
