var E = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function tt(y) {
  return y && y.__esModule && Object.prototype.hasOwnProperty.call(y, "default") ? y.default : y;
}
var V = { exports: {} };
(function(y, P) {
  (function(O, v) {
    y.exports = v();
  })(E, function() {
    var O = 1e3, v = 6e4, x = 36e5, L = "millisecond", l = "second", D = "minute", H = "hour", S = "day", C = "week", m = "month", N = "quarter", b = "year", j = "date", U = "Invalid Date", G = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, Q = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, K = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, q = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, R = {
      s: q,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), s = r % 60;
        return (e <= 0 ? "+" : "-") + q(n, 2, "0") + ":" + q(s, 2, "0");
      },
      m: function u(t, e) {
        if (t.date() < e.date()) return -u(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, m), s = e - n < 0, i = t.clone().add(r + (s ? -1 : 1), m);
        return +(-(r + (e - n) / (s ? n - i : i - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: m,
          y: b,
          w: C,
          d: S,
          D: j,
          h: H,
          m: D,
          s: l,
          ms: L,
          Q: N
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, k = "en", w = {};
    w[k] = K;
    var J = "$isDayjsObject", F = function(t) {
      return t instanceof A || !(!t || !t[J]);
    }, W = function u(t, e, r) {
      var n;
      if (!t) return k;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        w[s] && (n = s), e && (w[s] = e, n = s);
        var i = t.split("-");
        if (!n && i.length > 1) return u(i[0]);
      } else {
        var o = t.name;
        w[o] = t, n = o;
      }
      return !r && n && (k = n), n || !r && k;
    }, f = function(t, e) {
      if (F(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new A(r);
    }, a = R;
    a.l = W, a.i = F, a.w = function(u, t) {
      return f(u, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var A = function() {
      function u(e) {
        this.$L = W(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[J] = !0;
      }
      var t = u.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, s = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (a.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var i = n.match(G);
            if (i) {
              var o = i[2] - 1 || 0, c = (i[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(i[1], o, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, c)) : new Date(i[1], o, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, c);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return a;
      }, t.isValid = function() {
        return this.$d.toString() !== U;
      }, t.isSame = function(e, r) {
        var n = f(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return f(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < f(e);
      }, t.$g = function(e, r, n) {
        return a.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, s = !!a.u(r) || r, i = a.p(e), o = function(p, $) {
          var g = a.w(n.$u ? Date.UTC(n.$y, $, p) : new Date(n.$y, $, p), n);
          return s ? g : g.endOf(S);
        }, c = function(p, $) {
          return a.w(n.toDate()[p].apply(n.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice($)), n);
        }, d = this.$W, h = this.$M, _ = this.$D, M = "set" + (this.$u ? "UTC" : "");
        switch (i) {
          case b:
            return s ? o(1, 0) : o(31, 11);
          case m:
            return s ? o(1, h) : o(0, h + 1);
          case C:
            var Y = this.$locale().weekStart || 0, T = (d < Y ? d + 7 : d) - Y;
            return o(s ? _ - T : _ + (6 - T), h);
          case S:
          case j:
            return c(M + "Hours", 0);
          case H:
            return c(M + "Minutes", 1);
          case D:
            return c(M + "Seconds", 2);
          case l:
            return c(M + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, s = a.p(e), i = "set" + (this.$u ? "UTC" : ""), o = (n = {}, n[S] = i + "Date", n[j] = i + "Date", n[m] = i + "Month", n[b] = i + "FullYear", n[H] = i + "Hours", n[D] = i + "Minutes", n[l] = i + "Seconds", n[L] = i + "Milliseconds", n)[s], c = s === S ? this.$D + (r - this.$W) : r;
        if (s === m || s === b) {
          var d = this.clone().set(j, 1);
          d.$d[o](c), d.init(), this.$d = d.set(j, Math.min(this.$D, d.daysInMonth())).$d;
        } else o && this.$d[o](c);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[a.p(e)]();
      }, t.add = function(e, r) {
        var n, s = this;
        e = Number(e);
        var i = a.p(r), o = function(_) {
          var M = f(s);
          return a.w(M.date(M.date() + Math.round(_ * e)), s);
        };
        if (i === m) return this.set(m, this.$M + e);
        if (i === b) return this.set(b, this.$y + e);
        if (i === S) return o(1);
        if (i === C) return o(7);
        var c = (n = {}, n[D] = v, n[H] = x, n[l] = O, n)[i] || 1, d = this.$d.getTime() + e * c;
        return a.w(d, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || U;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", i = a.z(this), o = this.$H, c = this.$m, d = this.$M, h = n.weekdays, _ = n.months, M = n.meridiem, Y = function($, g, I, X) {
          return $ && ($[g] || $(r, s)) || I[g].slice(0, X);
        }, T = function($) {
          return a.s(o % 12 || 12, $, "0");
        }, z = M || function(p, $, g) {
          var I = p < 12 ? "AM" : "PM";
          return g ? I.toLowerCase() : I;
        };
        return s.replace(Q, function(p, $) {
          return $ || function(g) {
            switch (g) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return a.s(r.$y, 4, "0");
              case "M":
                return d + 1;
              case "MM":
                return a.s(d + 1, 2, "0");
              case "MMM":
                return Y(n.monthsShort, d, _, 3);
              case "MMMM":
                return Y(_, d);
              case "D":
                return r.$D;
              case "DD":
                return a.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return Y(n.weekdaysMin, r.$W, h, 2);
              case "ddd":
                return Y(n.weekdaysShort, r.$W, h, 3);
              case "dddd":
                return h[r.$W];
              case "H":
                return String(o);
              case "HH":
                return a.s(o, 2, "0");
              case "h":
                return T(1);
              case "hh":
                return T(2);
              case "a":
                return z(o, c, !0);
              case "A":
                return z(o, c, !1);
              case "m":
                return String(c);
              case "mm":
                return a.s(c, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return a.s(r.$s, 2, "0");
              case "SSS":
                return a.s(r.$ms, 3, "0");
              case "Z":
                return i;
            }
            return null;
          }(p) || i.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var s, i = this, o = a.p(r), c = f(e), d = (c.utcOffset() - this.utcOffset()) * v, h = this - c, _ = function() {
          return a.m(i, c);
        };
        switch (o) {
          case b:
            s = _() / 12;
            break;
          case m:
            s = _();
            break;
          case N:
            s = _() / 3;
            break;
          case C:
            s = (h - d) / 6048e5;
            break;
          case S:
            s = (h - d) / 864e5;
            break;
          case H:
            s = h / x;
            break;
          case D:
            s = h / v;
            break;
          case l:
            s = h / O;
            break;
          default:
            s = h;
        }
        return n ? s : a.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(m).$D;
      }, t.$locale = function() {
        return w[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), s = W(e, r, !0);
        return s && (n.$L = s), n;
      }, t.clone = function() {
        return a.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, u;
    }(), Z = A.prototype;
    return f.prototype = Z, [["$ms", L], ["$s", l], ["$m", D], ["$H", H], ["$W", S], ["$M", m], ["$y", b], ["$D", j]].forEach(function(u) {
      Z[u[1]] = function(t) {
        return this.$g(t, u[0], u[1]);
      };
    }), f.extend = function(u, t) {
      return u.$i || (u(t, A, f), u.$i = !0), f;
    }, f.locale = W, f.isDayjs = F, f.unix = function(u) {
      return f(1e3 * u);
    }, f.en = w[k], f.Ls = w, f.p = {}, f;
  });
})(V);
var B = V.exports;
const et = /* @__PURE__ */ tt(B);
var nt = { exports: {} };
(function(y, P) {
  (function(O, v) {
    y.exports = v(B);
  })(E, function(O) {
    function v(l) {
      return l && typeof l == "object" && "default" in l ? l : {
        default: l
      };
    }
    var x = v(O), L = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(D) {
        return D + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return x.default.locale(L, null, !0), L;
  });
})(nt);
et.locale("pt-br");
export {
  et as d
};
