import type { ProductError } from "@attentions-components/hooks/dist/@types/error";
import { ErrorProvider, useError, type ProductTypes } from "@cvccorp-engines/components";
import { EngineBox } from "@cvccorp-engines/search/src";
import Engine from "@cvccorp-engines/search/src/engine/EngineWrapper";

interface MotorWrapperProps {
    type: ProductTypes;
    hide?: ProductTypes[];
}

function EngineWrapper({ type, hide }: MotorWrapperProps) {
    const { throwError } = useError();
    return (
        <EngineBox>
            <ErrorProvider
                onError={params => {
                    throwError(params as ProductError);
                }}
            >
                <Engine type={type} hide={hide} />
            </ErrorProvider>
        </EngineBox>
    );
}

export default EngineWrapper;
