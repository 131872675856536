import e from "@emotion/styled";
const t = e.div`
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 600px) {
        display: none;
    }
`;
export {
  t as Container
};
