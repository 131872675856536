"use client";
import e from "react";
import d from "@cvccorp-components/chui-react-components/components/Typography";
import { useTheme as c } from "@emotion/react";
import m from "../Counter/Counter.js";
import { usePax as p } from "./PaxProvider/PaxProvider.js";
function v(i) {
  const {
    addPax: n,
    removePax: o,
    roomIndex: t
  } = i, {
    travelers: a,
    errors: r
  } = p(), s = c();
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(m, { label: "Adultos", helperText: "Acima de 12 anos", min: 1, max: 8, onChange: (l) => {
    l > a.adult ? n("adult", t) : o("adult", t);
  }, value: a.adult }), /* @__PURE__ */ e.createElement(m, { label: "Crianças", helperText: "De 2 até 12 anos", max: 5, onChange: (l) => {
    l > a.children ? n("children", t) : o("children", t);
  }, value: a.children }), /* @__PURE__ */ e.createElement(m, { label: "Bebês", helperText: "De 3 até 23 meses", max: a.adult ?? 1, onChange: (l) => {
    l > a.baby ? n("baby", t) : o("baby", t);
  }, value: a.baby }), (r == null ? void 0 : r.baby) && /* @__PURE__ */ e.createElement("div", { style: {
    paddingInline: 16,
    transform: "translateY(-60%)",
    textAlign: "end"
  } }, /* @__PURE__ */ e.createElement(d, { scale: 8, variant: "label", style: {
    color: s.colors.feedback.danger[600]
  } }, r.baby)), /* @__PURE__ */ e.createElement(d, { variant: "paragraph", scale: 3, color: "support.optional", weight: "regular", style: {
    margin: "0 16px 10px 16px"
  } }, "*Idade ao voltar da viagem"));
}
export {
  v as default
};
