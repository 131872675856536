import e from "react";
import { getCalendarDatesAsDate as d, SearchButton as p } from "@cvccorp-engines/components";
import { d as u } from "../../dayjs-CKf7tiKR.js";
import "../../context/EngineResortsContext/EngineResortsContext.js";
import { useEngineResortsContext as f } from "../../context/EngineResortsContext/useEngineResortsContext.js";
import { useResortsStore as C } from "../../store/ResortsStore.js";
import S from "../DefaultLocationForm/index.js";
import { MotorContainer as g, buttonContainer as h } from "./styled.js";
function E() {
  const {
    handleSearch: a
  } = f(), {
    destination: r,
    setDestination: i,
    calendarDates: s,
    setCalendarDates: m,
    errors: l,
    clearDates: c,
    setOpenPax: D
  } = C();
  return /* @__PURE__ */ e.createElement(g, null, /* @__PURE__ */ e.createElement(S, { errors: l, destination: r, onDestinationChange: i, onOpenPax: () => D(!0), dates: d(s), onDateClear: c, onDateChange: (o) => {
    if (!o) return;
    const [t, n] = o;
    m({
      startDate: t == null ? void 0 : t.toJSON(),
      endDate: n == null ? void 0 : n.toJSON()
    });
  }, min: u().add(1, "d").toDate() }, /* @__PURE__ */ e.createElement(h, null, /* @__PURE__ */ e.createElement(p, { onClick: a }))));
}
E.displayName = "SingleDestinationForm";
export {
  E as default
};
