import { getParams as e } from "@cvccorp-engines/components";
const r = () => {
  const t = {
    iata: e("IataOri", ""),
    description: e("OriginDesc", ""),
    zoneId: Number(e("OriginZoneId", "0")),
    type: e("OriginType", ""),
    city: e("City", ""),
    state: e("State", ""),
    country: e("Country", ""),
    officeHours: []
  };
  return t.description ? t : void 0;
}, a = () => e("CustomLocation") === "true", s = () => {
  const t = {
    iata: e("IataDes", ""),
    description: e("DestinationDesc", ""),
    zoneId: Number(e("DestZoneId", "0")),
    type: e("DestinationType", ""),
    city: "",
    state: "",
    country: "",
    officeHours: []
  };
  return a() ? t.description ? void 0 : t : t.description ? t : void 0;
}, c = () => {
  const t = e("CheckIn");
  return t ? t.concat("T03:00:00.000Z") : "";
}, u = () => {
  var i;
  const t = e("CheckInTime", "");
  return ((i = [{
    label: "",
    value: ""
  }].find((n) => n.label === t)) == null ? void 0 : i.value) ?? "21";
}, l = () => {
  const t = e("CheckOut");
  return t ? t.concat("T03:00:00.000Z") : "";
}, I = () => {
  var i;
  const t = e("CheckoutTime", "");
  return ((i = [{
    label: "",
    value: ""
  }].find((n) => n.label === t)) == null ? void 0 : i.value) ?? "21";
};
export {
  a as getCarsCustomLocationInitialValue,
  s as getCarsDestinationInitialValue,
  r as getCarsOriginInitialValue,
  c as getCarsPickupDateInitialValue,
  u as getCarsPickupTimeInitialValue,
  l as getCarsReturnDateInitialValue,
  I as getCarsReturnTimeInitialValue
};
