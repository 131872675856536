import i from "@emotion/styled";
const p = i.div`
    padding: 0px;
    display: flex;

    & > fieldset {
        height: fit-content;
        & > div {
            height: 56px;
            & > input {
                padding: 20px 8px 15px;
            }
        }
    }
`;
export {
  p as Container
};
