"use client";
import e from "react";
import { DEFAULT_TRAVEL_INSURANCE as c } from "../../constants/defaultValues.js";
import i from "../../utils/formatPaxTravelInsurance.js";
import f from "../Dropdown/Dropdown.js";
import p from "./PaxContent.js";
import x, { PaxConsumer as P } from "./PaxProvider/PaxProvider.js";
function C(t) {
  const {
    open: l,
    value: m = c,
    error: o,
    onToggle: s,
    onConfirm: a,
    onClear: r
  } = t;
  return /* @__PURE__ */ e.createElement(x, { travelers: m }, /* @__PURE__ */ e.createElement(P, null, ({
    onClearTraveler: u,
    travelers: n
  }) => /* @__PURE__ */ e.createElement(f, { label: i(n), error: o, supportText: o ? "Preencha quantos passageiros e a classe" : "", onToggle: s, onClickOutside: () => {
    a(n);
  }, onClear: () => {
    r == null || r(), u();
  }, onConfirm: () => {
    a(n);
  }, renderContent: () => /* @__PURE__ */ e.createElement(p, null), open: l })));
}
C.displayName = "PaxTravelInsurance";
export {
  C as default
};
