import { useRef as t, useLayoutEffect as a, useCallback as u } from "react";
function o(r) {
  const e = t(() => {
    throw new Error("Cannot call an event handler while rendering.");
  });
  return a(() => {
    e.current = r;
  }, [r]), u((...n) => e.current(...n), [e]);
}
export {
  o as default
};
