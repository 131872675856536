import { HTMLAttributes } from "react";
import * as Styled from "./Engine.styled";

function EngineBox(props: HTMLAttributes<HTMLDivElement>) {
    const { children, ...restProps } = props;

    return (
        <Styled.Root className="chui-engines">
            <Styled.Wrapper>
                <Styled.Container {...restProps}>{children}</Styled.Container>
            </Styled.Wrapper>
        </Styled.Root>
    );
}

export default EngineBox;
