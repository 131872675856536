import e from "react";
import r from "@cvccorp-components/chui-react-components/components/Button";
import o from "../../context/useEngineHotel.js";
import l from "../LocationAndDate/LocationAndDate.js";
import { Container as a, ButtonContainer as n } from "./Form.styles.js";
function i() {
  const {
    handleSearch: t
  } = o();
  return /* @__PURE__ */ e.createElement(a, null, /* @__PURE__ */ e.createElement(l, null), /* @__PURE__ */ e.createElement(n, null, /* @__PURE__ */ e.createElement(r, { color: "primary", disabled: !1, fullWidth: !0, loading: !1, shadow: !1, size: "lg", onClick: () => {
    t();
  }, variant: "filled" }, "Buscar")));
}
i.displayName = "Form";
export {
  i as default
};
