import { getBaseHostname as et, encodeSearchURL as nt } from "@cvccorp-engines/components";
var B = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function rt(h) {
  return h && h.__esModule && Object.prototype.hasOwnProperty.call(h, "default") ? h.default : h;
}
var E = { exports: {} };
(function(h, O) {
  (function(D, M) {
    h.exports = M();
  })(B, function() {
    var D = 1e3, M = 6e4, L = 36e5, S = "millisecond", l = "second", _ = "minute", k = "hour", b = "day", C = "week", p = "month", F = "quarter", Y = "year", T = "date", N = "Invalid Date", G = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, Q = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, K = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, P = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, X = {
      s: P,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), s = r % 60;
        return (e <= 0 ? "+" : "-") + P(n, 2, "0") + ":" + P(s, 2, "0");
      },
      m: function u(t, e) {
        if (t.date() < e.date()) return -u(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, p), s = e - n < 0, i = t.clone().add(r + (s ? -1 : 1), p);
        return +(-(r + (e - n) / (s ? n - i : i - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: p,
          y: Y,
          w: C,
          d: b,
          D: T,
          h: k,
          m: _,
          s: l,
          ms: S,
          Q: F
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, x = "en", H = {};
    H[x] = K;
    var J = "$isDayjsObject", q = function(t) {
      return t instanceof W || !(!t || !t[J]);
    }, I = function u(t, e, r) {
      var n;
      if (!t) return x;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        H[s] && (n = s), e && (H[s] = e, n = s);
        var i = t.split("-");
        if (!n && i.length > 1) return u(i[0]);
      } else {
        var o = t.name;
        H[o] = t, n = o;
      }
      return !r && n && (x = n), n || !r && x;
    }, d = function(t, e) {
      if (q(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new W(r);
    }, a = X;
    a.l = I, a.i = q, a.w = function(u, t) {
      return d(u, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var W = function() {
      function u(e) {
        this.$L = I(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[J] = !0;
      }
      var t = u.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, s = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (a.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var i = n.match(G);
            if (i) {
              var o = i[2] - 1 || 0, c = (i[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(i[1], o, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, c)) : new Date(i[1], o, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, c);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return a;
      }, t.isValid = function() {
        return this.$d.toString() !== N;
      }, t.isSame = function(e, r) {
        var n = d(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return d(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < d(e);
      }, t.$g = function(e, r, n) {
        return a.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, s = !!a.u(r) || r, i = a.p(e), o = function(g, $) {
          var w = a.w(n.$u ? Date.UTC(n.$y, $, g) : new Date(n.$y, $, g), n);
          return s ? w : w.endOf(b);
        }, c = function(g, $) {
          return a.w(n.toDate()[g].apply(n.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice($)), n);
        }, f = this.$W, m = this.$M, y = this.$D, v = "set" + (this.$u ? "UTC" : "");
        switch (i) {
          case Y:
            return s ? o(1, 0) : o(31, 11);
          case p:
            return s ? o(1, m) : o(0, m + 1);
          case C:
            var j = this.$locale().weekStart || 0, z = (f < j ? f + 7 : f) - j;
            return o(s ? y - z : y + (6 - z), m);
          case b:
          case T:
            return c(v + "Hours", 0);
          case k:
            return c(v + "Minutes", 1);
          case _:
            return c(v + "Seconds", 2);
          case l:
            return c(v + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, s = a.p(e), i = "set" + (this.$u ? "UTC" : ""), o = (n = {}, n[b] = i + "Date", n[T] = i + "Date", n[p] = i + "Month", n[Y] = i + "FullYear", n[k] = i + "Hours", n[_] = i + "Minutes", n[l] = i + "Seconds", n[S] = i + "Milliseconds", n)[s], c = s === b ? this.$D + (r - this.$W) : r;
        if (s === p || s === Y) {
          var f = this.clone().set(T, 1);
          f.$d[o](c), f.init(), this.$d = f.set(T, Math.min(this.$D, f.daysInMonth())).$d;
        } else o && this.$d[o](c);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[a.p(e)]();
      }, t.add = function(e, r) {
        var n, s = this;
        e = Number(e);
        var i = a.p(r), o = function(y) {
          var v = d(s);
          return a.w(v.date(v.date() + Math.round(y * e)), s);
        };
        if (i === p) return this.set(p, this.$M + e);
        if (i === Y) return this.set(Y, this.$y + e);
        if (i === b) return o(1);
        if (i === C) return o(7);
        var c = (n = {}, n[_] = M, n[k] = L, n[l] = D, n)[i] || 1, f = this.$d.getTime() + e * c;
        return a.w(f, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || N;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", i = a.z(this), o = this.$H, c = this.$m, f = this.$M, m = n.weekdays, y = n.months, v = n.meridiem, j = function($, w, A, tt) {
          return $ && ($[w] || $(r, s)) || A[w].slice(0, tt);
        }, z = function($) {
          return a.s(o % 12 || 12, $, "0");
        }, U = v || function(g, $, w) {
          var A = g < 12 ? "AM" : "PM";
          return w ? A.toLowerCase() : A;
        };
        return s.replace(Q, function(g, $) {
          return $ || function(w) {
            switch (w) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return a.s(r.$y, 4, "0");
              case "M":
                return f + 1;
              case "MM":
                return a.s(f + 1, 2, "0");
              case "MMM":
                return j(n.monthsShort, f, y, 3);
              case "MMMM":
                return j(y, f);
              case "D":
                return r.$D;
              case "DD":
                return a.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return j(n.weekdaysMin, r.$W, m, 2);
              case "ddd":
                return j(n.weekdaysShort, r.$W, m, 3);
              case "dddd":
                return m[r.$W];
              case "H":
                return String(o);
              case "HH":
                return a.s(o, 2, "0");
              case "h":
                return z(1);
              case "hh":
                return z(2);
              case "a":
                return U(o, c, !0);
              case "A":
                return U(o, c, !1);
              case "m":
                return String(c);
              case "mm":
                return a.s(c, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return a.s(r.$s, 2, "0");
              case "SSS":
                return a.s(r.$ms, 3, "0");
              case "Z":
                return i;
            }
            return null;
          }(g) || i.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var s, i = this, o = a.p(r), c = d(e), f = (c.utcOffset() - this.utcOffset()) * M, m = this - c, y = function() {
          return a.m(i, c);
        };
        switch (o) {
          case Y:
            s = y() / 12;
            break;
          case p:
            s = y();
            break;
          case F:
            s = y() / 3;
            break;
          case C:
            s = (m - f) / 6048e5;
            break;
          case b:
            s = (m - f) / 864e5;
            break;
          case k:
            s = m / L;
            break;
          case _:
            s = m / M;
            break;
          case l:
            s = m / D;
            break;
          default:
            s = m;
        }
        return n ? s : a.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(p).$D;
      }, t.$locale = function() {
        return H[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), s = I(e, r, !0);
        return s && (n.$L = s), n;
      }, t.clone = function() {
        return a.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, u;
    }(), Z = W.prototype;
    return d.prototype = Z, [["$ms", S], ["$s", l], ["$m", _], ["$H", k], ["$W", b], ["$M", p], ["$y", Y], ["$D", T]].forEach(function(u) {
      Z[u[1]] = function(t) {
        return this.$g(t, u[0], u[1]);
      };
    }), d.extend = function(u, t) {
      return u.$i || (u(t, W, d), u.$i = !0), d;
    }, d.locale = I, d.isDayjs = q, d.unix = function(u) {
      return d(1e3 * u);
    }, d.en = H[x], d.Ls = H, d.p = {}, d;
  });
})(E);
var V = E.exports;
const R = /* @__PURE__ */ rt(V);
var st = { exports: {} };
(function(h, O) {
  (function(D, M) {
    h.exports = M(V);
  })(B, function(D) {
    function M(l) {
      return l && typeof l == "object" && "default" in l ? l : {
        default: l
      };
    }
    var L = M(D), S = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(_) {
        return _ + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return L.default.locale(S, null, !0), S;
  });
})(st);
R.locale("pt-br");
function at(h = {}) {
  var S, l;
  const O = new URL(`${et()}/tours/search`), D = ((S = h.destinations) == null ? void 0 : S.map((_) => _.zoneId).join(",")) || "", M = ((l = h.destinations) == null ? void 0 : l.map((_) => _.description).join(",")) || "", L = h.date ? R(h.date).format("YYYY-MM") : "";
  return L && O.searchParams.append("date", L), D && O.searchParams.append("zoneIds", D), M && O.searchParams.append("descriptions", M), h.allDestiny && O.searchParams.append("allDestiny", String(h.allDestiny)), nt(O);
}
export {
  R as d,
  at as f
};
