import e from "react";
import { Pesqinc as p } from "@cvccorp-engines/components";
import { LocationContainer as c } from "./styled.js";
const u = (t) => {
  const {
    label: r,
    options: o,
    value: l,
    placeholder: n,
    onSelect: i,
    inputProps: s,
    error: a
  } = t;
  return /* @__PURE__ */ e.createElement(c, null, /* @__PURE__ */ e.createElement(p, { value: l, placeholder: n, onSelect: i, error: a, label: r, options: o, inputProps: {
    ...s,
    inputAttributes: {
      readOnly: !0
    },
    style: {
      userSelect: "none",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  } }));
};
u.displayName = "SingleResorts";
export {
  u as default
};
