import ChuiDate from "@cvccorp-components/chui-react-components/lib/chuiDate";
import { calcAge, type Room } from "@cvccorp-engines/pax";

function validateChildrenPaxDate(
    pax?: Room[],
    endDate?: string,
): [hasErros: boolean, erros: Record<string, boolean | string>] {
    let hasError = false;

    const errorsPax =
        pax?.reduce(
            (prev, curr, index) => {
                curr.childrenDateOfBirth.forEach((date, indexDate) => {
                    const dateOfBirth = ChuiDate(date);
                    if (!dateOfBirth.isValid()) {
                        prev[`${String(index)}-${String(indexDate)}`] = "Informe a data de nascimento";
                        hasError = true;
                        return;
                    }

                    if (endDate && new Date(date).getTime() > new Date(endDate).getTime()) {
                        prev[`${String(index)}-${String(indexDate)}`] = "Data é posterior ao fim da viagem*";
                        hasError = true;
                        return;
                    }

                    const age = calcAge(date, endDate);
                    if (age > 17 || age < 0) {
                        hasError = true;
                        prev[`${String(index)}-${String(indexDate)}`] = "Idade não está entre 0 e 17 anos*";
                    }
                });
                return prev;
            },
            {} as Record<string, boolean | string>,
        ) ?? {};

    return [hasError, errorsPax];
}

export default validateChildrenPaxDate;
