import t from "@emotion/styled";
const p = t.div`
    padding: 0px;
    /* container-type: inline-size; */

    fieldset {
        > div {
            min-height: 56px;
            > input {
                padding: 20px 8px 15px;
            }
        }
    }

    .chui-input__label {
        top: unset !important;
        font-size: 0.875rem !important;
        line-height: 100% !important;
        position: absolute !important;
        transform: unset !important;
    }

    .chui-input__addon--before--wrapper input {
        padding-top: 7px;
        color: #000;
    }

    @container (min-width: 1024px) {
        display: flex;

        > div {
            width: 100%;
        }
    }

    @container (max-width: 540px) {
        .chui-autocomplete-tag__wrapper:has(.chui-autocomplete-tag__item) {
            padding-block: 10px;
        }
    }
`;
export {
  p as LocationContainer
};
