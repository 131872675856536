import n, { useCallback as X, Fragment as L } from "react";
import { SearchButton as z, OptionsBar as Z } from "@cvccorp-engines/components";
import ee from "@cvccorp-components/chui-react-components/components/Button";
import te from "@cvccorp-components/chui-react-components/components/Checkbox";
import ne from "@cvccorp-components/chui-react-components/components/Divider";
import N from "@cvccorp-components/chui-react-components/components/Grid";
import $ from "@cvccorp-components/chui-react-components/hooks/useMediaQuery";
import { _ as re, a as j, e as ie, l as oe } from "./PesqincFight-Cy3sxUzZ.js";
import { useTheme as ae } from "@emotion/react";
import { d as se, E as le } from "./EngineFlightContext-7MpSiu-w.js";
import { useFlightStore as m } from "./store/FlightStore.js";
import { AddDestinationButtonWrapper as ce, MotorContainer as me, buttonContainer as ue } from "./styled.js";
import de from "./components/DefaultLocationForm/index.js";
import ge from "./context/useEngineFlight.js";
import { FlightOptions as x } from "./store/FlightStore.types.js";
import he from "./hooks/useMergedMultDestination.js";
import fe from "./components/MultiDestinationsForm/MultiDestinationRow.js";
var pe = ["size", "className"], I = function(a) {
  var s, c, v = a.size, f = v === void 0 ? "medium" : v, p = a.className, E = p === void 0 ? "" : p, d = re(a, pe), r = d, y = ["chui-icon", E].join(" "), h = r.color || "#000014", O = j({}, d, {
    color: void 0
  }), C = ie[f] || oe[f];
  return /* @__PURE__ */ n.createElement("svg", j({
    xmlns: "http://www.w3.org/2000/svg",
    fill: h,
    viewBox: "0 0 24 24",
    width: (s = d.width) != null ? s : C,
    height: (c = d.height) != null ? c : C,
    className: y
  }, O), /* @__PURE__ */ n.createElement("path", {
    fill: void 0,
    d: "M11.47 21.77a.75.75 0 0 0 1.28-.55v-8.48h8.48a.75.75 0 1 0 0-1.5h-8.48V2.76a.75.75 0 1 0-1.5 0v8.48H2.77a.75.75 0 1 0 0 1.5h8.48v8.5c0 .199.079.39.22.53"
  }));
};
I.displayName = "FilledAdd";
function P({
  errors: i
}) {
  const {
    name: a
  } = ae(), s = he(), c = $("(max-width: 962px)"), v = $("(max-width: 720px)"), {
    multidestinations: f,
    addMultdestinations: p,
    calendarDates: E,
    removeMultdestinations: d,
    setCalendarDates: r,
    clearDates: y,
    setMultiDestination: h,
    setDestination: O,
    setOrigin: C,
    backToFirstOrigin: A,
    setBackToFirstOrigin: G,
    origin: F
  } = m(), W = (e = !1) => /* @__PURE__ */ n.createElement(n.Fragment, null, e ? null : /* @__PURE__ */ n.createElement(n.Fragment, null, v && /* @__PURE__ */ n.createElement(ne, { color: "gray-light", size: "sm", style: {
    marginBlock: 24
  } }))), V = X(d, [d]), H = (e) => {
    var t;
    return e === -1 ? S(E) : S((t = f[e]) == null ? void 0 : t.calendarDates).sort((o, l) => o > l ? 0 : -1);
  };
  function K(e, t) {
    if (!e) return;
    const [o, l] = Array.isArray(e) ? e : [e];
    if (t === -1) return r({
      startDate: o,
      endDate: l
    });
    h(t, {
      calendarDates: {
        startDate: o,
        endDate: l
      }
    });
  }
  function B(e, t) {
    if (t === -1) return O(e);
    h(t, {
      destination: e
    });
  }
  function Q(e, t) {
    if (t === -1) return C(e);
    h(t, {
      origin: e
    });
  }
  function U(e, t, o) {
    if (e === -1) {
      C(t), O(o);
      return;
    }
    h(e, {
      origin: t,
      destination: o
    });
  }
  function Y(e) {
    if (e === -1) return y();
  }
  function q(e) {
    var t;
    return e === -1 ? i : (t = i == null ? void 0 : i.multidestinations) == null ? void 0 : t[e];
  }
  function J(e) {
    var u;
    const t = Object.entries(f ?? []);
    if (!(t != null && t.length)) return;
    const [o, l] = t.reverse()[0];
    e && !(l != null && l.destination) ? B(F, +o) : ((u = l.destination) == null ? void 0 : u.mastercode) === (F == null ? void 0 : F.mastercode) && B(void 0, +o), G(e);
  }
  const _ = (e, t = () => !0, o = (u) => !1, l = !0) => {
    var b, w;
    if (!t()) return;
    let u;
    const k = l ? -1 : 1;
    let D = e + k;
    for (; o(D) && !u; )
      u = (w = (b = s[D]) == null ? void 0 : b.calendarDates) == null ? void 0 : w.startDate, D = D + k;
    return u;
  };
  return /* @__PURE__ */ n.createElement(L, null, /* @__PURE__ */ n.createElement(N, { container: !0, spacing: 0 }, /* @__PURE__ */ n.createElement(N, { item: !0, xs: 12 }, s.map((e, t, o) => {
    var k, D, b, w;
    const l = _(t, () => t > 0, (g) => g >= 0), u = _(t, () => t !== s.length - 1, (g) => g < s.length, !1);
    return /* @__PURE__ */ n.createElement(L, { key: (k = e.origin) == null ? void 0 : k.mastercode }, /* @__PURE__ */ n.createElement(N, { item: !0, xs: 12, style: {
      marginBlockEnd: 12
    }, key: [(D = e.calendarDates) == null ? void 0 : D.startDate, (b = e.destination) == null ? void 0 : b.mastercode, (w = e.origin) == null ? void 0 : w.mastercode].join("|") }, /* @__PURE__ */ n.createElement(fe, { index: t + 1, isLast: t === o.length - 1, destinationKey: e.key, hidePax: t !== o.length - 1, dates: H(e.key), handleRemove: o.length > 1 ? V : void 0, errors: q(e.key), onDateChange: (g) => K(g, e.key), onDateClear: () => Y(e.key), min: l ? new Date(l) : void 0, max: u ? new Date(u) : void 0, destination: e.destination, origin: e.origin, onDestinationChange: (g) => B(g, e.key), onOriginChange: (g) => Q(g, e.key), onReverse: () => U(e.key, e.destination, e.origin) })), W(t === o.length - 1));
  }), /* @__PURE__ */ n.createElement(N, { item: !0, xs: 12, style: {
    paddingBlockEnd: 16
  } }, /* @__PURE__ */ n.createElement(ce, null, /* @__PURE__ */ n.createElement(ee, { fullWidth: c, icon: /* @__PURE__ */ n.createElement(I, null), variant: "outline", color: a === "cvc" ? "secondary" : "primary", type: "button", onClick: () => {
    var e;
    p({
      origin: (e = s[s.length - 1]) == null ? void 0 : e.destination,
      destination: A ? F : void 0
    });
  }, size: "md" }, "Adicionar voo"), /* @__PURE__ */ n.createElement(te, { checked: A, onChange: J, label: "Voltar para a primeira origem", color: "primary", size: "md" })))), /* @__PURE__ */ n.createElement(N, { item: !0, xs: 12, justifyContent: "flex-end", style: {
    display: "flex",
    paddingBlockStart: 16,
    borderTop: "1px solid gray"
  } }, /* @__PURE__ */ n.createElement(z, { "aria-label": "Buscar voo", style: c ? void 0 : {
    minWidth: 184
  } }))));
}
P.displayName = "MultiDestinationsForm";
function M() {
  const i = m(({
    origin: r
  }) => r), a = m(({
    setOrigin: r
  }) => r), s = m(({
    reverse: r
  }) => r), c = m(({
    destination: r
  }) => r), v = m(({
    setDestination: r
  }) => r), f = m(({
    calendarDates: r
  }) => r), p = m(({
    clearDates: r
  }) => r), E = m(({
    errors: r
  }) => r), d = m(({
    setCalendarDates: r
  }) => r);
  return /* @__PURE__ */ n.createElement(me, null, /* @__PURE__ */ n.createElement(de, { errors: E, origin: i, destination: c, onDestinationChange: v, onOriginChange: a, dates: S(f), onReverse: s, onDateClear: p, onDateChange: (r) => {
    if (!r) return p();
    const [y, h] = Array.isArray(r) ? r : [r];
    d({
      startDate: y,
      endDate: h
    });
  }, min: se().add(1, "d").toDate() }, /* @__PURE__ */ n.createElement(ue, null, /* @__PURE__ */ n.createElement(z, { "aria-label": "Buscar voo" }, "Buscar"))));
}
M.displayName = "SingleDestinationForm";
const De = {
  multidestinations: P,
  oneway: M,
  roundtrip: M
};
function S(i) {
  const a = [];
  return i != null && i.startDate && a.push(new Date(i.startDate)), i != null && i.endDate && a.push(new Date(i.endDate)), a;
}
function R() {
  const i = m((c) => c.choice), a = m((c) => c.errors), s = De[i];
  return /* @__PURE__ */ n.createElement(s, { errors: a });
}
R.displayName = "EngineFlightForms";
const ve = [{
  value: x.GOING_AND_BACK,
  label: "Ida e volta"
}, {
  value: x.ONLY_GOING,
  label: "Só ida"
}, {
  value: x.MULTIPLE_DESTINATION,
  label: "Vários destinos"
}], T = () => {
  const {
    handleSearch: i
  } = ge(), {
    choice: a,
    setChoice: s
  } = m();
  return /* @__PURE__ */ n.createElement("form", { onSubmit: (c) => {
    c.preventDefault(), i();
  } }, /* @__PURE__ */ n.createElement(Z, { options: ve, value: a, onChange: s }), /* @__PURE__ */ n.createElement(R, null));
};
T.displayName = "EngineFlightContent";
function Ee() {
  return /* @__PURE__ */ n.createElement(le, null, /* @__PURE__ */ n.createElement(T, null));
}
Ee.displayName = "EngineFlight";
export {
  Ee as E,
  P as M,
  M as S,
  S as g
};
