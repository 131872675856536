/* eslint-disable quotes */
import styled from "@emotion/styled";

export const MotorContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    /* padding: 30px 16px 40px; */
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 1fr 120px;
        gap: 12px;
    }
`;
export const buttonContainer = styled.div`
    margin-left: ${({ theme }) => theme.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`;

export const AddDestinationButtonWrapper = styled.div`
    padding-inline-start: 40px;
    @container (max-width: 898px) {
        padding-inline-start: 0px;
    }
`;

export const DestinationCircle = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: ${({ theme }: any) => theme.colors.brand.primary[500]};
`;

export const MultiDestinationWrapper = styled.div<{
    index?: number;
    isLast?: boolean;
}>`
    .destination {
        position: relative;
        left: 0;
        text-align: center;
        display: flex;
        align-items: top;
        justify-content: center;
        height: 100%;
        transform: translateY(16px);
        ::before {
            content: ${({ index }) => `"${index}"`};
            position: absolute;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: ${({ theme }: any) => theme.colors.brand.primary[500]};
        }
        ::after {
            content: ${({ isLast }) => (!isLast ? '""' : undefined)};
            height: calc(100% + 24px + 12px);
            z-index: 0;
            width: 3px;
            background-color: ${({ theme }) => theme.colors.brand.primary[500]};
        }

        @container (max-width: 688px) {
            display: none;
        }
    }
    display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 10px;

    @container (max-width: 898px) {
        margin-bottom: ${({ isLast }) => (isLast ? 16 : 32)}px;
    }

    @container (max-width: 688px) {
        grid-template-columns: 1fr;
    }
`;

export const LocationAndDateContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    column-gap: 12px;
    row-gap: 8px;
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 3fr 4fr;
        /* column-gap: ${({ theme }) => theme.spacing.size[8]}; */
    }
`;

export const LocationAndDateWrapper = styled.div<{ isMult?: boolean }>(({ isMult = false }) => {
    return {
        display: "grid",
        gridTemplateColumns: isMult ? "1fr" : "1fr 1fr",
        rowGap: 8,
        alignItems: "flex-start",
        gap: 12,
        width: "100%",
        ".date-picker": {
            display: "flex",
            alignItems: "flex-start",
            gap: 8,
            width: "100%",
        },
        ".css-1kl7p8h": {
            width: "100%",
        },
        "@container (min-width: 899px)": {
            gridTemplateColumns: "1.5fr 1fr",
        },

        "@container (max-width: 688px)": {
            gridTemplateColumns: "1fr",
        },
    };
});
