import e from "@emotion/styled";
const i = e.div(({
  isMult: r = !1
}) => ({
  display: "grid",
  gridTemplateColumns: r ? "1fr" : "1fr 1fr",
  rowGap: 8,
  alignItems: "top",
  gap: 12,
  width: "100%",
  ".date-picker": {
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: "100%"
  },
  "@container (min-width: 899px)": {
    gridTemplateColumns: "1fr 1fr"
  },
  "@container (max-width: 688px)": {
    gridTemplateColumns: "1fr"
  }
}));
export {
  i as LocationAndDateWrapper
};
