import { getParams as t } from "@cvccorp-engines/components";
function l() {
  const n = parseInt(t("Adults") || "0", 10), o = parseInt(t("Children") || "0", 10), e = t("CheckIn") || "", s = t("CheckOut") || "", r = parseInt(t("Rooms") || "0", 10), c = decodeURI(t("Location") || ""), a = t("ZoneId") || "";
  return {
    adult: n,
    children: o,
    origDate: e,
    destDate: s,
    room: r,
    destDescription: c,
    destZoneId: a,
    durationUnit: "Milliseconds"
  };
}
export {
  l as default
};
