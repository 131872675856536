import e from "../../libs/fetcher.js";
import i from "../../utils/QueryString.js";
const c = async (o) => {
  var r;
  try {
    const {
      data: t
    } = await e.get("https://locations-api.services.cvc.com.br/v1/locations", {
      params: {
        q: i.text(o).toString(),
        owner: "cars",
        strategy: "iataDrive",
        type: "CITY"
      }
    });
    return {
      meta: t.meta,
      locations: ((r = t.locations) == null ? void 0 : r.filter(({
        officeHours: a
      }) => a.length)) || []
    };
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  c as getCarsLocations
};
