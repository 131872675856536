import { create as o } from "zustand";
import { getHotelPaxParam as n, getHotelStartDateParam as l, getHotelEndDateParam as d } from "../utils/getHotelParams.js";
const t = {
  destination: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  pax: !1
}, D = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], x = o((e) => ({
  pax: n(),
  calendarDates: {
    startDate: l(),
    endDate: d()
  },
  destination: void 0,
  errors: t,
  errorsPax: {},
  openPax: !1,
  setDestination: (a) => e((r) => {
    var s;
    return {
      destination: a,
      inputFocus: (s = r.calendarDates) != null && s.startDate ? "pax" : "dates",
      errors: {
        ...r.errors,
        destination: t.destination
      }
    };
  }),
  setCalendarDates: (a) => e((r) => ({
    calendarDates: a,
    inputFocus: "pax",
    errors: {
      ...r.errors,
      calendarDates: t.calendarDates
    }
  })),
  setPax: (a) => e((r) => ({
    pax: a,
    errorsPax: {},
    errors: {
      ...r.errors,
      pax: !1
    }
  })),
  setErrors: (a) => e(() => ({
    errors: a
  })),
  setOpenPax: (a) => e(() => ({
    openPax: a
  })),
  clearPax: () => e((a) => ({
    pax: D,
    errors: {
      ...a.errors,
      pax: !1
    },
    errorsPax: {}
  })),
  clearDates: () => e((a) => ({
    calendarDates: {
      endDate: void 0,
      startDate: void 0
    },
    errors: {
      ...a.errors,
      calendarDates: t.calendarDates
    }
  }))
}));
export {
  D as defaultHotelPax,
  x as useHotelStore
};
