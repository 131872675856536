import type { CalendarDates } from "@cvccorp-engines/components";
import type { Room } from "@cvccorp-engines/pax";
import { create } from "zustand";
import {
    getPackageDestinationInitialValue,
    getPackageEndDateInitialValue,
    getPackageOriginInitialValue,
    getPackagePaxInitialValue,
    getPackageStartDateInitialValue,
} from "../../engine-package/utils/getPackageInitialValue";

export interface IPackageValuesErrors {
    destination?: boolean;
    origin?: boolean;
    calendarDates?: Record<keyof CalendarDates, boolean>;
    pax?: boolean;
}

export interface IPackageLocation {
    iata: string;
    description: string;
    zoneId: number;
    city: string;
    state: string;
    country: string;
    latitude: number;
    longitude: number;
    type: string;
}

export type PackageStoreState = {
    origin?: IPackageLocation;
    destination?: IPackageLocation;
    calendarDates?: CalendarDates;
    pax?: Room[];
    childrenDateOfBirth?: string[];
    errors?: IPackageValuesErrors;
    errorsPax?: {
        [roomIndexAndchildrenBirthDayIndex: string]: string | boolean;
    };
    openPax: boolean;
};

export type PackageStatePersisted = Pick<PackageStoreState, "destination" | "origin" | "pax" | "calendarDates">;

type Actions = {
    setOrigin: (origin: PackageStoreState["origin"]) => void;
    setDestination: (destination: PackageStoreState["destination"]) => void;
    setCalendarDates: (calendarDates: PackageStoreState["calendarDates"]) => void;
    setPax: (pax: PackageStoreState["pax"]) => void;
    setErrors: (errors: PackageStoreState["errors"]) => void;
    setErrorsPax: (errorsPax: PackageStoreState["errorsPax"]) => void;
    setOpenPax: (openPax: PackageStoreState["openPax"]) => void;
    resetTempPax: () => void;
    clearDates: () => void;
    clearPax: () => void;
    reverse: () => void;
};

const defaultErros = {
    destination: false,
    origin: false,
    calendarDates: { endDate: false, startDate: false },
    pax: false,
};

export const defaultPackagePax = [
    {
        adult: 2,
        children: 0,
        childrenDateOfBirth: [],
    },
];

export const usePackageStore = create<PackageStoreState & Actions>(set => ({
    pax: getPackagePaxInitialValue(),
    calendarDates: {
        startDate: getPackageStartDateInitialValue(),
        endDate: getPackageEndDateInitialValue(),
    },
    origin: getPackageOriginInitialValue(),
    destination: getPackageDestinationInitialValue(),
    errors: defaultErros,
    errorsPax: {},
    openPax: false,
    setDestination: destination =>
        set(state => ({
            destination,
            inputFocus: state.calendarDates?.startDate ? "pax" : "dates",
            errors: { ...state.errors, destination: defaultErros.destination },
        })),
    setOrigin: origin =>
        set(state => ({
            origin,
            inputFocus: state.destination ? (state.calendarDates?.startDate ? "pax" : "dates") : "destination",
            errors: { ...state.errors, origin: defaultErros.origin },
        })),
    setCalendarDates: calendarDates =>
        set(state => ({
            calendarDates: calendarDates,
            inputFocus: "pax",
            errors: { ...state.errors, calendarDates: defaultErros.calendarDates },
        })),
    setPax: pax =>
        set(s => ({
            pax,
            errorsPax: {},
            errors: { ...s.errors, pax: false },
        })),
    setErrors: errors => set(() => ({ errors: errors })),
    setErrorsPax: errorsPax => set(() => ({ errorsPax: errorsPax })),
    setOpenPax: openPax => set(() => ({ openPax: openPax })),
    resetTempPax() {
        return set(s => {
            return {
                errorsPax: {},
                errors: { ...s.errors, pax: false },
            };
        });
    },
    clearPax: () =>
        set(s => ({
            pax: defaultPackagePax,
            errors: { ...s.errors, pax: false },
            errorsPax: {},
        })),
    clearDates: () =>
        set(state => ({
            calendarDates: { endDate: undefined, startDate: undefined },
            errors: { ...state.errors, calendarDates: defaultErros.calendarDates },
        })),
    reverse() {
        return set(state => ({
            destination: state.origin,
            origin: state.destination,
            errors: {
                ...state.errors,
                destination: state.errors?.origin,
                origin: state.errors?.destination,
            },
        }));
    },
}));
