import { create } from "zustand";

export enum OptionHotelsResortsBarValue {
    HOTELS = "hotels",
    RESORTS = "resorts",
}

export type HotelsResortsStoreState = {
    choice: OptionHotelsResortsBarValue | string;
};

type Actions = {
    setChoice: (choice: string) => void;
};

export const useHotelsResortsStore = create<HotelsResortsStoreState & Actions>(set => ({
    choice: OptionHotelsResortsBarValue.HOTELS,

    setChoice: (choice: string) => set(() => ({ choice })),
}));
