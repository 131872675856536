import v, { createContext as x, useState as d, useEffect as i, useCallback as l, useMemo as m, useContext as P } from "react";
import { DEFAULT_TRAVEL_INSURANCE as f } from "../../../constants/defaultValues.js";
const a = x({});
function T(c) {
  const {
    children: u,
    travelers: e
  } = c, [n, r] = d(e);
  i(() => {
    r({
      ...e
    });
  }, [e]);
  const o = l((t) => {
    r({
      ...t
    });
  }, []), s = l(() => {
    const t = e ? {
      ...e
    } : {
      ...f
    };
    r(t);
  }, [e]), C = m(() => ({
    travelers: n,
    onChangeTraveler: o,
    onClearTraveler: s
  }), [o, s, n]);
  return /* @__PURE__ */ v.createElement(a.Provider, { value: C }, u);
}
T.displayName = "PaxProvider";
const p = a.Consumer;
function A() {
  return P(a);
}
export {
  p as PaxConsumer,
  T as default,
  A as usePax
};
