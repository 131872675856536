import { getBaseHostname as v, generateSearchParams as z, formatDateToParam as E, encodeSearchURL as F } from "@cvccorp-engines/components";
import { calcChildAges as G } from "@cvccorp-engines/pax";
function M(t) {
  var a, i, c, e, r, s, d, g, y, h, l, u, D, C, f, S, O, x, I, L, m, A, P, R, T, U, j, N, k, w, B, H, $;
  const o = new URL(`${v()}/pacote-estatico/search`), b = G(t.pax), q = z({
    Rooms: String((a = t.pax) == null ? void 0 : a.length),
    Adults: (c = (i = t.pax) == null ? void 0 : i.map) == null ? void 0 : c.call(i, (n) => n.adult).join(","),
    Children: (r = (e = t.pax) == null ? void 0 : e.map) == null ? void 0 : r.call(e, (n) => n.children).join(","),
    ChildAges: b,
    IataOri: ((s = t.origin) == null ? void 0 : s.iata) || ((y = (g = (d = t.origin) == null ? void 0 : d.city) == null ? void 0 : g.state) == null ? void 0 : y.shortName),
    OriginId: (h = t.origin) == null ? void 0 : h.id,
    OriginDesc: (l = t.origin) == null ? void 0 : l.description,
    CityOrigin: (u = t.origin) == null ? void 0 : u.city.name,
    StateOrigin: (f = (C = (D = t.origin) == null ? void 0 : D.city) == null ? void 0 : C.state) == null ? void 0 : f.name,
    CountryOrigin: (I = (x = (O = (S = t.origin) == null ? void 0 : S.city) == null ? void 0 : O.state) == null ? void 0 : x.country) == null ? void 0 : I.name,
    IataDes: ((L = t.destination) == null ? void 0 : L.iata) || ((P = (A = (m = t.destination) == null ? void 0 : m.city) == null ? void 0 : A.state) == null ? void 0 : P.shortName),
    DestinationId: (R = t.destination) == null ? void 0 : R.id,
    DestinationDesc: (T = t.destination) == null ? void 0 : T.description,
    DestinationLat: "",
    DestinationLon: "",
    City: (U = t.destination) == null ? void 0 : U.city.name,
    State: (k = (N = (j = t.destination) == null ? void 0 : j.city) == null ? void 0 : N.state) == null ? void 0 : k.name,
    Country: ($ = (H = (B = (w = t.destination) == null ? void 0 : w.city) == null ? void 0 : B.state) == null ? void 0 : H.country) == null ? void 0 : $.name,
    Classes: "eco",
    Direct: "false",
    CheckIn: E(t.calendarDates),
    Type: "destino"
  });
  return o.search = q.toString(), F(o);
}
export {
  M as default
};
