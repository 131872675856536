import { getTravelSecurityLocations as i } from "@cvccorp-engines/api";
import { getParams as n } from "@cvccorp-engines/components";
const m = async () => {
  const t = n("Location") ?? "";
  return t ? (await i(t)).locations.find((e) => e.description === t) ?? void 0 : void 0;
}, h = () => {
  const t = n("CheckIn");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, p = () => {
  const t = n("CheckOut");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, I = () => {
  const t = n("paxs");
  if (!t) return {
    elderly: 0,
    adult: 1,
    children: 0
  };
  const a = t.split(",").map(Number);
  let r = 0, e = 0, c = 0;
  return a.forEach((o) => {
    o < 17 ? c++ : o > 18 && o < 69 ? e++ : o > 69 && r++;
  }), {
    elderly: r,
    adult: e,
    children: c
  };
}, f = () => {
  const t = Number(n("Adults") || 0), a = Number(n("Children") || 0), r = n("CheckIn") || "", e = n("CheckOut") || "", c = Number(n("Rooms") || 0), s = n("Location") || "", o = n("ZoneId") || "";
  return {
    adult: t,
    children: a,
    origDate: r,
    destDate: e,
    room: c,
    destDescription: s,
    destZoneId: o,
    durationUnit: "Milliseconds"
  };
};
export {
  m as getTravelInsuranceDestinationParam,
  p as getTravelInsuranceEndDateParam,
  f as getTravelInsuranceParamsForLogs,
  I as getTravelInsurancePaxParam,
  h as getTravelInsuranceStartDateParam
};
