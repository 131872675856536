import e from "react";
import r from "./components/Form/Form.js";
import n from "./context/EngineCarsContext.js";
function t() {
  return /* @__PURE__ */ e.createElement(n, null, /* @__PURE__ */ e.createElement(r, null));
}
t.displayName = "EngineCar";
export {
  t as default
};
