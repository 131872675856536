import t from "react";
import { SearchButton as l } from "@cvccorp-engines/components";
import { useMotorToursContext as u } from "../../context/EngineToursContext.js";
import { useToursStore as c } from "../../store/ToursStore.js";
import D from "../ToursDefaultLocationForm/ToursDefaultLocationForm.js";
import { MotorContainer as f, ButtonContainer as p } from "./styled.js";
function C() {
  const {
    handleSearch: e
  } = u(), {
    destinations: o,
    date: n,
    allDestiny: r,
    errors: a,
    setAllDestiny: i,
    setDestinations: s,
    setDate: m
  } = c();
  return /* @__PURE__ */ t.createElement(f, { twoItems: !0 }, /* @__PURE__ */ t.createElement(D, { date: n, errors: a, allDestiny: r, destinations: o, onDateChange: m, onAllDestinyChange: i, onDestinationsChange: s }, /* @__PURE__ */ t.createElement(p, null, /* @__PURE__ */ t.createElement(l, { onClick: e }))));
}
C.displayName = "SingleDestinationForm";
export {
  C as default
};
