import { Typography as p } from "@cvccorp-components/chui-react-components";
import i from "@emotion/styled";
const e = i.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 600px) {
        gap: 4px;
    }
`, o = i(p)`
    @media (max-width: 600px) {
        display: none;
    }
`;
export {
  e as Container,
  o as Subtitle
};
