import { useContext as o } from "react";
import { EngineHotelContext as t } from "./EngineHotelContext.js";
function i() {
  const e = o(t);
  if (!e) throw new Error("Chamada do hook useEngineHotel inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineHotelContext");
  return e;
}
export {
  i as default
};
