import { type ReactNode, useMemo, useState } from "react";
import Autocomplete from "@cvccorp-components/chui-react-components/components/Autocomplete";
import OutlinePlaneRight from "@cvccorp-components/chui-react-icons/icons/outline/product/OutlinePlaneRight";
import OutlinePinMap from "@cvccorp-components/chui-react-icons/icons/outline/service/OutlinePinMap";
import { pesqIncService } from "@cvccorp-engines/api";
import { useError, getParams, Pesqinc } from "@cvccorp-engines/components";
import { IPackageLocation } from "../../Context/PackageStore";
import type { SinglePackageProps, SinglePackagePropsOption } from "./SinglePackage.types";

const icons: Record<string, ReactNode> = {
    AIRPORT: <OutlinePlaneRight />,
    CITY: <OutlinePinMap />,
};

const SinglePackage = (props: SinglePackageProps) => {
    const {
        error,
        value,
        placeholder,
        supportText,
        inputProps,
        label = "Destino",
        supportTextProps,
        onClear,
        onSelect,
    } = props;
    const [options, setOptions] = useState<SinglePackagePropsOption[]>(() => []);
    const packageGroup = getParams("packageGroup") ? String(getParams("packageGroup")).toUpperCase() : "PACKAGE";
    const { throwError } = useError();

    const handleSearch = async (searchString: string) => {
        const params: any = {
            productType: "package",
            packageGroup,
            operation: "location",
            player: null,
            errorResponse: error || {},
        };

        try {
            if (!searchString) return [];
            const searchText = searchString.replace(/ /g, "+");
            const data = await pesqIncService("PAC", searchText);

            const formattedLocations = data?.locations.filter(({ IATA }) => Boolean(IATA.trim()));

            const location = formattedLocations.map(item => {
                return {
                    label: item.description,
                    value: {
                        iata: item.IATA,
                        description: item.description,
                        zoneId: item.masterCode,
                        city: item.city,
                        state: item.state,
                        country: item.country,
                        latitude: item.latitude ?? 0,
                        longitude: item.longitude ?? 0,
                        type: item.type,
                    },
                };
            });

            if (data?.locations.length === 0) {
                Object.assign(params, {
                    _message: `EMPTYRESULT ${searchString}`,
                });
                throwError(params);
            }
            setOptions(location);
        } catch (err: any) {
            Object.assign(params, {
                _message: `ERROR ${searchString}`,
                errorResponse: { error: err.message },
            });

            throwError(params);
            return [];
        }
    };

    const optionsWithValue = useMemo(() => {
        const tempOptions = options;
        if (value) {
            tempOptions.push({
                value,
                label: value.description,
            });
        }
        return tempOptions;
    }, [options, value]);

    return (
        <Pesqinc
            label={label}
            supportText={supportText}
            supportTextProps={supportTextProps}
            error={error}
            placeholder={placeholder || label}
            onSearch={handleSearch}
            onSelect={(selected?: IPackageLocation) => {
                if (!selected) {
                    onClear ? onClear?.() : onSelect?.(value);
                    return;
                }
                onSelect(selected);
            }}
            options={optionsWithValue}
            value={value}
            renderOption={({ option }) => {
                const { type, iata } = option.value;

                return (
                    <Autocomplete.Option
                        fullWidth
                        data-iata={iata}
                        supportText={option.label}
                        label={option.label}
                        onSelect={() => onSelect(option.value)}
                        addonBefore={icons[type]}
                    />
                );
            }}
            inputProps={inputProps}
        />
    );
};

export default SinglePackage;
