"use client";
import e from "react";
import { SearchButton as r } from "@cvccorp-engines/components";
import o from "../../context/useEngineCarContext.js";
import a from "../LocationAndDate/LocationAndDate.js";
import { MotorContainer as i, ButtonContainer as m } from "./Form.styled.js";
function l() {
  const {
    handleSearch: t
  } = o();
  return /* @__PURE__ */ e.createElement(i, { onSubmit: (n) => {
    n.preventDefault(), t();
  } }, /* @__PURE__ */ e.createElement(a, null), /* @__PURE__ */ e.createElement(m, null, /* @__PURE__ */ e.createElement(r, { "aria-label": "Buscar carros" })));
}
l.displayName = "SingleDestinationForm";
export {
  l as default
};
