import { getParams } from "@cvccorp-engines/components";
import type { Room } from "@cvccorp-engines/pax";
import type { IPackageLocation } from "../../shared/Context/PackageStore";

export const getPackageOriginInitialValue = (): IPackageLocation | undefined => {
    const origin: IPackageLocation = {
        iata: getParams("IataOri") ?? "",
        description: getParams("OriginDesc") ?? "",
        zoneId: Number(getParams("ZoneIdOrigin") || 0),
        city: getParams("CityOrigin") ?? "",
        state: getParams("StateOrigin") ?? "",
        country: getParams("CountryOrigin") ?? "",
        latitude: 0,
        longitude: 0,
        type: "",
    };

    return origin.description ? origin : undefined;
};

export const getPackageDestinationInitialValue = (): IPackageLocation | undefined => {
    const destination: IPackageLocation = {
        iata: getParams("IataDes") ?? "",
        description: getParams("DestinationDesc") ?? "",
        latitude: Number(getParams("DestinationLat") || 0),
        longitude: Number(getParams("DestinationLon") || 0),
        zoneId: Number(getParams("ZoneId") || 0),
        city: getParams("City") ?? "",
        state: getParams("State") ?? "",
        country: getParams("Country") ?? "",
        type: "",
    };

    return destination.description ? destination : undefined;
};

export const getPackageStartDateInitialValue = () => {
    const checkIn = getParams("CheckIn");
    return !checkIn ? "" : (checkIn.concat("T03:00:00.000Z") ?? "");
};

export const getPackageEndDateInitialValue = () => {
    const checkOut = getParams("CheckOut");
    return !checkOut ? "" : (checkOut.concat("T03:00:00.000Z") ?? "");
};

export const getPackagePaxInitialValue = (): Room[] => {
    const rooms = getParams("Rooms");
    const adults = getParams("Adults");
    const childrens = getParams("Children");
    const childrensAges = getParams("ChildAges");

    const pax = [];

    if (!rooms)
        return [
            {
                adult: 2,
                children: 0,
                childrenDateOfBirth: [],
            },
        ];

    for (let i = 0; i < Number(rooms); i++) {
        const adultsArr = adults?.split(",");
        const childrensArr = childrens?.split(",");
        const childrensAgesArr = childrensAges?.split(";");

        const room = {
            adult: Number(adultsArr?.[i] || 0),
            children: Number(childrensArr?.[i] || 0),
            childrenDateOfBirth:
                childrensAgesArr?.[i].split(",").map((age: string) => {
                    return new Date(new Date().setFullYear(new Date().getFullYear() - Number(age)))
                        .toISOString()
                        .split("T")[0];
                }) || [],
        };

        pax.push(room);
    }

    return pax;
};
