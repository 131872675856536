import i from "@emotion/styled";
const a = "engine-plugin-embedded-cms__hidden", s = i.div`
    &::-webkit-scrollbar {
        display: none;
    }

    position: relative;
    scrollbar-width: 0px;
    container-type: inline-size;

    .${a} {
        visibility: hidden;
        opacity: 0;
        width: 0;
        height: 0;
    }

    margin-block-end: ${({
  theme: o
}) => o.spacing.size[8]};
`, p = i.nav`
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    overflow-x: auto;
    display: inline-flex;
    flex-wrap: nowrap;
    height: 57px;

    gap: 24px;
`, c = i.div(({
  theme: o,
  show: n,
  right: t,
  left: r
}) => ({
  right: t,
  left: r,
  visibility: n ? "visible" : "hidden",
  pointerEvents: n ? "auto" : "none",
  opacity: n ? 1 : 0,
  position: "absolute",
  zIndex: 99,
  transition: "all 200ms",
  transform: `scale(${n ? 1 : 0.05})`,
  "@media (max-width: 599px)": {
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
    width: 95,
    height: "100%",
    pointerEvents: "none",
    transform: t === 0 ? "rotate(180deg) translateX(-1px)" : "none"
  },
  [`@media (min-width:0px) and (max-width:${o.breakpoints.layoutGridSm})`]: {
    display: "none"
  }
})), e = (o) => o.name === "cvc" ? {
  color: o.colors.brand.secondary[500]
} : {
  color: o.colors.brand.primary[500]
}, d = i.div(({
  theme: o,
  underline: n
}) => {
  const {
    color: t
  } = e(o);
  return {
    "*": {
      fontWeight: o.typography.font.weight.regular,
      fontSize: o.typography.font.size.xs,
      color: n ? t : o.colors.neutral.black,
      fill: n ? t : o.colors.neutral.black,
      fontFamily: o.typography.font.family[2]
    },
    button: {
      backgroundColor: "transparent",
      border: "none"
    },
    cursor: "pointer",
    position: "relative",
    ":after": {
      content: n ? '" "' : void 0,
      position: "absolute",
      background: t,
      width: 20,
      height: 2,
      left: "50%",
      bottom: 0,
      transform: "translateX(calc(50% - 20px))"
    }
  };
}), b = i.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap"
}));
export {
  a as HIDDEN_CLASSNAME,
  d as LinkWrapper,
  p as Nav,
  b as ProductWrapper,
  s as RelativeContainer,
  c as ScrollButtonWrapper
};
