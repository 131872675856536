import p, { createContext as v, useState as m, useEffect as E, useCallback as u, useMemo as R, useContext as g } from "react";
import { DEFAULT_ROOM as i } from "../../../constants/defaultValues.js";
import A from "../../../validations/childrenPaxDate.js";
const t = v({});
function D(d) {
  const {
    children: x,
    infantAge: n,
    rooms: e = [{
      ...i
    }]
  } = d, [s, r] = m(e), [a, C] = m();
  E(() => {
    r(e);
  }, [e]);
  const c = u((o) => {
    const [P, h] = A(o);
    C(P ? h : void 0), r(o);
  }, []), l = u(() => {
    const o = e ? [...e] : [{
      ...i
    }];
    r(o);
  }, [e]), f = R(() => ({
    infantAge: n,
    rooms: s,
    errors: a,
    onChangeRooms: c,
    onClearRooms: l
  }), [a, c, l, s, n]);
  return /* @__PURE__ */ p.createElement(t.Provider, { value: f }, x);
}
D.displayName = "PaxProvider";
const k = t.Consumer;
function y() {
  return g(t);
}
export {
  k as PaxConsumer,
  D as default,
  y as usePax
};
