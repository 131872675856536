function m({
  multidestinations: e = {},
  calendarDates: t,
  destination: n,
  errors: r,
  origin: i,
  pax: s
}) {
  const a = Object.entries(e).map(([o, u]) => ({
    ...u,
    key: Number(o)
  }));
  return [{
    calendarDates: t,
    errors: r,
    pax: s,
    destination: n,
    origin: i,
    key: -1
  }, ...a];
}
export {
  m as default
};
