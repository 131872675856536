import e from "react";
import { p as c, s as t, a, r as s, m as i, b as r, d as l, c as n, e as o, f as m, g as p, h as v } from "../../../FilledPlay-Bo3nTh9_.js";
import { h as b, r as d, m as u } from "../../../FilledBed-CZJxOTKc.js";
const w = {
  air: "/passagens-aereas",
  hotel: "/hotel",
  packages: "/pacotes-turisticos",
  "package-full": "/pacotes-estatico",
  cru: "https://cruzeirosonline.cvc.com.br/?_ga=2.51952873.1737951401.1700483294-1676975524.1673306072",
  cars: "/aluguel-de-carros",
  "rock-in-rio": "https://www.cvc.com.br/lp/omni/rock-in-rio",
  "cvc-me-leva": "https://www.cvc.com.br/lp/promocoes/cvcmeleva?chn=web",
  club: "https://www.cvc.com.br/clube-cvc/",
  disney: "https://www.cvc.com.br/lp/pacotes-de-viagem/internacional/disney",
  app: "https://www.cvc.com.br/lp/app-cvc-viagens",
  "cvc+": "https://www.cvcmais.com.br/#xd_co_f=MzY0ZGFhNTktNzA5ZS00YjBjLTlhZTAtMTVhZmQyMzQ1YmNk~",
  tickets: "/ingresso",
  "travel-security": "/seguro-viagem",
  tours: "/tours",
  partnerships: "https://www.cvc.com.br/lp/parcerias",
  "ticket-trip": "https://www.cvc.com.br/vale-viagem.aspx"
}, y = [{
  label: "Pacotes",
  type: "packages",
  icon: /* @__PURE__ */ e.createElement(b, { size: "md" }),
  link: "/pacotes-turisticos",
  id: "menu-pacotes"
}, {
  label: "Passagens",
  type: "air",
  icon: /* @__PURE__ */ e.createElement(d, { size: "md" }),
  link: "/passagens-aereas",
  id: "menu-passagens"
}, {
  label: "Hotéis",
  type: "hotel",
  icon: /* @__PURE__ */ e.createElement(u, { size: "md" }),
  link: "/hotel",
  id: "menu-hoteis"
}, {
  label: "Cruzeiros",
  type: "cru",
  icon: /* @__PURE__ */ e.createElement(c, { size: "md" }),
  target: "_blank",
  link: "https://cruzeirosonline.cvc.com.br/?_ga=2.51952873.1737951401.1700483294-1676975524.1673306072",
  id: "menu-cruzeiros"
}, {
  label: "Carros",
  type: "cars",
  icon: /* @__PURE__ */ e.createElement(t, { size: "md" }),
  link: "/aluguel-de-carros",
  id: "menu-cars"
}, {
  label: "Seguro Viagem",
  type: "travel-security",
  icon: /* @__PURE__ */ e.createElement(a, { size: "md" }),
  link: "/seguro-viagem",
  id: "menu-seguro-viagem"
}, {
  label: "Ingressos",
  type: "tickets",
  icon: /* @__PURE__ */ e.createElement(s, { size: "md" }),
  link: "/ingresso",
  id: "menu-ingressos"
}, {
  label: "CVC me leva",
  type: "cvc-me-leva",
  icon: /* @__PURE__ */ e.createElement(i, { size: "md" }),
  target: "_blank",
  link: "https://www.cvc.com.br/lp/promocoes/cvcmeleva-site?chn=web",
  id: "menu-cvc-me-leva"
}, {
  label: "Rock in Rio",
  type: "rock-in-rio",
  icon: /* @__PURE__ */ e.createElement(r, { size: "md" }),
  target: "_blank",
  link: "https://www.cvc.com.br/lp/promocoes/rock-in-rio",
  id: "menu-rock-in-rio"
}, {
  label: "Disney",
  type: "disney",
  icon: /* @__PURE__ */ e.createElement(l, { size: "md" }),
  target: "_blank",
  link: w.disney,
  id: "menu-disney"
}, {
  label: "Circuitos",
  type: "tours",
  icon: /* @__PURE__ */ e.createElement(n, { size: "md" }),
  link: "/tours",
  id: "menu-tours"
}, {
  label: "App CVC",
  type: "app",
  icon: /* @__PURE__ */ e.createElement(o, { size: "md" }),
  target: "_blank",
  link: "https://www.cvc.com.br/lp/app-cvc-viagens",
  id: "menu-app-cvc"
}, {
  label: "Vale Viagem",
  type: "ticket-trip",
  icon: /* @__PURE__ */ e.createElement(m, { size: "md" }),
  target: "_blank",
  link: "https://www.cvc.com.br/vale-viagem.aspx",
  id: "menu-vale-viagem"
}, {
  label: "Parcerias",
  type: "partnerships",
  icon: /* @__PURE__ */ e.createElement(p, { size: "md" }),
  target: "_blank",
  link: "https://www.cvc.com.br/lp/parcerias",
  id: "menu-parcerias"
}, {
  label: "CVC+",
  type: "cvc+",
  icon: /* @__PURE__ */ e.createElement(v, { size: "md" }),
  target: "_blank",
  link: "https://www.cvcmais.com.br/#xd_co_f=MzY0ZGFhNTktNzA5ZS00YjBjLTlhZTAtMTVhZmQyMzQ1YmNk~",
  id: "menu-cvc-mais"
}];
export {
  w as PRODUCTS_LINK_CVC,
  y as default
};
