import { getParams as o } from "@cvccorp-engines/components";
var s = function(t) {
  return t == null || t === "undefined" || t === "null";
};
const d = () => {
  var i, l, r;
  const n = (i = o("zoneIds")) == null ? void 0 : i.split(","), t = (l = o("descriptions")) == null ? void 0 : l.split(","), e = [];
  return n == null || n.forEach((a, u) => {
    e.push({
      zoneId: Number(a),
      description: (t == null ? void 0 : t[u]) || ""
    });
  }), s((r = e == null ? void 0 : e[0]) == null ? void 0 : r.description) ? [] : e;
}, p = () => o("date"), f = () => !!o("allDestiny");
export {
  f as getTourAllDestinyInitialValue,
  p as getTourDateInitialValue,
  d as getTourDestinationInitialValue
};
