import { getParams as t } from "@cvccorp-engines/components";
const u = () => {
  var n;
  const o = t("originCode") || "", [i = "", s = "", c = ""] = ((n = t("location")) == null ? void 0 : n.split("-")) ?? [], e = {
    provider: t("provider") || "",
    description: "",
    cityId: o,
    cityName: i,
    stateName: s,
    countryName: c
  };
  return e.provider ? e : void 0;
}, l = () => {
  const o = Number(t("Adults") || 0), i = Number(t("Children") || 0), s = t("CheckIn") || "", c = t("CheckOut") || "", r = Number(t("Rooms") || 0), e = t("Location"), n = t("ZoneId") || "";
  return {
    adult: o,
    children: i,
    origDate: s,
    destDate: c,
    room: r,
    destDescription: e,
    destZoneId: n,
    durationUnit: "Milliseconds"
  };
}, m = () => {
  var o;
  return ((o = t("endDate")) == null ? void 0 : o.concat("T03:00:00.000Z")) ?? "";
};
export {
  u as getTicketDestinationInitialValue,
  m as getTicketEndDateInitialValue,
  l as getTicketsParamsForLogs
};
